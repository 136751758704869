import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Fade,
  Typography,
  MenuItem,
  Select,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Backdrop,
  OutlinedInput,
  Chip,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";

interface SendMailModalProps {
  open: boolean;
  onClose: () => void;
  requestId: number;
  saleId: number;
}

interface EmailOption {
  email: string;
  type: string;
}

const SendMailModal: React.FC<SendMailModalProps> = ({
  open,
  onClose,
  requestId,
  saleId,
}) => {
  const [selectedEmails, setSelectedEmails] = useState<EmailOption[]>([]);
  const [emailOptions, setEmailOptions] = useState<EmailOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sendTrustPilotReview, setSendTrustPilotReview] =
    useState<boolean>(false);

  const fetchEmails = async (requestId: number) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://crm.vipfares.com/api/client/email/${requestId}`
      );
      setEmailOptions(response.data);
    } catch (error) {
      console.error("Не удалось загрузить emails", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && requestId) {
      fetchEmails(requestId);
    }
  }, [open, requestId]);

  const handleEmailChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    const newSelectedEmails = selectedValues.map((email) =>
      emailOptions.find((option) => option.email === email)
    );
    setSelectedEmails(newSelectedEmails as EmailOption[]);
  };

  const handleDelete = (emailToDelete: string) => (event: React.MouseEvent) => {
    event.stopPropagation(); // Предотвращаем открытие дропдауна
    setSelectedEmails((emails) =>
      emails.filter((email) => email.email !== emailToDelete)
    );
  };

  const handleSend = async () => {
    try {
      await axios.post("https://bob.vipfares.com/api/send", {
        emails: selectedEmails.map((email) => email.email),
        id: saleId,
        sendTrustPilotReview,
      });
      console.log(
        "Письма отправлены на:",
        selectedEmails.map((email) => email.email)
      );
      console.log("Отправить отзыв TrustPilot:", sendTrustPilotReview);
      onClose();
    } catch (error) {
      console.error("Не удалось отправить письма", error);
    }
  };

  const getChipColor = (type: string) => {
    switch (type) {
      case "Agent email":
        return "primary";
      case "Main email":
        return "success";
      case "Shared email":
        return "success";
      default:
        return "default";
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
          }}
        >
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Send Ticket Confirmation Mail
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Select
                multiple
                value={selectedEmails.map((email) => email.email)}
                onChange={handleEmailChange}
                input={<OutlinedInput label="Выберите emails" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selectedEmails.map((selectedEmail) => (
                      <Chip
                        key={selectedEmail.email}
                        label={selectedEmail.email}
                        onDelete={handleDelete(selectedEmail.email)}
                        color={getChipColor(selectedEmail.type)}
                        onMouseDown={(event) => event.stopPropagation()} // Добавляем это для предотвращения открытия
                      />
                    ))}
                  </Box>
                )}
                fullWidth
                sx={{ mt: 2, mb: 2 }}
              >
                <MenuItem value="" disabled>
                  Выберите emails
                </MenuItem>
                {emailOptions.map((item, index) => (
                  <MenuItem key={item.email + index} value={item.email}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>{item.email}</span>
                      <span style={{ color: "gray", marginLeft: "auto" }}>
                        {item.type}
                      </span>
                    </Box>
                  </MenuItem>
                ))}
              </Select>

              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendTrustPilotReview}
                      onChange={(e) =>
                        setSendTrustPilotReview(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Send TrustPilot Review"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSend}
                  disabled={selectedEmails.length === 0}
                >
                  Send
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default SendMailModal;
