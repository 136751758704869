import { createContext, ReactNode, useEffect, useReducer } from "react";

import { JWTContextType, ActionMap, AuthState, AuthUser } from "../types/auth";

import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import InfoSnackbar from "../components/snackbar/InfoSnackbar";
import * as React from "react";

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.tsx` file.

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const SET_ROLE = "SET_ROLE";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
    role: "ticketing" | "agent" | "manager" | "bookkeping";
  };
  [SIGN_IN]: {
    user: AuthUser;
    role: "ticketing" | "agent" | "manager" | "bookkeping";
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    user: AuthUser;
    role: "ticketing" | "agent" | "manager" | "bookkeping";
  };
  [SET_ROLE]: {
    role: "ticketing" | "agent" | "manager" | "bookkeping";
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  role: "manager",
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        role: "manager",
      };

    case SET_ROLE:
      return {
        ...state,
        role: action.payload.role,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const base = process.env["REACT_APP_BACKEND_URL"];
          const response = await axios.get(`${base}/api/my-account`, {
            headers: {
              Authorization: `${accessToken}`,
            },
          });
          const { user } = response.data.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
              role: "manager",
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              role: "manager",
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            role: "manager",
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email: string, password: string) => {
    const base = process.env["REACT_APP_BACKEND_URL"];
    const response = await axios.post(`${base}/api/login`, {
      email,
      password,
    });
    const { token, user } = response.data.data;
    setSession(token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
        role: "manager",
      },
    });
  };

  const setRole = async (
    role: "ticketing" | "agent" | "manager" | "bookkeping"
  ) => {
    dispatch({ type: SET_ROLE, payload: { role } });
  };
  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
        role: "manager",
      },
    });
  };

  const resetPassword = (email: string) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        role: "manager",
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        setRole,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
