import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  TextField,
} from "@mui/material";
import CommentsTable from "../../tables/CommentsTable";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import { useParams } from "react-router-dom";
import { createCommentAction } from "../../../../../redux/slices/actions/salesActions";

export interface CommentsTableData {
  date: string;
  madeBy: string;
  comment: string;
}

function createData(
  date: string,
  madeBy: string,
  comment: string
): CommentsTableData {
  return {
    date,
    madeBy,
    comment,
  };
}
const rows: Array<CommentsTableData> = [
  createData("25/03/23 15:46", "ME", "sdasd"),
  createData("25/03/23 15:46", "Michael", "something warn"),
  createData("25/03/23 15:46", "XYAN", "non-com"),
];
const CommentSection: FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [isOpenDialog, setOpenDialog] = useState<boolean>(false);
  const [isOpenSnack, setOpenSnack] = useState<boolean>(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const [comment, setComment] = useState<string>("");
  const handleConfirmDialog = () => {
    dispatch(createCommentAction({ text: comment, saleId: Number(id) }));
    setOpenSnack(true);
  };
  return (
    <Grid container spacing={1} padding={3}>
      <Grid item md={12}>
        <Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Button
              sx={{ maxHeight: 60 }}
              onClick={handleClickOpenDialog}
              variant="contained"
              color="inherit"
            >
              + ADD COMMENT
            </Button>
          </Box>

          <CommentsTable />
        </Box>
      </Grid>
      <Dialog open={isOpenDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add new Comment</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ width: 530 }}
            autoFocus
            margin="dense"
            id="omment"
            label="Comment"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDialog}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isOpenSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully added!
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default CommentSection;
