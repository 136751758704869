import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableCell as MuiTableCell } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { styled } from "@mui/material";
import React from "react";
import AirplanemodeActiveRoundedIcon from "@mui/icons-material/AirplanemodeActiveRounded";

export default function AirfareTable() {
  const { saleDetails } = useSelector((state: RootState) => state.sales);
  const oafWithFlighlegs = saleDetails.oafs.find(
    (oaf) => oaf.type === "default" && oaf.airfares
  );
  const flightLegs = oafWithFlighlegs?.airfares![0].flightLegs;
  const getTwoLetterDayName = (dayName: string) => {
    const shortNames: { [key: string]: string } = {
      Sunday: "SU",
      Monday: "MO",
      Tuesday: "TU",
      Wednesday: "WE",
      Thursday: "TH",
      Friday: "FR",
      Saturday: "SA",
    };
    return shortNames[dayName] || "";
  };
  const getTwoLettersTypeCLass = (className: string) => {
    const shortNames: { [key: string]: string } = {
      BUSINESS: "B",
      ECONOMY: "E",
      "PREMIUM ECONOMY": "P",
      FIRST: "F",
    };
    return shortNames[className] || "";
  };

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const month = monthNames[date.getMonth()];

    return `${day}${month}`;
  };
  // Форматирование времени
  const formatTime = (dateString: string | number | Date) => {
    const date = new Date(dateString);

    // Extract the hours and minutes from the date
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format the hours and minutes as two-digit strings
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes}`;
  };
  const TableCell = styled(MuiTableCell)`
    border: 1px rgba(224, 224, 224, 1) solid;
    padding: 1px 6px;
    text-align: center;
    font-weight: 400;
  `;

  const FirstCell = styled(MuiTableCell)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: 2,
  }));
  const convertTimeFormat = (time: string): string => {
    const parts = time.split(":");
    if (parts.length !== 3) {
      throw new Error("Invalid time format");
    }
    return `${parts[0]}h ${parts[1]}m`;
  };
  const StyledDiv = styled("div")({});
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      // @ts-ignore
      backgroundColor: theme.card.headerBackground,
    },
  }));
  const setHeight = (length: number) => {
    return `${30 * length}px`;
  };
  console.log("test", flightLegs);
  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 2, maxWidth: 1210, minWidth: 300 }}
    >
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {flightLegs &&
            flightLegs.map((leg, legIndex) => (
              <React.Fragment key={legIndex}>
                {leg.segments.map((segment, segmentIndex) => (
                  <StyledTableRow key={segmentIndex}>
                    {segmentIndex === 0 && (
                      <FirstCell
                        width={30}
                        rowSpan={leg.segments.length}
                        sx={{
                          border: "none",
                        }}
                      >
                        {/*{legIndex + 1}*/}
                        <StyledDiv
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: setHeight(leg.segments.length),
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              border: "2px solid #6C757D",
                            }}
                          ></div>
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <AirplanemodeActiveRoundedIcon
                              sx={{
                                color: "#6C757D",
                                transform:
                                  legIndex % 2 === 0
                                    ? "rotate(180deg)"
                                    : "none",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              height: "100%",
                              borderLeft: "2px dashed #6C757D",
                              alignSelf: "center",
                            }}
                          ></div>
                          <div
                            style={{
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              border: "2px solid #6C757D",
                            }}
                          ></div>
                        </StyledDiv>
                      </FirstCell>
                    )}
                    <TableCell width={1}>{segmentIndex + 1}</TableCell>
                    <TableCell width={1}>{segment.airlineIataCode}</TableCell>
                    <TableCell width={1}>{segment.flightNumber}</TableCell>
                    <TableCell width={1}>{segment.bookingClass}</TableCell>
                    <TableCell width={1}>{segment.departureAirport}</TableCell>
                    <TableCell width={1}> {segment.arrivalAirport}</TableCell>
                    <TableCell width={1}>{segment.segmentStatus}</TableCell>
                    <TableCell width={1}>
                      {formatDate(segment.departureDate)}
                    </TableCell>
                    <TableCell width={1}>
                      {formatTime(segment.departureDate)}
                    </TableCell>
                    <TableCell width={1}>
                      {formatTime(segment.arrivalDate)}
                    </TableCell>
                    <TableCell width={1}>
                      {getTwoLetterDayName(segment.weekDayDeparture)}
                    </TableCell>
                    <TableCell width={1}>
                      {getTwoLettersTypeCLass(segment.cabinType)}
                      {/*{segment.cabinType}*/}
                    </TableCell>
                    <TableCell width={1}>{segment.flightTime}</TableCell>
                    {segment.layoverTime !== "00:00" ? (
                      <TableCell
                        sx={{
                          backgroundColor: "#fbdece",
                        }}
                        width={1}
                      >
                        {segment.layoverTime}
                      </TableCell>
                    ) : (
                      <TableCell width={1}></TableCell>
                    )}
                    {segmentIndex === 0 && (
                      <TableCell
                        sx={{ backgroundColor: "#cee9f0" }}
                        width={1}
                        rowSpan={leg.segments.length}
                      >
                        {convertTimeFormat(leg.totalTravelTime)}
                      </TableCell>
                    )}
                    <TableCell width={1}>
                      {segment.baggageNumber === null
                        ? "N/A"
                        : segment.baggageNumber}
                    </TableCell>
                    <TableCell width={1}>
                      {segment.recordLocator ?? "N/A"}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
