import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Login, Visibility, VisibilityOff } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(4)};
  width: 38.2%;

  @media (max-width: 960px) {
    display: none;
  }
`;

const CustomTypography = styled(Typography)`
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(79, 92, 104);
`;

const Logo = styled.img`
  width: 104%;
  height: auto;
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 19px;

  .MuiInputBase-root {
    background-color: #fff;
  }

  .MuiInputBase-input::placeholder {
    color: #a9a9a9;
  }
`;

const StyledButton = styled(Button)`
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
  justify-self: center;
  margin-top: ${(props) => props.theme.spacing(2)};
  background-color: #fff;
  height: 53px;
  width: 113px;
  color: #2c3e50;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <Container>
        <LeftSide>
          <Logo src="/static/img/media/main-bg.svg" alt="Logo" />
        </LeftSide>
        <RightSide>
          <FormContainer>
            <CustomTypography
              style={{ fontSize: 35 }}
              align="center"
              gutterBottom
              color="white"
            >
              F*CK AVERAGE, BE LEGENDARY
            </CustomTypography>
            <Formik
              initialValues={{
                email: "",
                password: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                password: Yup.string()
                  .max(255)
                  .required("Password is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await signIn(values.email, values.password);
                  navigate("/sales");
                } catch (error: any) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {errors.submit && (
                    <Typography color="error" variant="body2" align="center">
                      {errors.submit}
                    </Typography>
                  )}
                  <StyledTextField
                    type="email"
                    name="email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  <StyledTextField
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    startIcon={<Login />}
                  >
                    Sign In
                  </StyledButton>
                </form>
              )}
            </Formik>
          </FormContainer>
        </RightSide>
      </Container>
    </React.Fragment>
  );
};

export default SignIn;
