import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { TableHead } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  TravelProtection,
  TravelProtectionUsers,
} from "../../../../services/openapi";

interface Data {
  pax: string;
  ticketPrice: string;
  cost: string;
  basic: string;
  status: string;
}
function createData(
  pax: string,
  ticketPrice: string,
  cost: string,
  basic: string,
  status: string
): Data {
  return {
    pax,
    ticketPrice,
    cost,
    basic,
    status,
  };
}

const rows: Array<Data> = [
  createData("GOMEZ/ELIZABETH", "$4310.00", "$ 431.00", "12$", "Activated"),
  createData("GOMEZ/MICHAEL", "$4310.00", "$ 431.00", "12$", "Activated"),
];

export default function TravelProtectionTable() {
  const { saleDetails } = useSelector((state: RootState) => state.sales);
  const oafWithTravelProtections = saleDetails.oafs.find(
    (oaf) => oaf.type === "default" && oaf.travelProtections
  );

  let travelProtectionUsers: TravelProtectionUsers[] = [];

  if (oafWithTravelProtections?.travelProtections) {
    // Выбираем travelProtectionUsers из travelProtections
    travelProtectionUsers = oafWithTravelProtections.travelProtections.reduce(
      (users, travelProtection) => {
        return users.concat(travelProtection.travelProtectionUsers);
      },
      [] as TravelProtectionUsers[]
    );
  }

  const membershipLevels: { [key: number]: string } = {
    1000: "standart",
    2000: "premium",
    3000: "vip",
    0: "",
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2, maxWidth: 1000 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Pax Name</TableCell>
            <TableCell>Ticket Price</TableCell>
            <TableCell>Cost</TableCell>
            <TableCell>Basic</TableCell>
            <TableCell>Activation Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {travelProtectionUsers &&
            travelProtectionUsers.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.ticketPrice}</TableCell>
                <TableCell>{row.cost}</TableCell>
                <TableCell>{row.basic}</TableCell>
                <TableCell>{row.status}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
