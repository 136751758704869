import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import { clearError, clearSuccess } from "../../redux/slices/salesSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";

const InfoSnackbar = () => {
  const dispatch: AppDispatch = useDispatch();
  const { error, success } = useSelector((state: RootState) => state.sales);
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const handleAlertClose = (field: string) => () => {
    setIsErrorSnackbarOpen(false);
    // Вызываем clearError с именем поля для удаления
    dispatch(clearError({ field }));
  };
  useEffect(() => {
    // При обновлении ошибки открываем Snackbar
    if (error) {
      setIsErrorSnackbarOpen(true);
    }
  }, [error]);

  useEffect(() => {
    // При обновлении ошибки открываем Snackbar
    if (success) {
      setIsSuccessSnackbarOpen(true);
    }
  }, [success]);
  const handleCloseAlertSnackbar = (
    event: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      // Не закрывать Snackbar, если причина закрытия - клик вне компонента
      return;
    }
    setIsErrorSnackbarOpen(false);
    dispatch(clearError({}));
  };

  const handleCloseSuccessSnackbar = (
    event: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      // Не закрывать Snackbar, если причина закрытия - клик вне компонента
      return;
    }
    setIsSuccessSnackbarOpen(false);
    dispatch(clearSuccess({}));
  };
  // const errorsDiv = () => {
  //   console.log("error", error);
  //   switch (error) {
  //     case typeof error === "string":
  //       return (
  //         <Alert
  //           onClose={handleCloseAlertSnackbar}
  //           severity="error"
  //           sx={{ width: "100%" }}
  //         >
  //           {error}
  //         </Alert>
  //       );
  //     case error === "object" && error.message === "Failed to fetch":
  //       return (
  //         <Alert
  //           onClose={handleCloseAlertSnackbar}
  //           severity="error"
  //           sx={{ width: "100%" }}
  //         >
  //           {error.message}
  //         </Alert>
  //       );
  //     case typeof error === "object" && error !== null:
  //       return (
  //         <>
  //           {Object.keys(error).map((key) =>
  //             error[key].map((message: string, index: number) => (
  //               <Alert
  //                 key={`${key}_${index}`} // Уникальный ключ для каждого Alert
  //                 onClose={handleAlertClose(key)} // Передаем имя поля в обработчик
  //                 severity="error"
  //                 sx={{ width: "100%" }}
  //               >
  //                 {message}
  //               </Alert>
  //             ))
  //           )}
  //         </>
  //       );
  //   }
  // };

  return (
    <div>
      <Snackbar
        open={isSuccessSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSuccessSnackbar}
      >
        <Alert
          onClose={handleCloseSuccessSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isErrorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseAlertSnackbar}
      >
        <Alert>error</Alert>
        {/*{typeof error === "string" ? ( // Проверяем, является ли error строкой*/}
        {/*  <Alert*/}
        {/*    onClose={handleCloseAlertSnackbar}*/}
        {/*    severity="error"*/}
        {/*    sx={{ width: "100%" }}*/}
        {/*  >*/}
        {/*    {error}*/}
        {/*  </Alert>*/}
        {/*) : (*/}
        {/*  error && ( // Проверяем, является ли error объектом*/}
        {/*    <div>*/}
        {/*      {error.message === "Failed to fetch" ? (*/}
        {/*        <Alert*/}
        {/*          onClose={handleCloseAlertSnackbar}*/}
        {/*          severity="error"*/}
        {/*          sx={{ width: "100%" }}*/}
        {/*        >*/}
        {/*          {error.message}*/}
        {/*        </Alert>*/}
        {/*      ) : (*/}
        {/*        Object.keys(error).map((key: any) =>*/}
        {/*          error[key].map((message: string, index: number) => (*/}
        {/*            <Alert*/}
        {/*              key={`${key}_${index}`}*/}
        {/*              onClose={handleAlertClose(key)}*/}
        {/*              severity="error"*/}
        {/*              sx={{ width: "100%" }}*/}
        {/*            >*/}
        {/*              {message}*/}
        {/*            </Alert>*/}
        {/*          ))*/}
        {/*        )*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  )*/}
        {/*)}*/}
      </Snackbar>
    </div>
  );
};

export default InfoSnackbar;
