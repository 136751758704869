import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTheme } from "@mui/material/styles";
const AlertDialog: FC = () => {
  const saleDetails = useSelector(
    (state: RootState) => state.sales.saleDetails
  );
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (saleDetails.isPaused) {
      setOpen(true);
    }
  }, [saleDetails.isPaused]);

  const theme = useTheme();
  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          variant={"h3"}
          id="alert-dialog-title"
          sx={{ display: "flex" }}
        >
          <WarningAmberIcon fontSize={"large"} color={"warning"} />
          <Typography sx={{ color: theme.palette.warning.main, fontSize: 30 }}>
            Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            fontSize={24}
            textAlign={"center"}
            id="alert-dialog-description"
          >
            This sale is paused!!!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertDialog;
