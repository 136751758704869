import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store"; // Ensure the correct path
import {
  Passenger,
  PnrPassenger,
  UpdatePassengerRequest,
} from "../services/openapi";
import {
  getPnrPassengersBySale,
  updatePassengerData,
} from "../redux/slices/pnrSlice"; // Ensure the correct path
import { upperCase } from "lodash";

interface ComparisonModalProps {
  open: boolean;
  handleClose: () => void;
  passenger: Passenger;
}

const ComparisonModal: React.FC<ComparisonModalProps> = ({
  open,
  handleClose,
  passenger,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedCells, setSelectedCells] = useState<{
    [key: string]: boolean;
  }>({
    firstNameOAF: false,
    firstNameSALE: false,
    middleNameOAF: false,
    middleNameSALE: false,
    lastNameOAF: false,
    lastNameSALE: false,
  });

  const pnrPassengers = useSelector(
    (state: RootState) => state.pnr.pnrPassengers
  );
  const [selected_pnr_passenger, setSelected_pnr_passenger] = useState<
    PnrPassenger | undefined
  >(passenger.pnr_passenger);

  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      passenger &&
      !passenger.need_to_check_first_name &&
      !passenger.need_to_check_middle_name &&
      !passenger.need_to_check_last_name
    ) {
      setSelectedCells({
        firstNameOAF: true,
        firstNameSALE: false,
        middleNameOAF: true,
        middleNameSALE: false,
        lastNameOAF: true,
        lastNameSALE: false,
      });
    } else {
      setSelectedCells({
        firstNameOAF: false,
        firstNameSALE: false,
        middleNameOAF: false,
        middleNameSALE: false,
        lastNameOAF: false,
        lastNameSALE: false,
      });
    }

    if (passenger && !passenger.pnr_passenger) {
      dispatch(getPnrPassengersBySale(passenger.airfareId));
    }
  }, [dispatch, passenger]);

  const handleCellClick = (field: string, oppositeField: string) => {
    if (
      passenger &&
      (passenger.need_to_check_first_name ||
        passenger.need_to_check_middle_name ||
        passenger.need_to_check_last_name)
    ) {
      setSelectedCells((prev) => ({
        ...prev,
        [field]: !prev[field],
        [oppositeField]: false,
      }));
    }
  };

  const compareValues = (passengerValue: string, pnrValue?: string) => {
    return upperCase(passengerValue) === upperCase(pnrValue)
      ? "white"
      : "lightcoral";
  };

  const getBackgroundColor = (
    field: string,
    passengerValue: string,
    pnrValue?: string,
    //@ts-ignore
    needToCheck: boolean
  ) => {
    if (!needToCheck) {
      return "lightgreen";
    }
    return selectedCells[field]
      ? "lightgreen"
      : compareValues(passengerValue, pnrValue);
  };

  const handlePnrPassengerChange = (event: SelectChangeEvent<number>) => {
    const selectedId = event.target.value as number;
    const selected = pnrPassengers.find((p) => p.passenger_id === selectedId);
    setSelected_pnr_passenger(selected);

    // Re-check and update the background colors after changing the selected PNR passenger
    if (selected) {
      setSelectedCells((prev) => ({
        firstNameOAF: prev.firstNameOAF,
        firstNameSALE: false,
        middleNameOAF: prev.middleNameOAF,
        middleNameSALE: false,
        lastNameOAF: prev.lastNameOAF,
        lastNameSALE: false,
      }));
    }
  };

  const handleConfirm = async () => {
    if (selected_pnr_passenger) {
      const passengerData: UpdatePassengerRequest = {
        passenger_id: passenger.id,
        pnr_passenger_id: selected_pnr_passenger.id!,
        first_name: selected_pnr_passenger.first_name!,
        middle_name: selected_pnr_passenger.middle_name,
        last_name: selected_pnr_passenger.last_name!,
      };

      try {
        await dispatch(updatePassengerData(passengerData)).unwrap();
        handleClose();
        setSuccessModalOpen(true); // Open success modal
      } catch (error) {
        console.error("Failed to update passenger data:", error);
      }
    }
  };

  const handleSuccessConfirm = () => {
    setSuccessModalOpen(false);
    window.location.reload(); // Reload the page
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Choose Correct Data</DialogTitle>
        <DialogContent>
          {pnrPassengers.length > 0 && (
            <Box display={"flex"} justifyContent={"end"}>
              <FormControl sx={{ width: 458 }} margin="normal">
                <InputLabel>Select PNR Passenger</InputLabel>
                <Select
                  value={selected_pnr_passenger?.passenger_id || ""}
                  onChange={handlePnrPassengerChange}
                  label="Select PNR Passenger"
                >
                  {pnrPassengers.map((pnrPassenger, index) => (
                    <MenuItem
                      key={`${pnrPassenger.passenger_id}-${index}`}
                      value={pnrPassenger.passenger_id}
                    >
                      {pnrPassenger.first_name} {pnrPassenger.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <TableContainer component={Paper}>
            <Table size="small" aria-label="comparison table">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>OAF</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>SALE</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: getBackgroundColor(
                        "firstNameOAF",
                        passenger.firstName,
                        selected_pnr_passenger?.first_name,
                        !!passenger.need_to_check_first_name
                      ),
                      cursor: passenger.need_to_check_first_name
                        ? "pointer"
                        : "default",
                    }}
                    onClick={() =>
                      handleCellClick("firstNameOAF", "firstNameSALE")
                    }
                  >
                    {passenger.firstName}
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: getBackgroundColor(
                        "firstNameSALE",
                        passenger.firstName,
                        selected_pnr_passenger?.first_name,
                        !!passenger.need_to_check_first_name
                      ),
                      cursor: passenger.need_to_check_first_name
                        ? "pointer"
                        : "default",
                    }}
                    onClick={() =>
                      handleCellClick("firstNameSALE", "firstNameOAF")
                    }
                  >
                    {selected_pnr_passenger?.first_name ?? "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: getBackgroundColor(
                        "middleNameOAF",
                        passenger.middleName,
                        selected_pnr_passenger?.middle_name,
                        !!passenger.need_to_check_middle_name
                      ),
                      cursor: passenger.need_to_check_middle_name
                        ? "pointer"
                        : "default",
                    }}
                    onClick={() =>
                      handleCellClick("middleNameOAF", "middleNameSALE")
                    }
                  >
                    {passenger.middleName}
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: getBackgroundColor(
                        "middleNameSALE",
                        passenger.middleName,
                        selected_pnr_passenger?.middle_name,
                        !!passenger.need_to_check_middle_name
                      ),
                      cursor: passenger.need_to_check_middle_name
                        ? "pointer"
                        : "default",
                    }}
                    onClick={() =>
                      handleCellClick("middleNameSALE", "middleNameOAF")
                    }
                  >
                    {selected_pnr_passenger?.middle_name ?? "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: getBackgroundColor(
                        "lastNameOAF",
                        passenger.lastName,
                        selected_pnr_passenger?.last_name,
                        !!passenger.need_to_check_last_name
                      ),
                      cursor: passenger.need_to_check_last_name
                        ? "pointer"
                        : "default",
                    }}
                    onClick={() =>
                      handleCellClick("lastNameOAF", "lastNameSALE")
                    }
                  >
                    {passenger.lastName}
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: getBackgroundColor(
                        "lastNameSALE",
                        passenger.lastName,
                        selected_pnr_passenger?.last_name,
                        !!passenger.need_to_check_last_name
                      ),
                      cursor: passenger.need_to_check_last_name
                        ? "pointer"
                        : "default",
                    }}
                    onClick={() =>
                      handleCellClick("lastNameSALE", "lastNameOAF")
                    }
                  >
                    {selected_pnr_passenger?.last_name ?? "N/A"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <Typography>
              I confirm that the passenger details are correct.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Modal */}
      <Dialog
        open={successModalOpen}
        onClose={handleSuccessConfirm}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <Typography>Passenger data has been successfully updated!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ComparisonModal;
