import React, { FC } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button, Card, Typography } from "@mui/material";
import { SaleRequest } from "../../../services/openapi";
import { FieldArray, FormikTouched } from "formik";
import { ExtendedSaleRequest } from "../../../pages/forms/CreateOAForm";

interface Props {
  values: ExtendedSaleRequest;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  touched: any;
  errors: any;
}

const getErrorMessage = (error: any, path: string): string | undefined => {
  return typeof error === "object" && error && path in error
    ? error[path]
    : undefined;
};

const CreditCardForm: FC<Props> = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
}) => {
  return (
    <>
      <Typography variant={"h1"}>Payment Cards</Typography>
      <FieldArray
        name="paymentCard"
        render={(arrayHelpers) => (
          <>
            {values.paymentCard.map(
              (
                {
                  cardholdName,
                  cardNumber,
                  month,
                  year,
                  cvv,
                  bankPhoneNumber,
                  amount,
                  cardType,
                  country,
                  city,
                  state_province,
                  street_address,
                  zip,
                  email,
                  billingPhone,
                },
                index
              ) => (
                <Card
                  key={index}
                  sx={{ padding: 2, marginBottom: 2 }}
                  variant={"outlined"}
                >
                  <Grid container spacing={2} key={index}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Typography variant={"h3"}>
                        Payment card #{index + 1}
                      </Typography>
                      {values.paymentCard.length > 1 && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Remove PaymentCard
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        name={`paymentCard.${index}.cardholdName`}
                        label="Cardholder Name"
                        value={cardholdName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.cardholdName &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "cardholdName"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.cardholdName &&
                          getErrorMessage(
                            errors.paymentCard?.[index],
                            "cardholdName"
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        name={`paymentCard.${index}.cardNumber`}
                        label="cardNumber"
                        value={cardNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.cardNumber &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "cardNumber"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.cardNumber &&
                          getErrorMessage(
                            errors.paymentCard?.[index],
                            "cardNumber"
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        name={`paymentCard.${index}.month`}
                        label="month"
                        value={month}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.month &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "month"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.month &&
                          getErrorMessage(errors.paymentCard?.[index], "month")
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.year`}
                        label="year"
                        value={year}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.year &&
                          Boolean(
                            getErrorMessage(errors.paymentCard?.[index], "year")
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.year &&
                          getErrorMessage(errors.paymentCard?.[index], "year")
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.cvv`}
                        label="cvv"
                        value={cvv}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.cvv &&
                          Boolean(
                            getErrorMessage(errors.paymentCard?.[index], "cvv")
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.cvv &&
                          getErrorMessage(errors.paymentCard?.[index], "cvv")
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.bankPhoneNumber`}
                        label="bankPhoneNumber"
                        value={bankPhoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.bankPhoneNumber &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "bankPhoneNumber"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.bankPhoneNumber &&
                          getErrorMessage(
                            errors.paymentCard?.[index],
                            "bankPhoneNumber"
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.amount`}
                        label="amount"
                        value={amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.amount &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "amount"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.amount &&
                          getErrorMessage(errors.paymentCard?.[index], "amount")
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.cardType`}
                        label="cardType"
                        value={amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.cardType &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "cardType"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.cardType &&
                          getErrorMessage(
                            errors.paymentCard?.[index],
                            "cardType"
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.country`}
                        label="country"
                        value={country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.country &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "country"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.country &&
                          getErrorMessage(
                            errors.paymentCard?.[index],
                            "country"
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.city`}
                        label="city"
                        value={city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.city &&
                          Boolean(
                            getErrorMessage(errors.paymentCard?.[index], "city")
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.city &&
                          getErrorMessage(errors.paymentCard?.[index], "city")
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.state_province`}
                        label="state"
                        value={state_province}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.state_province &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "state_province"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.state_province &&
                          getErrorMessage(
                            errors.paymentCard?.[index],
                            "state_province"
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.street_address`}
                        label="street_address"
                        value={street_address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.street_address &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "street_address"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.street_address &&
                          getErrorMessage(
                            errors.paymentCard?.[index],
                            "street_address"
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.zip`}
                        label="zip"
                        value={zip}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.zip &&
                          Boolean(
                            getErrorMessage(errors.paymentCard?.[index], "zip")
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.zip &&
                          getErrorMessage(errors.paymentCard?.[index], "zip")
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.email`}
                        label="email"
                        value={email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.email &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "email"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.zip &&
                          getErrorMessage(errors.paymentCard?.[index], "zip")
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        name={`paymentCard.${index}.billingPhone`}
                        label="billingPhone"
                        value={billingPhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.paymentCard?.[index]?.billingPhone &&
                          Boolean(
                            getErrorMessage(
                              errors.paymentCard?.[index],
                              "billingPhone"
                            )
                          )
                        }
                        helperText={
                          touched.paymentCard?.[index]?.billingPhone &&
                          getErrorMessage(
                            errors.paymentCard?.[index],
                            "billingPhone"
                          )
                        }
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Card>
              )
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                arrayHelpers.push({
                  cardholdName: "John Doe",
                  cardNumber: 1234567890123456, // Пример номера карты
                  month: 12, // Пример месяца
                  year: 2025, // Пример года
                  cvv: 123, // Пример CVV
                  bankPhoneNumber: "+1234567890",
                  amount: 100.0, // Пример суммы
                  cardType: "Visa", // Пример типа карты
                  country: "1",
                  city: "1",
                  state: "17",
                  street_address: "123 Main St",
                  zip: "10001",
                  email: "johndoe@example.com",
                  billingPhone: "+9876543210",
                })
              }
            >
              Add Payment Card
            </Button>
          </>
        )}
      />
    </>
  );
};
export default CreditCardForm;
