import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, useMatch, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  List as MuiList,
  Typography as MuiTypography,
  Box,
} from "@mui/material";
import { spacing, display } from "@mui/system";
import CreateOAForm from "../forms/CreateOAForm";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);
const List = styled(MuiList)(display);
const Typography = styled(MuiTypography)(display);

function AddOAF() {
  return (
    <React.Fragment>
      <Helmet title="Invoice Details" />

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          <Typography>Create OAF</Typography>
        </Breadcrumbs>
      </Box>

      <Divider mt={2} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Box>
            <CreateOAForm />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddOAF;
