/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Pnr } from '../models/Pnr';
import type { PnrPassenger } from '../models/PnrPassenger';
import type { PnrRequest } from '../models/PnrRequest';
import type { UpdatePassengerRequest } from '../models/UpdatePassengerRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PnrService {

    /**
     * Store PNR data
     * Store Passenger Name Record (PNR) data with the provided PNR dump and sale ID
     * @param requestBody
     * @returns any Response with the stored PNR data
     * @throws ApiError
     */
    public static storePnr(
        requestBody: PnrRequest,
    ): CancelablePromise<{
        message?: string;
        data?: Pnr;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/pnr',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * Update Passenger Data
     * Update the data of a passenger and their associated PNR passenger record
     * @param requestBody
     * @returns any Passenger data updated successfully
     * @throws ApiError
     */
    public static updatePassengerData(
        requestBody: UpdatePassengerRequest,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/pnr/update-passenger',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * Get PNR Passengers by Sale
     * Get all PNR passengers associated with a specific sale
     * @param saleId ID of the Sale
     * @returns PnrPassenger List of PNR passengers
     * @throws ApiError
     */
    public static getPnrPassengersBySale(
        saleId: number,
    ): CancelablePromise<Array<PnrPassenger>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pnr/sale/{saleId}/passengers',
            path: {
                'saleId': saleId,
            },
            errors: {
                404: `Sale or PNR not found`,
            },
        });
    }

}
