import { FC } from "react";
import {
  Card as MuiCard,
  CardContent,
  CardHeader as MuiCardHeader,
  Link as MuiLink,
} from "@mui/material";
import styled from "@emotion/styled";

interface Interface {
  name: string;
  value: string | number | null | Array<string> | Array<number>;
  main?: boolean;
  width?: string;
  link?: string; // Добавили поле для ссылки
}

const InfoBadge: FC<Interface> = ({
  name,
  value,
  main = false,
  width,
  link,
}) => {
  const Card = styled(MuiCard)(({ theme }) => ({
    backgroundColor: main ? "" : theme.card.headerBackground, // This uses the primary color from the theme
    width: width ?? "170px",
    marginRight: 10,
    textAlign: "center",
  }));

  const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
    backgroundColor: main
      ? theme.card.headerBackground
      : theme.palette.background.default, // This uses the primary color from the theme
    width: width ?? "170px",
    marginRight: 10,
    textAlign: "center",
  }));

  return (
    <Card>
      <CardHeader title={name} />
      <CardContent sx={{ justifyContent: "center" }}>
        {Array.isArray(value) ? (
          value.map((item, index) => (
            <span key={index}>
              {link ? (
                <MuiLink href={link} target="_blank" rel="noopener noreferrer">
                  {item}
                </MuiLink>
              ) : (
                item
              )}
              <br />
            </span>
          ))
        ) : link ? (
          <MuiLink href={link} target="_blank" rel="noopener noreferrer">
            {value}
          </MuiLink>
        ) : (
          value
        )}
      </CardContent>
    </Card>
  );
};

export default InfoBadge;
