import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { FC } from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setProductStatusAction } from "../../../redux/slices/actions/salesActions";

export interface SimpleDialogProps {
  open: boolean;
  selectedValue:
    | "created"
    | "notCreated"
    | "requested"
    | "declined"
    | "rejected"
    | "charged"
    | "approved";
  onClose: (
    value:
      | "created"
      | "notCreated"
      | "requested"
      | "declined"
      | "rejected"
      | "charged"
      | "approved"
  ) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: any) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set Status</DialogTitle>
      <FormControl
        sx={{
          width: 200,
          alignSelf: "center",
          justifySelf: "center",
          marginBottom: 2,
          marginLeft: 2,
          marginRight: 2,
        }}
      >
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          value={selectedValue}
          label="Status"
          onChange={(event) => handleListItemClick(event.target.value)}
          placeholder="Select Status"
          inputProps={{
            name: "status",
            id: "status",
          }}
        >
          <MenuItem value="created">Created</MenuItem>
          <MenuItem value="notCreated">Not Created</MenuItem>
          <MenuItem value="requested">Requested</MenuItem>
          <MenuItem value="declined">Declined</MenuItem>
          <MenuItem value="rejected">Rejected</MenuItem>
          <MenuItem value="charged">Charged</MenuItem>
          <MenuItem value="charged">Approved</MenuItem>
        </Select>
      </FormControl>
    </Dialog>
  );
}

interface InterfaceStatusDialog {
  type: "tips" | "travelProtection";
  productId: number;
  initialValue: string;
  status: any;
  change(e: any): void;
}

const StatusDialog: FC<InterfaceStatusDialog> = ({
  type,
  productId,
  change,
  status,
}) => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState<
    | "created"
    | "notCreated"
    | "requested"
    | "declined"
    | "rejected"
    | "charged"
    | "approved"
  >(status);
  const dispatch: AppDispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    value:
      | "created"
      | "notCreated"
      | "requested"
      | "declined"
      | "rejected"
      | "charged"
      | "approved"
  ) => {
    setOpen(false);
    setSelectedValue(value);
    change(value);
    const requestBody = {
      id: productId,
      type: type,
      status: value,
    };
    dispatch(setProductStatusAction({ requestBody }));
    // dispatch(fetchSaleDetails(Number(id)));
  };
  return (
    <React.Fragment>
      <SimpleDialog
        open={open}
        selectedValue={selectedValue}
        onClose={handleClose}
      />
      <Button
        onClick={handleClickOpen}
        sx={{ height: 50, alignSelf: "center", width: 130 }}
        variant={"contained"}
      >
        Change status
      </Button>
    </React.Fragment>
  );
};
export default StatusDialog;
