import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Grid,
  Input,
  styled,
  TableCell as MuiTableCell,
  TableHead,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../redux/store";
import { Airfare, Passenger } from "../../../../../../services/openapi";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { FC, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useAuth from "../../../../../../hooks/useAuth";
import RevenueTicketTable from "../../../tables/FinancialInfo/RevenueTicketTable";
import PassengersTable from "../../../tables/PassengersTable";
import {
  approveTicketNumberTableAction,
  confirmTicketNumberTableAction,
  setTicketNumberAction,
} from "../../../../../../redux/slices/actions/salesActions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `passenger-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TableCell = styled(MuiTableCell)`
  width: 1px;
  text-align: center;
  padding: 1px 4px;
`;

const customCell = () => {};

function checkAllPassengersHaveTicketNumbers(airfare: Airfare): boolean {
  // Проверяем, есть ли массив пассажиров
  if (!airfare.passengers || airfare.passengers.length === 0) {
    return false;
  }

  // Проверяем, что у каждого пассажира есть номер билета
  for (const passenger of airfare.passengers) {
    if (!passenger.ticketNumber) {
      return false;
    }
  }

  return true;
}

export default function PassengerTabs() {
  const [value, setValue] = React.useState(0);
  const [passengers, setPassengers] = React.useState<Passenger[] | undefined>();
  const [showConfirmTicketNumbers, setShowConfirmTicketNumbers] =
    React.useState<boolean>(false);
  const [confirmAirfareTicketNumber, setConfirmAirfareTicketNumber] =
    React.useState<boolean>(false);
  const [approveAirfareTicketNumber, setApproveAirfareTicketNumber] =
    React.useState<boolean>(false);
  const [prev, setPrev] = React.useState<
    { [index: number]: Passenger } | undefined
  >({});
  const { saleDetails } = useSelector((state: RootState) => state.sales);
  useEffect(() => {
    const oafWithPassengers = saleDetails.oafs.find(
      (oaf) => oaf.type === "default" && oaf.airfares
    );

    if (oafWithPassengers?.airfares) {
      const airfaresWithsegments = oafWithPassengers.airfares.filter(
        (airfare) => airfare?.flightLegs && airfare.flightLegs.length > 0
      );

      if (airfaresWithsegments.length > 0) {
        setPassengers(airfaresWithsegments[0].passengers);
        setPrev(airfaresWithsegments[0].passengers);
        if (
          checkAllPassengersHaveTicketNumbers(airfaresWithsegments[0]) &&
          saleDetails.status === "inProgress"
        ) {
          setShowConfirmTicketNumbers(true);
          if (airfaresWithsegments[0].ticket_number_confirmed) {
            setConfirmAirfareTicketNumber(true);
          }
        }
        if (saleDetails.status === "inApproving") {
          if (airfaresWithsegments[0].ticket_number_approved) {
            setApproveAirfareTicketNumber(
              airfaresWithsegments[0].ticket_number_approved
            );
          }
        }
      }
    }
  }, [saleDetails]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch: AppDispatch = useDispatch();

  const handleFocus = (index: number) => {
    if (prev && !prev[index] && passengers) {
      setPrev({ ...prev, [index]: passengers[index] });
    }
  };

  const handleCellChange = (
    index: number,
    key: string,
    value: string | number
  ) => {
    const updatedPassengers =
      passengers &&
      passengers.map((passenger, i) =>
        i === index ? { ...passenger, [key]: value } : passenger
      );
    setPassengers(updatedPassengers);
  };

  const handleConfirm = async (index: number) => {
    if (prev && prev[index]) {
      let newPrev = { ...prev };
      delete newPrev[index];
      setPrev(newPrev);

      const passenger = passengers && passengers[index];

      const args = {
        id: passenger!.id, // Replace with actual ID
        ticketNumberRequest: { ticketNumber: passenger?.ticketNumber || "" }, // Replace with actual ticket number request
      };
      await dispatch(setTicketNumberAction(args));
    }
  };

  const handleCancel = (index: number) => {
    if (prev && prev[index] && passengers) {
      let newPassengers = [...passengers];
      newPassengers[index] = prev[index];
      setPassengers(newPassengers);
      let newPrev = { ...prev };
      delete newPrev[index];
      setPrev(newPrev);
    }
  };
  const oaf = saleDetails.oafs.find((oaf) => oaf.type === "default");
  const { role } = useAuth();

  const confirmTicketNumbersTable = () => {
    dispatch(
      confirmTicketNumberTableAction({ airfareId: passengers![0].airfareId })
    ).then((actionResult) => {
      if (confirmTicketNumberTableAction.fulfilled.match(actionResult)) {
        setConfirmAirfareTicketNumber(!confirmAirfareTicketNumber);
      } else if (confirmTicketNumberTableAction.rejected.match(actionResult)) {
        console.error("Ошибка при выполнении действия: ", actionResult.payload);
      }
    });
  };
  const approveTicketNumbersTable = () => {
    dispatch(
      approveTicketNumberTableAction({ airfareId: passengers![0].airfareId })
    ).then((actionResult) => {
      if (approveTicketNumberTableAction.fulfilled.match(actionResult)) {
        setConfirmAirfareTicketNumber(!confirmAirfareTicketNumber);
      } else if (confirmTicketNumberTableAction.rejected.match(actionResult)) {
        console.error("Ошибка при выполнении действия: ", actionResult.payload);
      }
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Passengers" {...a11yProps(0)} />
          <Tab label="Financial info" {...a11yProps(1)} />
          <Tab label="Ticket number" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {passengers && <PassengersTable passengers={passengers} />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <RevenueTicketTable tickets={oaf?.revenueTickets || []} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container>
          <Grid item xs={10}>
            <TableContainer component={Card} sx={{ mt: 2, width: "100%" }}>
              <Table
                size="small"
                aria-label="a dense table"
                sx={{ width: "100%" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>#</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>PTC</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>FIRST NAME</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>MID NAME</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>LAST NAME</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>
                      TICKET NUMBER
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {passengers &&
                    passengers.map((passenger, index) => (
                      <TableRow key={index}>
                        <TableCell>{passenger.id}</TableCell>
                        <TableCell>{passenger.ptc}</TableCell>
                        <TableCell>{passenger.firstName}</TableCell>
                        <TableCell>{passenger.middleName}</TableCell>
                        <TableCell>{passenger.lastName}</TableCell>

                        <TableCell
                          sx={{
                            backgroundColor: passenger.ticketNumber
                              ? theme.palette.success.light
                              : theme.palette.warning.light,
                            minWidth: "30px",
                            maxWidth: "200px",
                            padding: 0,
                          }}
                        >
                          <TextField
                            disabled={
                              !(role === "ticketing" || role === "manager") ||
                              confirmAirfareTicketNumber
                            }
                            inputRef={inputRef}
                            inputProps={{ maxLength: 15 }}
                            sx={{
                              textAlign: "center",
                              width: "100%",
                              height: "100%",
                              "&::before": {
                                border: "none",
                              },
                              "&::after": {
                                border: `3px solid ${theme.palette.divider}`,
                              },
                              "&:hover:not(.Mui-disabled):before": {
                                borderBottom: "none",
                              },
                              "&.Mui-focused": {
                                // outline: `2px solid ${theme.palette.divider}`,
                                border: `3px solid ${theme.palette.divider}`,
                              },
                            }}
                            onFocus={() => handleFocus(index)}
                            value={passenger.ticketNumber || ""}
                            onChange={(e) =>
                              handleCellChange(
                                index,
                                "ticketNumber",
                                e.target.value
                              )
                            }
                            onKeyUp={(e) => {
                              if (e.key === "Escape") {
                                handleCancel(index);
                                (e.target as HTMLInputElement).blur();
                              }
                              if (e.key === "Enter") {
                                handleConfirm(index);
                                (e.target as HTMLInputElement).blur();
                              }
                            }}
                          />
                        </TableCell>

                        <TableCell sx={{ border: "none", width: 290 }}>
                          {prev &&
                            prev[index] &&
                            JSON.stringify(passengers[index]) !==
                              JSON.stringify(prev[index]) && (
                              <>
                                <Button
                                  sx={{ marginRight: 3 }}
                                  color={"success"}
                                  variant={"contained"}
                                  onClick={() => handleConfirm(index)}
                                >
                                  Confirm
                                </Button>
                                <Button
                                  onClick={() => handleCancel(index)}
                                  variant="outlined"
                                  color="error"
                                >
                                  Cancel
                                </Button>
                              </>
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {showConfirmTicketNumbers && (
            <Grid item xs={2} alignItems={"center"} display={"flex"}>
              <Button variant={"contained"} onClick={confirmTicketNumbersTable}>
                {confirmAirfareTicketNumber ? "Unconfirm" : "Confirm"} Ticket
                Numbers Table
              </Button>
            </Grid>
          )}
          {saleDetails.status === "inApproving" && (
            <Grid item xs={2} alignItems={"center"} display={"flex"}>
              <Button variant={"contained"} onClick={approveTicketNumbersTable}>
                {confirmAirfareTicketNumber ? "Reject " : "Approve"} Ticket
                Numbers Table
              </Button>
            </Grid>
          )}
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}
