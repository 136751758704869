import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Chip, TableHead } from "@mui/material";
import { FC } from "react";
import { TransactionsTableData } from "../TabContent/Sale/TransactionSection";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import moment from "moment/moment";
import { confirmTransactionAction } from "../../../../redux/slices/actions/salesActions";

const TransactionTable: FC = () => {
  const { saleDetails } = useSelector((state: RootState) => state.sales);
  const dispatch: AppDispatch = useDispatch();
  const totalAmount = saleDetails.totalCharge;
  return (
    <TableContainer component={Paper} sx={{ width: 1080 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell width={130}>Date</TableCell>
            <TableCell width={40}>ID</TableCell>
            <TableCell width={70}>Type</TableCell>
            <TableCell width={70}>OAF</TableCell>
            <TableCell width={108}>Method</TableCell>
            <TableCell width={95}>Amount</TableCell>
            <TableCell width={125}>Transaction ID</TableCell>
            <TableCell width={125}>Approval Code</TableCell>
            <TableCell width={70}>Status</TableCell>
            <TableCell>Products</TableCell>
            {saleDetails.status === "inProgress" && (
              <TableCell>Actions</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {saleDetails.transactions &&
            saleDetails.transactions.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  {moment(row.created_at).format("YYYY-MM-DD HH:mm")}
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.oafId}</TableCell>
                <TableCell>{row.method}</TableCell>
                <TableCell>$ {row.amount}.00</TableCell>
                <TableCell>{row.transactionId}</TableCell>
                <TableCell>{row.approvalCode}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>
                  {row.products ? (
                    row.products.map((i) => (
                      <Chip
                        size={"small"}
                        label={i}
                        sx={{ marginRight: 1 }}
                        key={i}
                      />
                    ))
                  ) : (
                    // Handle the case when row.products is null
                    <span>No products available</span>
                  )}
                </TableCell>
                {saleDetails.status === "inProgress" && (
                  <TableCell>
                    <Button
                      onClick={() =>
                        dispatch(confirmTransactionAction({ id: row.id }))
                      }
                    >
                      {row.confirmed ? "Unconfirm" : "Confirm"}
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          <TableRow>
            <TableCell sx={{ border: "none" }}></TableCell>
            <TableCell sx={{ border: "none" }}></TableCell>
            <TableCell sx={{ border: "none" }}></TableCell>
            <TableCell sx={{ border: "none" }}></TableCell>
            <TableCell sx={{ border: "none" }}></TableCell>
            <TableCell sx={{ bgcolor: "red" }}>
              $ {saleDetails.totalCharge}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TransactionTable;
