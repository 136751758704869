import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Tabs,
  Typography,
} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import { spacing } from "@mui/system";
import SalesTable from "../tables/sales/SalesTable";
import Tab from "@mui/material/Tab";
import useAuth from "../../hooks/useAuth";
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function InvoiceList() {
  // const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const target = event.target as HTMLElement;
    setValue(target.tabIndex);
  };
  const { role } = useAuth();

  const getInitialTabValue = (role: any) => {
    // Установите начальное значение в зависимости от роли
    if (role === "manager" || role === "agent" || role === "ticketing") {
      return 0; // Первая вкладка для этих ролей
    } else if (role === "bookkeeping") {
      return 4; // Первая вкладка для роли "bookkeeping"
    }
    return 0; // Значение по умолчанию, если роль не соответствует ожидаемой
  };

  const [value, setValue] = useState(getInitialTabValue(role));
  useEffect(() => {
    const res = role === "bookkeeping" ? 4 : 0;
    setValue(res);
  }, [role]);

  return (
    <React.Fragment>
      <Helmet title="Sales" />
      <Grid
        justifyContent="space-between"
        container
        spacing={10}
        p={5}
        alignItems={"center"}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Sales
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/dashboard">
              Dashboard
            </Link>
            <Typography>Sales</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {(role === "manager" ||
              role === "agent" ||
              role === "ticketing") && (
              <Tab value={0} label="Open" tabIndex={0} {...a11yProps(0)} />
            )}
            {(role === "manager" ||
              role === "agent" ||
              role === "ticketing") && (
              <Tab label="Pending" value={1} tabIndex={1} {...a11yProps(1)} />
            )}
            {(role === "manager" ||
              role === "ticketing" ||
              role === "agent") && (
              <Tab
                value={2}
                label="NEW SALES(TICKETING) "
                tabIndex={2}
                {...a11yProps(2)}
              />
            )}
            {(role === "manager" || role === "ticketing") && (
              <Tab
                value={3}
                label={
                  role === "ticketing"
                    ? "My sales (In Progress)"
                    : "In progress"
                }
                tabIndex={3}
                {...a11yProps(3)}
              />
            )}
            {(role === "manager" || role === "bookkeeping") && (
              <Tab label="Processed" value={4} tabIndex={4} {...a11yProps(4)} />
            )}
            {(role === "bookkeeping" || role === "manager") && (
              <Tab
                label={
                  role === "bookkeeping"
                    ? "My Sales (In Approving)"
                    : "In Approving"
                }
                value={7}
                tabIndex={7}
                {...a11yProps(7)}
              />
            )}
            {(role === "manager" || role === "bookkeeping") && (
              <Tab label="Approved" value={5} tabIndex={5} {...a11yProps(5)} />
            )}
            {role === "manager" && (
              <Tab label="Rejected" value={6} tabIndex={6} {...a11yProps(6)} />
            )}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <SalesTable type={"open"} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SalesTable type={"pending"} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <SalesTable type={"ticketing"} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <SalesTable type={"inProgress"} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <SalesTable type={"processed"} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <SalesTable type={"approved"} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <SalesTable type={"rejected"} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          <SalesTable type={"inApproving"} />
        </CustomTabPanel>
      </Box>
    </React.Fragment>
  );
}

export default InvoiceList;
