import Box from "@mui/material/Box";
import { Card as MuiCard, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import * as React from "react";
import InfoBadge from "../../../InfoBadge";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { OAF } from "../../../../../services/openapi";
import { FC } from "react";
import moment from "moment/moment";
import OAFCard from "../../../OAFCard";

const OafSection = () => {
  const { oafs } = useSelector((state: RootState) => state.sales.saleDetails);

  return (
    <Box display={"flex"} mt={3} padding={3}>
      <Grid container spacing={1}>
        <Grid item md={12} display={"flex"}>
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {oafs && oafs.map((oaf, index) => <OAFCard key={index} />)}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OafSection;
