export function splitName(
  fullName: string,
  type: "lastName" | "midName" | "firstName"
) {
  try {
    const [lastName, firstAndMidName] = fullName.split("/");

    // Проверка на наличие среднего имени
    const firstAndMidNameParts = firstAndMidName.trim().split(" ");
    const firstName = firstAndMidNameParts[0];
    const midName =
      firstAndMidNameParts.length > 1
        ? firstAndMidNameParts.slice(1).join(" ")
        : "";
    switch (type) {
      case "firstName":
        return firstName;
      case "lastName":
        return lastName;
      case "midName":
        return midName;
    }
  } catch (e) {
    return fullName;
  }
  // Разделение строки на фамилию и остальное
}
