import { Grid } from "@mui/material";
import { DataGrid, GridFilterModel, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { Log } from "../../../../../services/openapi";
const LogSection = () => {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "rating",
        operator: ">",
        value: "2.5",
      },
    ],
  });

  const columns = [
    { field: "created_at", headerName: "Date", width: 150 },
    { field: "actionBy", headerName: "Action by", width: 150 },
    { field: "objectType", headerName: "Object", width: 150 },
    { field: "objectId", headerName: "Object ID", width: 150 },
    { field: "event", headerName: "Event", width: 150 },
  ];
  const { logs } = useSelector((state: RootState) => state.sales.saleDetails);

  const formattedLogs: Log[] | undefined = logs?.map((log) => ({
    ...log,
    created_at: moment(log.created_at).format("YYYY-MM-DD HH:mm"), // Форматируем дату
  }));

  return (
    <Grid container spacing={2} padding={3}>
      {formattedLogs && (
        <Grid item md={12}>
          <DataGrid
            columns={columns}
            rows={formattedLogs}
            slots={{
              toolbar: GridToolbar,
            }}
            density={"compact"}
            sx={{
              maxWidth: 780,
              boxShadow: 2,
              backgroundColor: "background.default", // Здесь ваш цвет фона
            }}
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) =>
              setFilterModel(newFilterModel)
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default LogSection;
