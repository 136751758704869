import React from "react";
import styled from "@emotion/styled";

import {
  Badge,
  Grid,
  Avatar,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 16px;
  font-weight: 500;
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 16px;
  display: block;
  padding: 1px;
`;

const Avatar1 = styled(Avatar)`
  width: 64px;
  height: 64px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  const { user, role, setRole } = useAuth();
  return (
    <Footer {...rest}>
      <Grid
        container
        spacing={2}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            {!!user && <Avatar1 alt={"sdasdas"} src={user.avatar} />}
            {/* Demo data */}
            {!user && (
              <Avatar1
                alt="Lucy Lavender"
                src="/static/img/avatars/avatar-1.jpg"
              />
            )}
          </FooterBadge>
        </Grid>
        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {!!user && (
            <FooterText variant="body2">
              {user.work_data.display_name} {user.work_data.display_last_name}
            </FooterText>
          )}
          {!!user && (
            <FooterText variant="body2">{user.work_data.work_email}</FooterText>
          )}
          {/* Demo data */}
          {!user && <FooterText variant="body2">Lucy Lavender</FooterText>}
          <FooterSubText variant="caption">$ 0</FooterSubText>
          <FormControl size={"small"} fullWidth>
            <InputLabel sx={{ color: "white" }} id="demo-simple-select-label">
              Role
            </InputLabel>
            <Select
              sx={{ color: "white" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Role"
              // @ts-ignore
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value={"agent"}>Agent</MenuItem>
              <MenuItem value={"ticketing"}>Ticketing Master </MenuItem>
              <MenuItem value={"bookkeeping"}>Bookkeeping Master</MenuItem>
              <MenuItem value={"manager"}>Manager</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
