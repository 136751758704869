import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHead } from "@mui/material";
import { FC, useEffect } from "react";
import { CommentsTableData } from "../TabContent/Sale/CommentSection";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import moment from "moment";
import { log } from "util";

const CommentsTable: FC<{}> = () => {
  const { comments } = useSelector(
    (state: RootState) => state.sales.saleDetails
  );

  return (
    <TableContainer component={Paper} sx={{ mt: 2, width: 700 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell width={130}>Date</TableCell>
            <TableCell width={150}>Made By</TableCell>
            <TableCell>Comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {comments &&
            comments?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  {moment(row.created_at).format("YYYY-MM-DD HH:mm")}{" "}
                </TableCell>
                <TableCell>
                  {row.createdBy === 1234 ? "Me" : row.createdBy}
                </TableCell>
                <TableCell>{row.text}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CommentsTable;
