import React, { FC, useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Card, InputAdornment, makeStyles, Typography } from "@mui/material";
import {
  AwardTicketRequest,
  RevenueTicketRequest,
  SaleRequest,
} from "../../../services/openapi";

import { FieldArray, FormikErrors } from "formik";
import { ExtendedSaleRequest } from "../../../pages/forms/CreateOAForm";
import { debounce } from "lodash";

interface Props {
  values: any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  touched: any;
  errors: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<ExtendedSaleRequest>>;
}

interface RevenueSettings {
  sellingPrice: number | "";
  netPrice: number | "";
}

const RevenueTicketForm: FC<Props> = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue,
}) => {
  const revenueSettings: RevenueSettings = {
    sellingPrice: 0,
    netPrice: 0,
  };
  const [adtSettings, setAdtSettings] =
    useState<RevenueSettings>(revenueSettings);
  const [chdSettings, setChdSettings] =
    useState<RevenueSettings>(revenueSettings);
  const [infSettings, setInfSettings] =
    useState<RevenueSettings>(revenueSettings);

  const totalSellingPrice = () => {
    let total = 0;
    values.tickets.forEach((ticket: RevenueTicketRequest) => {
      total += ticket.selling_price || 0;
    });
    total =
      values.travelProtection !== 0
        ? total * values.travelProtection + total
        : total;
    return total + Number(values.tips);
  };

  const recalculate = async () => {
    const updatedTickets = values.tickets.map(
      (ticket: RevenueTicketRequest) => {
        // Выбираем настройки на основе типа пассажира (ptc)
        const settings: RevenueSettings =
          ticket.ptc === "ADT"
            ? adtSettings
            : ticket.ptc === "CHD"
            ? chdSettings
            : infSettings;
        console.log("settings", settings);
        return {
          ...ticket,
          selling_price:
            settings.sellingPrice !== "" ? settings.sellingPrice : 0,
          net_price: settings.netPrice !== "" ? settings.netPrice : 0,
        };
      }
    );
    console.log("updatedTickets", updatedTickets);
    await setFieldValue("tickets", updatedTickets);
    return values.tickets; // Если у вас есть функция обновления состояния tickets
  };

  useEffect(() => {
    recalculate();
  }, [adtSettings, chdSettings, infSettings]);

  useEffect(() => {
    const newTotalCharge = totalSellingPrice();
    setFieldValue("totalCharge", newTotalCharge);
  }, [values.tickets, values.tips, values.travelProtection, values.passengers]);
  return (
    <>
      <style>
        {`
          input[type='number']::-webkit-inner-spin-button, 
          input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type='number'] {
            -moz-appearance: textfield;
          }
        `}
      </style>
      <Card sx={{ padding: 2, marginBottom: 2 }} variant={"outlined"}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            display={"flex"}
            flexDirection={"row"}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
            >
              <Typography sx={{ margin: 0 }}>ADT:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                value={adtSettings.sellingPrice}
                onChange={(e) => {
                  setAdtSettings({
                    ...adtSettings,
                    sellingPrice:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="sellingPrice"
                label="sellingPrice"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                value={adtSettings.netPrice}
                onChange={(e) => {
                  setAdtSettings({
                    ...adtSettings,
                    netPrice:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="netPrice"
                label="netPrice"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            display={"flex"}
            flexDirection={"row"}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
            >
              <Typography sx={{ margin: 0 }}>CHD:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                value={chdSettings.sellingPrice}
                onChange={(e) => {
                  setChdSettings({
                    ...chdSettings,
                    sellingPrice:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="sellingPrice"
                label="sellingPrice"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                value={chdSettings.netPrice}
                onChange={(e) => {
                  setChdSettings({
                    ...chdSettings,
                    netPrice:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="netPrice"
                label="netPrice"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            display={"flex"}
            flexDirection={"row"}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
            >
              <Typography sx={{ margin: 0 }}>INF:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                value={infSettings.sellingPrice}
                onChange={(e) => {
                  setInfSettings({
                    ...infSettings,
                    sellingPrice:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="sellingPrice"
                label="sellingPrice"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                value={infSettings.netPrice}
                onChange={(e) => {
                  setInfSettings({
                    ...infSettings,
                    netPrice:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="netPrice"
                label="netPrice"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <FieldArray
        name="tickets"
        render={(arrayHelpers) => (
          <>
            {values.tickets.map(
              (ticket: RevenueTicketRequest, index: number) => (
                <Card
                  key={index}
                  sx={{ padding: 2, marginBottom: 2 }}
                  variant={"outlined"}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        name={`tickets.${index}.pax_name`}
                        label="pax_name"
                        value={ticket?.pax_name}
                        disabled
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.ticket?.pax_name && errors.ticket?.pax_name
                        )}
                        helperText={
                          touched.ticket?.pax_name && errors.ticket?.pax_name
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        name={`tickets.${index}.ptc`}
                        label="PTC"
                        value={ticket?.ptc}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                        error={Boolean(
                          touched.ticket?.ptc && errors.ticket?.ptc
                        )}
                        helperText={touched.ticket?.ptc && errors.ticket?.ptc}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        name="ticket.selling_price"
                        label="selling_price"
                        value={ticket?.selling_price}
                        onChange={handleChange}
                        disabled
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.ticket?.selling_price &&
                            errors.ticket?.selling_price
                        )}
                        helperText={
                          touched.ticket?.selling_price &&
                          errors.ticket?.selling_price
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        name={`tickets.${index}.net_price`}
                        label="net_price"
                        value={ticket?.net_price}
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        // error={Boolean(
                        //   touched.ticket?.net_price && errors.ticket?.net_price
                        //)}
                        // helperText={touched.ticket?.net_price && errors.ticket?.net_price}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                        name={`tickets.${index}.consolidator_name`}
                        label="Consolidator Name"
                        value={ticket?.consolidator_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.ticket?.mileage_program_name &&
                            errors.ticket?.mileage_program_name
                        )}
                        helperText={
                          touched.ticket?.mileage_program_name &&
                          errors.ticket?.mileage_program_name
                        }
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Card>
              )
            )}
          </>
        )}
      />
    </>
  );
};

interface AwardSettings {
  sellingPrice: number | "";
  netPrice: number | "";
  miles: number | "";
  price_per_mile: number | "";
  taxes: number | "";
}
const AwardTicketForm: FC<Props> = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue,
}) => {
  const awardSettings: AwardSettings = {
    sellingPrice: 0.0,
    netPrice: 0.0,
    miles: 0.0,
    price_per_mile: 0.0,
    taxes: 0.0,
  };
  const [adtSettings, setAdtSettings] = useState<AwardSettings>(awardSettings);
  const [chdSettings, setChdSettings] = useState<AwardSettings>(awardSettings);

  const totalSellingPrice = () => {
    let total = 0;
    values.tickets.forEach((ticket: AwardTicketRequest) => {
      total += ticket.selling_price || 0;
    });
    return total;
  };

  const recalculate = async () => {
    const updatedTickets = values.tickets.map((ticket: AwardTicketRequest) => {
      const settings = ticket.ptc === "ADT" ? adtSettings : chdSettings;

      // Рассчитываем новые значения. Пример:
      const miles = settings.miles !== "" ? settings.miles : 0;
      const price_per_mile =
        settings.price_per_mile !== "" ? settings.price_per_mile : 0;
      const taxes = settings.taxes !== "" ? settings.taxes : 0;
      const sellingPrice =
        settings.sellingPrice !== "" ? settings.sellingPrice : 0;
      return {
        ...ticket,
        selling_price: sellingPrice,
        miles: miles,
        price_per_mile: price_per_mile,
        taxes: taxes,
        net_price: miles * price_per_mile + taxes,
      };
    });
    // Обновляем состояние tickets или выполняем другие действия с updatedTickets
    await setFieldValue("tickets", updatedTickets);
  };

  useEffect(() => {
    const chdMiles = chdSettings.miles !== "" ? chdSettings.miles : 0;
    const chdpPicePerMile =
      chdSettings.price_per_mile !== "" ? chdSettings.price_per_mile : 0;
    const chdTaxes = chdSettings.taxes !== "" ? chdSettings.taxes : 0;
    setChdSettings({
      ...chdSettings,
      netPrice: chdMiles * chdpPicePerMile + chdTaxes,
    });

    const adtMiles = adtSettings.miles !== "" ? adtSettings.miles : 0;
    const adtpPicePerMile =
      adtSettings.price_per_mile !== "" ? adtSettings.price_per_mile : 0;
    const adtTaxes = adtSettings.taxes !== "" ? adtSettings.taxes : 0;
    setAdtSettings({
      ...adtSettings,
      netPrice: adtMiles * adtpPicePerMile + adtTaxes,
    });
    recalculate();
  }, [
    adtSettings.price_per_mile,
    adtSettings.sellingPrice,
    adtSettings.miles,
    adtSettings.taxes,
    chdSettings.price_per_mile,
    chdSettings.sellingPrice,
    chdSettings.miles,
    chdSettings.taxes,
    // chdSettings,
  ]);

  useEffect(() => {
    const newTotalCharge = totalSellingPrice();
    setFieldValue("totalCharge", newTotalCharge);
  }, [values.tickets, values.tips, values.travelProtection]);

  const handleChangeSellingPrice = (e: any) => {
    const value = e.target.value === "" ? "" : parseFloat(e.target.value);
    setAdtSettings({
      ...adtSettings,
      sellingPrice: value,
    });
  };
  return (
    <>
      <style>
        {`
          input[type='number']::-webkit-inner-spin-button, 
          input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type='number'] {
            -moz-appearance: textfield;
          }
        `}
      </style>
      <Card sx={{ padding: 2, marginBottom: 2 }} variant={"outlined"}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            display={"flex"}
            flexDirection={"row"}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
            >
              <Typography sx={{ margin: 0 }}>ADT:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                value={adtSettings.sellingPrice}
                onChange={handleChangeSellingPrice}
                inputProps={{ inputMode: "decimal" }}
                name="sellingPrice"
                label="sellingPrice"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                disabled
                value={adtSettings.netPrice}
                onChange={(e) => {
                  setAdtSettings({
                    ...adtSettings,
                    netPrice:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="netPrice"
                label="netPrice"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                name="miles"
                label="miles"
                fullWidth
                type="number"
                value={adtSettings.miles}
                onChange={(e) => {
                  setAdtSettings({
                    ...adtSettings,
                    miles:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                value={adtSettings.price_per_mile}
                onChange={(e) => {
                  setAdtSettings({
                    ...adtSettings,
                    price_per_mile:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="price_per_mile"
                label="Price per mile"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                name="taxes"
                label="taxes"
                fullWidth
                type="number"
                value={adtSettings.taxes}
                onChange={(e) => {
                  setAdtSettings({
                    ...adtSettings,
                    taxes:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            display={"flex"}
            flexDirection={"row"}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
            >
              <Typography sx={{ margin: 0 }}>CHD:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                value={chdSettings.sellingPrice}
                onChange={(e) => {
                  setChdSettings({
                    ...chdSettings,
                    sellingPrice:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="sellingPrice"
                label="sellingPrice"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                disabled
                value={chdSettings.netPrice}
                onChange={(e) => {
                  setChdSettings({
                    ...chdSettings,
                    netPrice:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="netPrice"
                label="netPrice"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                name="miles"
                label="miles"
                fullWidth
                type="number"
                value={chdSettings.miles}
                onChange={(e) => {
                  setChdSettings({
                    ...chdSettings,
                    miles:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                type="number"
                value={chdSettings.price_per_mile}
                onChange={(e) => {
                  setChdSettings({
                    ...chdSettings,
                    price_per_mile:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
                name="price_per_mile"
                label="Price per mile"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                size={"small"}
                name="taxes"
                label="taxes"
                fullWidth
                type="number"
                value={chdSettings.taxes}
                onChange={(e) => {
                  setChdSettings({
                    ...chdSettings,
                    taxes:
                      e.target.value === "" ? "" : parseFloat(e.target.value),
                  });
                }}
                inputProps={{ inputMode: "decimal" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <FieldArray
        name="tickets"
        render={(arrayHelpers) => (
          <>
            {values.tickets.map((ticket: AwardTicketRequest, index: number) => (
              <Card
                key={index}
                sx={{ padding: 2, marginBottom: 2 }}
                variant={"outlined"}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name={`tickets.${index}.pax_name`}
                      label="pax_name"
                      value={ticket?.pax_name}
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.ticket?.pax_name && errors.ticket?.pax_name
                      )}
                      helperText={
                        touched.ticket?.pax_name && errors.ticket?.pax_name
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      name={`tickets.${index}.ptc`}
                      label="PTC"
                      value={ticket?.ptc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      error={Boolean(touched.ticket?.ptc && errors.ticket?.ptc)}
                      helperText={touched.ticket?.ptc && errors.ticket?.ptc}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      name="ticket.selling_price"
                      label="selling_price"
                      value={ticket?.selling_price}
                      onChange={handleChange}
                      disabled
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.ticket?.selling_price &&
                          errors.ticket?.selling_price
                      )}
                      helperText={
                        touched.ticket?.selling_price &&
                        errors.ticket?.selling_price
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      name={`tickets.${index}.net_price`}
                      label="net_price"
                      value={ticket?.net_price}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // error={Boolean(
                      //   touched.ticket?.net_price && errors.ticket?.net_price
                      //)}
                      // helperText={touched.ticket?.net_price && errors.ticket?.net_price}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      name={`tickets.${index}.miles`}
                      label="Miles"
                      value={ticket?.miles}
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.ticket?.miles && errors.ticket?.miles
                      )}
                      helperText={touched.ticket?.miles && errors.ticket?.miles}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      name={`tickets.${index}.taxes`}
                      label="Taxes"
                      disabled
                      value={ticket?.taxes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.ticket?.taxes && errors.ticket?.taxes
                      )}
                      helperText={touched.ticket?.taxes && errors.ticket?.taxes}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      name={`tickets.${index}.price_per_mile`}
                      label="Price/mile"
                      disabled
                      value={ticket?.price_per_mile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.ticket?.price_per_mile &&
                          errors.ticket?.price_per_mile
                      )}
                      helperText={
                        touched.ticket?.price_per_mile &&
                        errors.ticket?.price_per_mile
                      }
                      fullWidth
                    />
                  </Grid>
                  {/*<Grid item xs={12} sm={6} md={4}>*/}
                  {/*  <TextField*/}
                  {/*    name="ticket.cc_fee"*/}
                  {/*    label="cc_fee"*/}
                  {/*    value={values.ticket?.cc_fee}*/}
                  {/*    onChange={handleChange}*/}
                  {/*    onBlur={handleBlur}*/}
                  {/*    error={Boolean(touched.ticket?.cc_fee && errors.ticket?.cc_fee)}*/}
                  {/*    helperText={touched.ticket?.cc_fee && errors.ticket?.cc_fee}*/}
                  {/*    fullWidth*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} sm={6} md={4}>*/}
                  {/*  <TextField*/}
                  {/*    name="ticket.estimated_profit"*/}
                  {/*    label="estimated_profit"*/}
                  {/*    value={values.ticket?.estimated_profit}*/}
                  {/*    onChange={handleChange}*/}
                  {/*    onBlur={handleBlur}*/}
                  {/*    error={Boolean(*/}
                  {/*      touched.ticket?.estimated_profit &&*/}
                  {/*        errors.ticket?.estimated_profit*/}
                  {/*    )}*/}
                  {/*    helperText={*/}
                  {/*      touched.ticket?.estimated_profit &&*/}
                  {/*      errors.ticket?.estimated_profit*/}
                  {/*    }*/}
                  {/*    fullWidth*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={12} sm={6} md={4}>*/}
                  {/*  <TextField*/}
                  {/*    name="ticket.total_estimated_profit"*/}
                  {/*    label="total_estimated_profit"*/}
                  {/*    value={values.ticket?.total_estimated_profit}*/}
                  {/*    onChange={handleChange}*/}
                  {/*    onBlur={handleBlur}*/}
                  {/*    error={Boolean(*/}
                  {/*      touched.ticket?.total_estimated_profit &&*/}
                  {/*        errors.ticket?.total_estimated_profit*/}
                  {/*    )}*/}
                  {/*    helperText={*/}
                  {/*      touched.ticket?.total_estimated_profit &&*/}
                  {/*      errors.ticket?.total_estimated_profit*/}
                  {/*    }*/}
                  {/*    fullWidth*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name={`tickets.${index}.mileage_program_name`}
                      label="Program Name"
                      value={ticket?.mileage_program_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.ticket?.mileage_program_name &&
                          errors.ticket?.mileage_program_name
                      )}
                      helperText={
                        touched.ticket?.mileage_program_name &&
                        errors.ticket?.mileage_program_name
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Card>
            ))}
          </>
        )}
      />
    </>
  );
};

const TicketForm: FC<Props> = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue,
}) => {
  if (values.ticket_type === "RevenueTicket") {
    return (
      <RevenueTicketForm
        {...{
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          setFieldValue,
        }}
      />
    );
  } else if (values.ticket_type === "AwardTicket") {
    return (
      <AwardTicketForm
        {...{
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          setFieldValue,
        }}
      />
    );
  }

  return null; // Обработка других случаев или ошибок
};

export default TicketForm;
