import AirfareSection from "./TabContent/Sale/AirfareSection";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Divider,
  PaletteColor,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import TravelProtectionSection from "./TabContent/Sale/TravelProtectionSection";
import TipsSection from "./TabContent/Sale/TipsSection";
import TransactionSection from "./TabContent/Sale/TransactionSection";
import OafSection from "./TabContent/Sale/OAFSection";
import CommentSection from "./TabContent/Sale/CommentSection";
import LogSection from "./TabContent/Sale/LogSection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type PaletteColorKey =
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "info"
  | "success"
  | "text"
  | "background"
  | "divider"
  | "grey";

const accordionColors: PaletteColorKey[] = [
  "primary",
  "secondary",
  "error",
  "warning",
  "info",
  "success",
  "text",
  "background",
  "divider",
  "grey",
];

const Accordion = styled(MuiAccordion)<{ colorkey?: PaletteColorKey }>(
  ({ theme, colorkey }) => {
    let backgroundColor;

    if (colorkey) {
      const color = theme.palette[colorkey];
      const isPaletteColor = (color: any): color is PaletteColor =>
        color.main !== undefined;

      if (isPaletteColor(color)) {
        backgroundColor = color.main;
      }
    }

    // Если colorkey не предоставлен или не является частью палитры,
    // backgroundColor не будет установлен.

    return {
      ...(backgroundColor && { backgroundColor }),
    };
  }
);

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));

const SaleContent = () => {
  const { oafs } = useSelector((state: RootState) => state.sales.saleDetails);

  const showTravelProtections = oafs.some(
    (oaf) => oaf.travelProtections && oaf.travelProtections.length > 0
  );
  const showTips = oafs.some((oaf) => oaf.tips && oaf.tips.length > 0);

  return (
    <Box display={"flex"} padding={0}>
      <Stack width={"100%"} spacing={3}>
        <Accordion defaultExpanded>
          <AccordionSummary
            aria-expanded={false}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ paddingLeft: 3 }} variant={"h4"}>
              Airfare
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AirfareSection />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography sx={{ paddingLeft: 3 }} variant={"h4"}>
              Travel Protection
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TravelProtectionSection />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography sx={{ paddingLeft: 3 }} variant={"h4"}>
              Tips
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TipsSection />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography sx={{ paddingLeft: 3 }} variant={"h4"}>
              Transactions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TransactionSection />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography sx={{ paddingLeft: 3 }} variant={"h4"}>
              OAF-s
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OafSection />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Typography sx={{ paddingLeft: 3 }} variant={"h4"}>
              Comments
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CommentSection />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography sx={{ paddingLeft: 3 }} variant={"h4"}>
              Logs
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LogSection />
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Box>
  );
};

export default SaleContent;
