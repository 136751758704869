import * as React from "react";
import { FC, useEffect, useState } from "react";
import {
  ConfirmRevenueTicketRequest,
  RevenueTicket,
  UpdateRevenueTicketRequest,
} from "../../../../../services/openapi";
import { SelectChangeEvent } from "@mui/material/Select";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  styled,
  TableCell as MuiTableCell,
  TableHead,
  TextField as MuiTextField,
  Theme,
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { splitName } from "./helpers";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import {
  approveFinancialInfoTableAction,
  confirmFinancialInfoAction,
  confirmRevenueTicketAction,
  getVariables,
  updateRevenueTicketAction,
} from "../../../../../redux/slices/actions/salesActions";
import Box from "@mui/material/Box";

interface RevenueTableProps {
  tickets: RevenueTicket[];
  // passengers: Passenger[];
}
const TableCellHead = styled(MuiTableCell)`
  padding: ${(props) => props.theme.spacing(1)};
  text-align: center;
  white-space: nowrap;
`;
const TableCell = styled(MuiTableCell)`
  height: 27px;
  padding: 0;
  text-align: center;
`;

const TextField = styled(MuiTextField)({
  height: "100%",
  "& .MuiFormControl-root": {
    height: "100%",
  },
  "& .MuiInputBase-root": {
    // Целевой стиль для корневого элемента Input
    padding: (theme: Theme) => theme.spacing(1.5),
    textAlign: "center",
    whiteSpace: "nowrap",
    height: "100%",
  },
  "& .MuiInputBase-input": {
    textAlign: "center",
    padding: 0,
    height: "100%",
    // Другие необходимые стили
  },
  "& .MuiOutlinedInput-root": {
    height: "100%",
    borderRadius: 0,
    // Другие необходимые стили
  },
});
const RevenueTicketTable: FC<RevenueTableProps> = ({ tickets }) => {
  const dispatch: AppDispatch = useDispatch();
  const [sTickets, setTickets] = useState<RevenueTicket[]>(tickets);
  const { saleDetails, variables, success } = useSelector(
    (state: RootState) => state.sales
  );
  useEffect(() => {
    console.log("Tickets have been updated", sTickets);
  }, [sTickets]);

  useEffect(() => {
    dispatch(getVariables());
  }, []);
  const handleChange = (index: number, key: string, value: string | number) => {
    const updatedTickets = sTickets.map((ticket, i) => {
      if (i === index) {
        // Обновляем значение ticket с новым ключом и значением
        const updatedTicket = { ...ticket, [key]: value };

        if (!updatedTicket.cc_fee) {
          updatedTicket.cc_fee = parseFloat(
            (
              ticket.selling_price *
                (parseFloat(variables.cc_fee_percent!) / 100) +
              parseFloat(variables.cc_fee!)
            ).toFixed(2)
          );
        }
        console.log("key", key);
        if (updatedTicket.net_price) {
          updatedTicket.markup =
            updatedTicket.selling_price - updatedTicket.net_price;
        }
        if (!updatedTicket.processing_fee) {
          updatedTicket.processing_fee = parseFloat(variables.processing_fee!);
        }
        if (!updatedTicket.commission_type) {
          updatedTicket.commission_type = "F";
        }
        // Проверяем, существуют ли fare и comm_percent в обновлённом билете
        if (updatedTicket.fare && updatedTicket.comm_percent) {
          // Вычисляем комиссию как процент от стоимости
          // Добавляем вычисленную комиссию к обновлённому билету
          updatedTicket.comm_dollar =
            (updatedTicket.fare * updatedTicket.comm_percent) / 100;
        }

        if (
          key === "fare" &&
          updatedTicket.fare &&
          updatedTicket.fuel &&
          !updatedTicket.taxes
        ) {
          updatedTicket.taxes =
            updatedTicket.net_price - updatedTicket.fuel - updatedTicket.fare;
        }

        if (
          key === "fare" &&
          updatedTicket.fare &&
          !updatedTicket.fuel &&
          updatedTicket.taxes
        ) {
          updatedTicket.fuel =
            updatedTicket.net_price - updatedTicket.taxes - updatedTicket.fare;
        }

        if (
          key === "fuel" &&
          updatedTicket.fuel &&
          updatedTicket.fare &&
          !updatedTicket.taxes
        ) {
          updatedTicket.taxes =
            updatedTicket.net_price - updatedTicket.fuel - updatedTicket.fare;
        }
        if (
          key === "fuel" &&
          updatedTicket.fuel &&
          !updatedTicket.fare &&
          updatedTicket.taxes
        ) {
          updatedTicket.fare =
            updatedTicket.net_price - updatedTicket.fuel - updatedTicket.taxes;
        }

        if (
          key === "taxes" &&
          updatedTicket.taxes &&
          updatedTicket.fare &&
          !updatedTicket.fuel
        ) {
          updatedTicket.fuel =
            updatedTicket.net_price - updatedTicket.fare - updatedTicket.taxes;
        }

        if (
          key === "taxes" &&
          updatedTicket.taxes &&
          !updatedTicket.fare &&
          updatedTicket.fuel
        ) {
          updatedTicket.fare =
            updatedTicket.net_price - updatedTicket.fuel - updatedTicket.taxes;
        }
        console.log("updatedTicket", updatedTicket);
        if (
          updatedTicket.selling_price &&
          updatedTicket.net_price &&
          updatedTicket.fare &&
          updatedTicket.fuel &&
          updatedTicket.taxes &&
          updatedTicket.comm_dollar &&
          updatedTicket.processing_fee &&
          updatedTicket.consolidator_if &&
          key !== "estimated_profit"
        ) {
          updatedTicket.estimated_profit = Number(
            (
              updatedTicket.selling_price -
              updatedTicket.processing_fee -
              updatedTicket.net_price -
              updatedTicket.cc_fee -
              updatedTicket.consolidator_if +
              updatedTicket.comm_dollar
            ).toFixed(2)
          );
        }
        // Возвращаем обновлённый билет без комиссии, если не все данные для её расчёта присутствуют
        return updatedTicket;
      }

      // Возвращаем неизменённый билет, если индекс не совпадает
      return ticket;
    });
    // Обновляем состояние билетов
    setTickets(updatedTickets);
  };

  const { oafs } = useSelector((state: RootState) => state.sales.saleDetails);
  const [confirmTable, setConfirmTable] = useState<boolean>(false);
  const [approveTable, setApproveTable] = useState<boolean>(false);
  useEffect(() => {
    const curOAF = oafs.find((el) => el.id === tickets[0].oaf_id);
    if (curOAF) {
      setConfirmTable(curOAF.financialInfoConfirmed);
      setApproveTable(curOAF.financialInfoApproved);
    }
  }, [oafs, tickets]);

  const handleSelectChange =
    (index: number) => (event: SelectChangeEvent<string>) => {
      const name = event.target.name.replace(/[0-9]/g, "");
      const value = event.target.value;

      setTickets((prevsTickets) => {
        const newsTickets = [...prevsTickets];
        newsTickets[index] = { ...newsTickets[index], [name]: value };
        return newsTickets;
      });
    };

  function handleUpdateRow(row: RevenueTicket) {
    const requestBody: UpdateRevenueTicketRequest = {
      id: row.id,
      net_price: row.net_price,
      markup: row.net_price - row.selling_price,
      fare: row.fare!,
      fuel: row.fuel!,
      taxes: row.taxes!,
      //@ts-ignore
      commission_type: row.commission_type,
      comm_percent: row.comm_percent!,
      comm_dollar: row.comm_dollar,
      processing_fee: row.processing_fee!,
      cc_fee: row.cc_fee!,
      consolidator_if: row.consolidator_if!,
      estimated_profit: row.estimated_profit!,
      consolidator_name: row.consolidator_name ?? "",
    };
    dispatch(updateRevenueTicketAction({ requestBody }));
    const updatedTickets = sTickets.map((ticket, i) =>
      ticket.id === row.id ? row : ticket
    );
    setTickets(updatedTickets);
  }

  function handleConfirmRow(row: RevenueTicket) {
    const requestBody: ConfirmRevenueTicketRequest = {
      id: row.id,
    };
    dispatch(confirmRevenueTicketAction({ requestBody }));
    const updatedTickets = sTickets.map((ticket, i) =>
      ticket.id === row.id ? { ...ticket, confirmed: !row.confirmed } : ticket
    );
    setTickets(updatedTickets);
  }

  const confirmFinancialInfo = () => {
    dispatch(confirmFinancialInfoAction({ id: tickets[0].oaf_id }));
  };
  const approveFinancialInfo = () => {
    dispatch(approveFinancialInfoTableAction({ id: tickets[0].oaf_id }));
    setApproveTable(!approveTable);
  };
  return (
    sTickets && (
      <Grid container>
        <Grid item xs={11}>
          <TableContainer
            component={Paper}
            sx={{ mt: 2, minWidth: 1000, overflowX: "auto" }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCellHead sx={{ fontWeight: 600, padding: 0 }}>
                    #
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600, width: 20 }}>
                    PTC
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600, width: 90 }}>
                    FIRST NAME
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600, width: 115 }}>
                    MIDDLE NAME
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600, width: 95 }}>
                    LAST NAME
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>
                    Selling Price $
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>
                    Net Price $
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>Markup</TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>Fare</TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>Fuel</TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>Taxes</TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>
                    Commission Type
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>
                    Commission %
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>
                    Commission $
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>
                    Processing Fee $
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>
                    CC FEE $
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>
                    Consolidator IF
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>
                    Estimated Profit
                  </TableCellHead>
                  <TableCellHead sx={{ fontWeight: 600 }}>
                    Consolidator Name
                  </TableCellHead>
                  {saleDetails.status === "inProgress" && (
                    <TableCellHead sx={{ fontWeight: 600 }}>
                      Actions
                    </TableCellHead>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {sTickets.map((ticket, index) => (
                  <TableRow key={ticket.id}>
                    <TableCell sx={{ padding: 2 }}>{index}</TableCell>
                    <TableCell>{ticket.ptc}</TableCell>
                    <TableCell width={100}>
                      {splitName(ticket.pax_name, "firstName")}
                    </TableCell>
                    <TableCell>
                      {splitName(ticket.pax_name, "midName")}
                    </TableCell>
                    <TableCell>
                      {splitName(ticket.pax_name, "lastName")}
                    </TableCell>
                    <TableCell>{ticket.selling_price}</TableCell>
                    <TableCell sx={{ padding: 0 }}>
                      <TextField
                        disabled={ticket.confirmed}
                        sx={{ width: 100, padding: 0 }}
                        // key={"net_price" + ticket.id}
                        value={ticket.net_price}
                        onChange={(e) =>
                          handleChange(index, "net_price", e.target.value)
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      {+(ticket.selling_price - ticket.net_price).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={ticket.confirmed}
                        value={ticket.fare ?? ""}
                        onChange={(e) =>
                          handleChange(index, "fare", e.target.value)
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={ticket.confirmed}
                        value={ticket.fuel ?? ""}
                        onChange={(e) =>
                          handleChange(index, "fuel", e.target.value)
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={ticket.confirmed}
                        name={"taxes" + index.toString()}
                        value={ticket.taxes ?? ""}
                        onChange={(e) =>
                          handleChange(index, "taxes", e.target.value)
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl
                        disabled={ticket.confirmed}
                        size={"small"}
                        fullWidth
                      >
                        <Select
                          name={`commission_type` + index}
                          value={ticket.commission_type ?? "F"}
                          onChange={handleSelectChange(index)}
                        >
                          <MenuItem value={"F"}>Fare</MenuItem>
                          <MenuItem value={"FF"}>FF</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={ticket.confirmed}
                        value={ticket.comm_percent ?? ""}
                        onChange={(e) =>
                          handleChange(index, "comm_percent", e.target.value)
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={ticket.confirmed}
                        value={ticket.comm_dollar ?? ""}
                        onChange={(e) =>
                          handleChange(index, "comm_dollar", e.target.value)
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={ticket.confirmed}
                        name={"processing_fee" + index.toString()}
                        value={
                          ticket.processing_fee ??
                          variables.processing_fee ??
                          ""
                        }
                        onChange={(e) =>
                          handleChange(index, "processing_fee", e.target.value)
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={ticket.confirmed}
                        name={"cc_fee" + index.toString()}
                        value={
                          ticket.cc_fee ??
                          (
                            ticket.selling_price *
                              (parseFloat(variables.cc_fee_percent!) / 100) +
                            parseFloat(variables.cc_fee!)
                          ).toFixed(2) ??
                          ""
                        }
                        onChange={(e) =>
                          handleChange(index, "cc_fee", e.target.value)
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={ticket.confirmed}
                        name={"consolidator_if" + index.toString()}
                        value={ticket.consolidator_if ?? ""}
                        onChange={(e) =>
                          handleChange(index, "consolidator_if", e.target.value)
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={ticket.confirmed}
                        name={"estimated_profit" + index.toString()}
                        value={ticket.estimated_profit ?? ""}
                        onChange={(e) =>
                          handleChange(
                            index,
                            "estimated_profit",
                            e.target.value
                          )
                        }
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled={ticket.confirmed}
                        name={"consolidator_name" + index.toString()}
                        value={ticket.consolidator_name ?? ""}
                        onChange={(e) =>
                          handleChange(
                            index,
                            "consolidator_name",
                            e.target.value
                          )
                        }
                        fullWidth
                      />
                    </TableCell>
                    {saleDetails.status === "inProgress" && (
                      <TableCell>
                        <Box display={"flex"}>
                          <Button
                            disabled={ticket.confirmed}
                            onClick={() => handleUpdateRow(ticket)}
                          >
                            Update
                          </Button>

                          <Button
                            disabled={confirmTable}
                            onClick={() => handleConfirmRow(ticket)}
                          >
                            {ticket.confirmed ? "Unconfirm" : "Confirm"}
                          </Button>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={1} padding={3} display={"flex"} alignItems={"center"}>
          {saleDetails.status === "inProgress" && (
            <Button variant={"contained"} onClick={confirmFinancialInfo}>
              {confirmTable ? "Uconfirm" : "Confirm"} Financial Info
            </Button>
          )}
          {saleDetails.status === "inApproving" && (
            <Button variant={"contained"} onClick={approveFinancialInfo}>
              {approveTable ? "Reject" : "Approve"} Financial Info
            </Button>
          )}
        </Grid>
      </Grid>
    )
  );
};

export default RevenueTicketTable;
