import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SuccessResponse } from "../../services/openapi";
import {
  asignSaleAction,
  confirmPassengerAction,
  confirmPassengersTableAction,
  updateRevenueTicketAction,
  confirmTicketNumberTableAction,
  createCommentAction,
  createSaleAction,
  createTransactionAction,
  fetchSaleDetails,
  fetchSalesList,
  pauseSaleAction,
  rejectSaleAction,
  setProductStatusAction,
  setTicketNumberAction,
  confirmFinancialInfoAction,
  confirmTravelProtectionAction,
  confirmTransactionAction,
  confirmTipsAction,
  confirmTransactionsTableAction,
  processSaleAction,
  approveTicketNumberTableAction,
  closeSaleAction,
  approveTransactionsTableAction,
  approveTravelProtectionAction,
  approveTipsAction,
  approveFinancialInfoTableAction,
  approvePassengersTableAction,
  showCcAction,
  getVariables,
} from "./actions/salesActions";
import { SalesState } from "./types/salesTypes";

const initialState: SalesState = {
  variables: {
    cc_fee: "0",
    cc_fee_percent: "0",
    processing_fee: "0",
  },
  sales: [],
  loading: false,
  error: null,
  success: null,
  selectedAirfare: {
    id: 0,
    ticket_number_confirmed: false,
    ticket_number_approved: false,
    passengers_confirmed: false,
    passengers_approved: false,
    flightLegs: [],
    passengers: [],
    created_at: "",
    updated_at: "",
  },
  backEndMessages: [],
  saleDetails: {
    transaction_confirmed: false,
    transaction_approved: false,
    id: 0,
    pnr: "",
    totalCharge: 0,
    paymentType: "",
    ipAddress: "",
    userAgent: "",
    created_at: "", // или начальное значение даты, если требуется
    updated_at: "",
    oafs: [],
    status: "open",
  },
  pagination: {
    links: {},
    meta: {},
  },
};

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    clearError: (state, action) => {
      const { field } = action.payload;
      if (field && state.error && state.error.errors) {
        // Удаляем определенное поле из объекта ошибок
        delete state.error.errors[field];
      } else {
        state.error = null; // Если поле не указано или объект ошибок пуст, очищаем всю ошибку
      }
    },
    clearSuccess: (state, action) => {
      const { field } = action.payload;
      if (field && state.success && state.success.success) {
        // Удаляем определенное поле из объекта ошибок
        delete state.success.success[field];
      } else {
        state.success = null; // Если поле не указано или объект ошибок пуст, очищаем всю ошибку
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(showCcAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(showCcAction.fulfilled, (state, action) => {
        state.loading = false;
        state.saleDetails.paymentCards = action.payload || [];
        state.error = null;
      })
      .addCase(showCcAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to load payment cards";
      })
      .addCase(getVariables.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVariables.fulfilled, (state, action) => {
        state.loading = false;
        state.variables = action.payload!;
        state.error = null;
      })
      .addCase(getVariables.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to load payment cards";
      })
      .addCase(approveTicketNumberTableAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveTicketNumberTableAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success =
          action.payload?.message ||
          "Ticket number table successfully approved.";
      })
      .addCase(approveTicketNumberTableAction.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || "Failed to approve ticket number table.";
      })
      // Добавление обработчиков для approvePassengersTableAction
      .addCase(approvePassengersTableAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(approvePassengersTableAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success =
          action.payload?.message || "Passengers table successfully approved.";
        state.selectedAirfare.passengers_approved =
          !state.selectedAirfare.passengers_approved;
      })
      .addCase(approvePassengersTableAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to approve passengers table.";
      })
      // Обработчики для approveFinancialInfoTableAction
      .addCase(approveFinancialInfoTableAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveFinancialInfoTableAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success =
          action.payload?.message ||
          "Financial info table successfully approved.";
      })
      .addCase(approveFinancialInfoTableAction.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || "Failed to approve financial info table.";
      })
      // Обработчики для approveTipsAction
      .addCase(approveTipsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveTipsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success =
          action.payload?.message || "Tips successfully approved.";
      })
      .addCase(approveTipsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to approve tips.";
      })
      // Обработчики для approveTravelProtectionAction
      .addCase(approveTravelProtectionAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveTravelProtectionAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success =
          action.payload?.message || "Travel protection successfully approved.";
      })
      .addCase(approveTravelProtectionAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to approve travel protection.";
      })
      // Обработчики для approveTransactionsTableAction
      .addCase(approveTransactionsTableAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveTransactionsTableAction.fulfilled, (state, action) => {
        state.loading = false;
        state.saleDetails.transaction_approved =
          !state.saleDetails.transaction_approved;
        state.success =
          action.payload?.message ||
          "Transactions table successfully approved.";
      })
      .addCase(approveTransactionsTableAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to approve transactions table.";
      })
      // Обработчики для closeSaleAction
      .addCase(closeSaleAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(closeSaleAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload?.message || "Sale successfully closed.";
      })
      .addCase(closeSaleAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to close sale.";
      })
      .addCase(processSaleAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(processSaleAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload?.message || "";
        state.saleDetails.status = "processed";
      })
      .addCase(processSaleAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(confirmTransactionsTableAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmTransactionsTableAction.fulfilled, (state, action) => {
        state.loading = false;
        state.saleDetails.transaction_confirmed =
          !state.saleDetails.transaction_confirmed;
        state.success = action.payload?.message || "";
      })
      .addCase(confirmTransactionsTableAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(confirmPassengersTableAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmPassengersTableAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload?.message || "";
        state.selectedAirfare.passengers_confirmed =
          !state.selectedAirfare.passengers_confirmed;
        // Дополнительные обновления состояния, если необходимо
      })
      .addCase(confirmPassengersTableAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(confirmPassengerAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmPassengerAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload?.message || "";
        // Дополнительные обновления состояния при необходимости
      })
      .addCase(confirmPassengerAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(confirmTicketNumberTableAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmTicketNumberTableAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload?.message || "";
        // Обновление состояния в зависимости от ответа
      })
      .addCase(confirmTicketNumberTableAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(updateRevenueTicketAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateRevenueTicketAction.fulfilled,
        (state, action: PayloadAction<SuccessResponse | undefined>) => {
          state.loading = false;
          state.success = action.payload?.message || "";
          // Здесь вы можете обновить состояние в соответствии с полученными данными
        }
      )
      .addCase(updateRevenueTicketAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(setProductStatusAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        setProductStatusAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.success = action.payload?.message;
        }
      )
      .addCase(
        setProductStatusAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload?.message || null;
        }
      )
      .addCase(asignSaleAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        asignSaleAction.fulfilled,
        (state, action: PayloadAction<SuccessResponse | undefined>) => {
          state.loading = false;
          state.success = action.payload?.message || "";
        }
      )
      .addCase(asignSaleAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(fetchSalesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSalesList.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.sales = action.payload.data || [];
          state.pagination.links = action.payload.links || {};
          state.pagination.meta = action.payload.meta || {};
        }
      })
      .addCase(fetchSalesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error || null;
      })
      // Обработка асинхронных действий для загрузки деталей продажи
      .addCase(fetchSaleDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSaleDetails.fulfilled, (state, action) => {
        state.loading = false;
        const defaultOaf = action.payload?.oafs.find(
          (oaf) => oaf.type === "default" && oaf.airfares
        );

        // Если нашли такой oaf и у него есть airfares, берем первый элемент
        if (defaultOaf?.airfares && defaultOaf.airfares.length > 0) {
          state.selectedAirfare = defaultOaf.airfares[0];
        }
        state.saleDetails = action.payload || { ...initialState.saleDetails };
      })
      .addCase(fetchSaleDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(createSaleAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSaleAction.fulfilled, (state, action) => {
        state.loading = false;
        // Добавьте логику для обновления состояния после успешного создания продажи
      })
      .addCase(createSaleAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(createCommentAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createCommentAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.success = action.payload?.message || "";
          if (state.saleDetails && state.saleDetails.comments) {
            state.saleDetails.comments.push(action.payload.data);
          }
        }
      )
      .addCase(createCommentAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(createTransactionAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTransactionAction.fulfilled, (state, action) => {
        state.loading = false;
        // @ts-ignore
        state.saleDetails.transactions.push(action.payload);
      })
      .addCase(createTransactionAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(setTicketNumberAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(setTicketNumberAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setTicketNumberAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(rejectSaleAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(rejectSaleAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(rejectSaleAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(pauseSaleAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(pauseSaleAction.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the successful pause action
        // For instance, update the status of the sale in the state
      })
      .addCase(pauseSaleAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
      .addCase(confirmFinancialInfoAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmFinancialInfoAction.fulfilled, (state, action) => {
        state.loading = false;
        const newOaf = state.saleDetails.oafs.find(
          (el) => el.id === action.meta.arg.id
        );
        if (newOaf) {
          newOaf.financialInfoConfirmed = !newOaf?.financialInfoConfirmed;
          state.saleDetails.oafs = [...state.saleDetails.oafs, newOaf];
        }
        state.success = action.payload?.message || "";
      })
      .addCase(confirmFinancialInfoAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      }) // Обработчики для confirmTravelProtectionAction
      .addCase(confirmTravelProtectionAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmTravelProtectionAction.fulfilled, (state, action) => {
        state.loading = false;
        // Добавьте логику для обновления состояния при успешном выполнении
        state.success =
          action.payload?.message || "Travel protection confirmed successfully";
      })
      .addCase(confirmTravelProtectionAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to confirm travel protection";
      })

      // Обработчики для confirmTransactionAction
      .addCase(confirmTransactionAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmTransactionAction.fulfilled, (state, action) => {
        state.loading = false;
        state.saleDetails.transactions = state.saleDetails.transactions!.map(
          (transaction) => {
            // Проверяем, совпадает ли id транзакции с id из action.meta.arg
            if (transaction.id === action.meta.arg.id) {
              // Возвращаем обновленный объект транзакции
              // Здесь вы можете изменить любые свойства транзакции, которые необходимо обновить
              // Например, вы можете добавить флаг подтверждения или изменить статус
              return {
                ...transaction,
                // Добавьте или измените свойства объекта транзакции по вашему усмотрению
                confirmed: !transaction.confirmed, // Пример обновления свойства
              };
            }
            // Если id не совпадает, возвращаем транзакцию без изменений
            return transaction;
          }
        );
        state.success =
          action.payload?.message || "Transaction confirmed successfully";
      })
      .addCase(confirmTransactionAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to confirm transaction";
      })

      // Обработчики для confirmTipsAction
      .addCase(confirmTipsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmTipsAction.fulfilled, (state, action) => {
        state.loading = false;
        // Добавьте логику для обновления состояния при успешном выполнении
        state.success =
          action.payload?.message || "Tips confirmed successfully";
      })
      .addCase(confirmTipsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to confirm tips";
      });
  },
});
export const { clearError, clearSuccess } = salesSlice.actions;
// Экспортируйте действия и селекторы
export default salesSlice.reducer;
