import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import InfoBadge from "../../../InfoBadge";
import OAFCard from "../../../OAFCard";
import TravelProtectionTable from "../../tables/TravelProtectionTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";

import React, { useEffect, useState } from "react";
import useAuth from "../../../../../hooks/useAuth";
import StatusDialog from "../../../../../components/modal/StatusDialog/StatusDialog";
import {
  approveTravelProtectionAction,
  confirmTravelProtectionAction,
} from "../../../../../redux/slices/actions/salesActions";

const TravelProtectionSection = () => {
  const { saleDetails } = useSelector((state: RootState) => state.sales);
  const { oafs } = saleDetails;
  const selectedOaf = oafs.find((oaf) => {
    if (oaf.travelProtections && oaf.travelProtections.length > 0) {
      const hasTp = oaf.type === "tp";
      const hasDefault = oaf.type === "default";

      if (hasTp || hasDefault) {
        return true;
      }
    }
    return false;
  });
  const [status, setStatus] = useState<any>("declined");
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const { role } = useAuth();
  useEffect(() => {
    if (
      selectedOaf &&
      selectedOaf.travelProtections &&
      selectedOaf.travelProtections.length > 0
    ) {
      setStatus(selectedOaf.travelProtections![0].status || "declined");
      setConfirmed(selectedOaf.travelProtections![0].confirmed || false);
      setApproved(selectedOaf.travelProtections![0].approved || false);
    } else {
      setStatus("declined");
      setConfirmed(false);
      setApproved(false);
    }
  }, [selectedOaf]);

  const { agentDetails } = useSelector((state: RootState) => state.agent);
  return selectedOaf ? (
    <Grid container spacing={1} mb={12}>
      <Grid item md={12} padding={3}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} maxHeight={100}>
            <InfoBadge
              name={"Status"}
              value={status.charAt(0).toUpperCase() + status.slice(1)}
            />
            {agentDetails && (
              <InfoBadge
                name={"Created By"}
                value={agentDetails?.last_name + " " + agentDetails?.name}
                link={`https://pass.vipfares.com/admin/users/edit/${selectedOaf.createdBy!}`}
              />
            )}
            <InfoBadge name={"Commission"} value={selectedOaf.commission} />
            <InfoBadge
              name={"Processed By"}
              value={selectedOaf.processedBy ?? "-"}
            />
            <InfoBadge
              name={"Approved By"}
              value={selectedOaf.approvedBy ?? "-"}
            />
            <InfoBadge name={"OAF ID"} value={selectedOaf.id ?? "-"} />
            {saleDetails.status === "inProgress" && (
              <StatusDialog
                initialValue={selectedOaf!.travelProtections![0].status}
                status={status}
                change={(e: any) => setStatus(e)}
                productId={selectedOaf!.travelProtections![0].id}
                type={"travelProtection"}
              />
            )}
            {saleDetails.status === "inProgress" &&
              (role === "manager" || role === "ticketing") && (
                <Button
                  variant={"contained"}
                  sx={{ marginLeft: 1, height: 50, alignSelf: "center" }}
                  disabled={selectedOaf.travelProtections![0].approved}
                  onClick={() => {
                    dispatch(
                      confirmTravelProtectionAction({
                        id: selectedOaf.travelProtections![0].id,
                      })
                    );
                    //TO DO MAKE AWAIT / .then()
                    setConfirmed(!confirmed);
                  }}
                >
                  {confirmed ? "Unconfirm " : "Confirm "}
                  Travel Protection
                </Button>
              )}
            {saleDetails.status === "inApproving" &&
              (role === "manager" || role === "bookkeeping") && (
                <Button
                  variant={"contained"}
                  sx={{ marginLeft: 1, height: 50, alignSelf: "center" }}
                  onClick={() => {
                    dispatch(
                      approveTravelProtectionAction({
                        id: selectedOaf.travelProtections![0].id,
                      })
                    );
                    //TO DO MAKE AWAIT / .then()
                    setApproved(!approved);
                  }}
                >
                  {!approved ? "Approve " : "Reject "}
                  Travel Protection
                </Button>
              )}
          </Box>
          <OAFCard />
        </Box>
        {selectedOaf.travelProtections ? (
          <TravelProtectionTable />
        ) : (
          <p>NO TP</p>
        )}
      </Grid>
    </Grid>
  ) : (
    <>NO TP</>
  );
};

export default TravelProtectionSection;
