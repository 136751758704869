import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { CssBaseline } from "@mui/material";

import Settings from "../components/Settings";
import GlobalStyle from "../components/GlobalStyle";

interface AuthType {
  children?: React.ReactNode;
}

const Auth: React.FC<AuthType> = ({ children }) => {
  return (
    <div>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
      <Settings />
    </div>
  );
};

export default Auth;
