/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AsignSaleRequest } from '../models/AsignSaleRequest';
import type { Comment } from '../models/Comment';
import type { CommentRequest } from '../models/CommentRequest';
import type { ConfirmOAFRequest } from '../models/ConfirmOAFRequest';
import type { ConfirmPassengerRequest } from '../models/ConfirmPassengerRequest';
import type { ConfirmRevenueTicketRequest } from '../models/ConfirmRevenueTicketRequest';
import type { ConfirmTicketNumberTableRequest } from '../models/ConfirmTicketNumberTableRequest';
import type { ConfirmTipsRequest } from '../models/ConfirmTipsRequest';
import type { ConfirmTransactionRequest } from '../models/ConfirmTransactionRequest';
import type { ConfirmTransactionsTableRequest } from '../models/ConfirmTransactionsTableRequest';
import type { ConfirmTravelProtectionRequest } from '../models/ConfirmTravelProtectionRequest';
import type { PaymentCard } from '../models/PaymentCard';
import type { ProductStatusRequest } from '../models/ProductStatusRequest';
import type { Sale } from '../models/Sale';
import type { SaleCreateResponse } from '../models/SaleCreateResponse';
import type { SaleList } from '../models/SaleList';
import type { SaleRequest } from '../models/SaleRequest';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { TicketNumberRequest } from '../models/TicketNumberRequest';
import type { Transaction } from '../models/Transaction';
import type { TransactionRequest } from '../models/TransactionRequest';
import type { UpdateRevenueTicketRequest } from '../models/UpdateRevenueTicketRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SalesService {

    /**
     * Get a paginated list of Sales
     * @param perPage Number of records per page
     * @param status Filter sales by their status
     * @param role Filter sales by their status
     * @param page Page number
     * @returns any List of Sales with pagination
     * @throws ApiError
     */
    public static getSalesList(
        perPage: number = 10,
        status: 'open' | 'pending' | 'ticketing' | 'inProgress' | 'processed' | 'approved' | 'rejected' | 'inApproving' = 'open',
        role: 'agent' | 'ticketing' | 'bookkeeping' | 'manager' = 'agent',
        page: number = 1,
    ): CancelablePromise<{
        data?: Array<SaleList>;
        links?: {
            first?: string;
            last?: string;
            prev?: string;
            next?: string;
        };
        meta?: {
            current_page?: number;
            from?: number;
            last_page?: number;
            path?: string;
            per_page?: number;
            to?: number;
            total?: number;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales',
            query: {
                'perPage': perPage,
                'status': status,
                'role': role,
                'page': page,
            },
        });
    }

    /**
     * Get a specific Sale by ID
     * @param id ID of the Sale
     * @returns any Sale details
     * @throws ApiError
     */
    public static getSale(
        id: number,
    ): CancelablePromise<{
        data?: Sale;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sales/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `sale not found`,
            },
        });
    }

    /**
     * Reject a specific Sale by ID
     * @param id ID of the Sale
     * @returns any Sale details
     * @throws ApiError
     */
    public static rejectSale(
        id: number,
    ): CancelablePromise<{
        data?: Sale;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/sales/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `No rights to reject or invalid status sale`,
                404: `Sale not found`,
            },
        });
    }

    /**
     * Get a paginated list of PaymentCards
     * @param id sale Id
     * @returns any List of PaymentCards with pagination
     * @throws ApiError
     */
    public static showCc(
        id: number = 10,
    ): CancelablePromise<{
        data?: Array<PaymentCard>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/showCC/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * pause a specific Sale by ID
     * @param id ID of the Sale
     * @returns any Sale details
     * @throws ApiError
     */
    public static pauseSale(
        id: number,
    ): CancelablePromise<{
        data?: Sale;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/pauseSale/{id}',
            path: {
                'id': id,
            },
            errors: {
                403: `No rights to reject or invalid status sale`,
                404: `Sale not found`,
            },
        });
    }

    /**
     * Create a new sale
     * Create a new sale with the provided data
     * @param requestBody
     * @returns any Response with sale Create data
     * @throws ApiError
     */
    public static createSale(
        requestBody: SaleRequest,
    ): CancelablePromise<{
        data?: SaleCreateResponse;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/createSale',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * Create a new comment for sale
     * Create a new comment for sale with the provided data
     * @param requestBody
     * @returns any Comment for sale created successfully
     * @throws ApiError
     */
    public static createComment(
        requestBody: CommentRequest,
    ): CancelablePromise<{
        data?: Comment;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/createComment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * Set a new ticketNumber for passenger
     * Set a new ticketNumber for passenger with the provided data
     * @param id ID of the Passenger
     * @param requestBody
     * @returns any Transaction for sale created successfully
     * @throws ApiError
     */
    public static setTicketNumber(
        id: number,
        requestBody: TicketNumberRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/setTicketNumber/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * updateRevenueTicket for passenger
     * updateRevenueTicket for passenger with the provided data
     * @param requestBody
     * @returns any update  Revenue Ticket set successfully
     * @throws ApiError
     */
    public static updateRevenueTicket(
        requestBody: UpdateRevenueTicketRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/updateRevenueTicket',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * confirmRevenueTicket for passenger
     * confirmRevenueTicket for passenger with the provided data
     * @param requestBody
     * @returns any Confirm  Revenue Ticket set successfully
     * @throws ApiError
     */
    public static confirmRevenueTicket(
        requestBody: ConfirmRevenueTicketRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/confirmRevenueTicket',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * Set a new setProductStatus for Product
     * Set a new setProductStatus for Product with the provided data
     * @param requestBody
     * @returns any Transaction for sale created successfully
     * @throws ApiError
     */
    public static setProductStatus(
        requestBody: ProductStatusRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/setProductStatus',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * asign sale to ticketing/bookkeping master
     * asign sale to ticketing/bookkeping master with the provided data
     * @param id ID of the Sale
     * @param requestBody
     * @returns any Transaction for sale created successfully
     * @throws ApiError
     */
    public static asignSale(
        id: number,
        requestBody: AsignSaleRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/asignSale/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * Create a new Transaction for sale
     * Create a new Transaction for sale with the provided data
     * @param requestBody
     * @returns any Transaction for sale created successfully
     * @throws ApiError
     */
    public static createTransaction(
        requestBody: TransactionRequest,
    ): CancelablePromise<{
        data?: Transaction;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/createTransaction',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * confirmTicketNumberTable
     * confirmTicketNumberTabler with the provided data
     * @param requestBody
     * @returns any Transaction for sale created successfully
     * @throws ApiError
     */
    public static confirmTicketNumberTable(
        requestBody: ConfirmTicketNumberTableRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/confirmTicketNumberTable',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * confirmPassenger
     * confirmPassenger with the provided data
     * @param requestBody
     * @returns any Transaction for sale created successfully
     * @throws ApiError
     */
    public static confirmPassenger(
        requestBody: ConfirmPassengerRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/confirmPassenger',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * confirmPassengersTable
     * confirmPassengersTable with the provided data
     * @param requestBody
     * @returns any confirmPassengersTable for sale created successfully
     * @throws ApiError
     */
    public static confirmPassengersTable(
        requestBody: ConfirmTicketNumberTableRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/confirmPassengersTable',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * confirmFinancialInfo
     * confirmFinancialInfo with the provided data
     * @param requestBody
     * @returns any confirmPassengersTable for sale created successfully
     * @throws ApiError
     */
    public static confirmFinancialInfo(
        requestBody: ConfirmOAFRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/confirmFinancialInfo',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * confirmTips
     * confirmTips with the provided data
     * @param requestBody
     * @returns any confirmPassengersTable for sale created successfully
     * @throws ApiError
     */
    public static confirmTips(
        requestBody: ConfirmTipsRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/confirmTips',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * ConfirmTravelProtection
     * confirmTravelProtection with the provided data
     * @param requestBody
     * @returns any confirmTravelProtection for sale created successfully
     * @throws ApiError
     */
    public static confirmTravelProtection(
        requestBody: ConfirmTravelProtectionRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/confirmTravelProtection',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * confirmTransaction
     * confirmTransaction with the provided data
     * @param requestBody
     * @returns any confirmTransaction for sale created successfully
     * @throws ApiError
     */
    public static confirmTransaction(
        requestBody: ConfirmTransactionRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/confirmTransaction',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * confirmTransactionsTable
     * confirmTransactionsTable with the provided data
     * @param requestBody
     * @returns any confirmTransaction for sale created successfully
     * @throws ApiError
     */
    public static confirmTransactionsTable(
        requestBody: ConfirmTransactionsTableRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/confirmTransactionsTable',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * processSale
     * processSale with the provided data
     * @param requestBody
     * @returns any processSale for sale created successfully
     * @throws ApiError
     */
    public static processSale(
        requestBody: ConfirmTransactionRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/processSale',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

}
