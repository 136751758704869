import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ApiError,
  ApprovesService,
  CommentRequest,
  ConfirmOAFRequest,
  ConfirmPassengerRequest,
  ConfirmRevenueTicketRequest,
  ConfirmTicketNumberTableRequest,
  ConfirmTipsRequest,
  ConfirmTransactionRequest,
  ConfirmTransactionsTableRequest,
  ConfirmTravelProtectionRequest,
  SaleRequest,
  SalesService,
  TransactionRequest,
  type UpdateRevenueTicketRequest,
  VariablesService,
} from "../../../services/openapi";
import {
  AsignSaleArgs,
  FetchSalesListArgs,
  SetProductStatusArgs,
  SetTicketNumberArgs,
} from "../types/salesTypes";

export const confirmPassengersTableAction = createAsyncThunk(
  "sales/confirmPassengersTable",
  async (requestBody: ConfirmTicketNumberTableRequest, thunkAPI) => {
    try {
      const response = await SalesService.confirmPassengersTable(requestBody);
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Проверяем, содержит ли body объект ошибок с ключом "pnr"
        if (error.body?.errors && typeof error.body.errors === "object") {
          // Создаем объект ошибок с ключами и значениями;
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          return thunkAPI.rejectWithValue(error.body.message);
        }
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const setProductStatusAction = createAsyncThunk(
  "sales/setProductStatus",
  async (args: SetProductStatusArgs, thunkAPI) => {
    try {
      const response = await SalesService.setProductStatus(args.requestBody);
      return response.data; // Adjust according to your actual response structure
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Проверяем, содержит ли body объект ошибок с ключом "pnr"
        if (error.body?.errors && typeof error.body.errors === "object") {
          // Создаем объект ошибок с ключами и значениями
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          // Если объект ошибок отсутствует или не соответствует ожидаемому формату,
          // возвращаем общее сообщение об ошибке
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        // Обработка случая, когда ошибка не является экземпляром ApiError
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const createSaleAction = createAsyncThunk(
  "sales/createSale",
  async (saleRequest: SaleRequest, thunkAPI) => {
    try {
      return await SalesService.createSale(saleRequest);
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Проверяем, содержит ли body объект ошибок с ключом "pnr"
        if (error.body?.errors && typeof error.body.errors === "object") {
          // Создаем объект ошибок с ключами и значениями
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          // Если объект ошибок отсутствует или не соответствует ожидаемому формату,
          // возвращаем общее сообщение об ошибке
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        // Обработка случая, когда ошибка не является экземпляром ApiError
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const createCommentAction = createAsyncThunk(
  "sales/createComment",
  async (commentRequest: CommentRequest, thunkAPI) => {
    try {
      return await SalesService.createComment(commentRequest);
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Проверяем, содержит ли body объект ошибок с ключом "pnr"
        if (error.body?.errors && typeof error.body.errors === "object") {
          // Создаем объект ошибок с ключами и значениями
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          // Если объект ошибок отсутствует или не соответствует ожидаемому формату,
          // возвращаем общее сообщение об ошибке
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        // Обработка случая, когда ошибка не является экземпляром ApiError
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const rejectSaleAction = createAsyncThunk(
  "sales/rejectSale",
  async (id: number, thunkAPI) => {
    try {
      const response = await SalesService.rejectSale(id);
      return response.data; // Adjust according to your actual response structure
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Проверяем, содержит ли body объект ошибок с ключом "pnr"
        if (error.body?.errors && typeof error.body.errors === "object") {
          // Создаем объект ошибок с ключами и значениями
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          // Если объект ошибок отсутствует или не соответствует ожидаемому формату,
          // возвращаем общее сообщение об ошибке
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        // Обработка случая, когда ошибка не является экземпляром ApiError
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const asignSaleAction = createAsyncThunk(
  "sales/asignSale",
  async (args: AsignSaleArgs, thunkAPI) => {
    const { id, asignSaleRequest } = args;
    try {
      const response = await SalesService.asignSale(id, asignSaleRequest);
      return response.data; // Adjust according to your actual response structure
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Проверяем, содержит ли body объект ошибок с ключом "pnr"
        if (error.body?.errors && typeof error.body.errors === "object") {
          // Создаем объект ошибок с ключами и значениями
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          // Если объект ошибок отсутствует или не соответствует ожидаемому формату,
          // возвращаем общее сообщение об ошибке
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        // Обработка случая, когда ошибка не является экземпляром ApiError
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const setTicketNumberAction = createAsyncThunk(
  "sales/setTicketNumber",
  async (args: SetTicketNumberArgs, thunkAPI) => {
    const { id, ticketNumberRequest } = args;
    try {
      const response = await SalesService.setTicketNumber(
        id,
        ticketNumberRequest
      );
      return response.data; // Adjust according to your actual response structure
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Проверяем, содержит ли body объект ошибок с ключом "pnr"
        if (error.body?.errors && typeof error.body.errors === "object") {
          // Создаем объект ошибок с ключами и значениями
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          // Если объект ошибок отсутствует или не соответствует ожидаемому формату,
          // возвращаем общее сообщение об ошибке
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        // Обработка случая, когда ошибка не является экземпляром ApiError
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const fetchSalesList = createAsyncThunk(
  "sales/fetchSalesList",
  async ({ perPage, page, status, role }: FetchSalesListArgs) => {
    return SalesService.getSalesList(perPage, status, role, page);
  }
);
export const fetchSaleDetails = createAsyncThunk(
  "sales/fetchSaleDetails",
  async (id: number, thunkAPI) => {
    try {
      const response = await SalesService.getSale(id);
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Проверяем, содержит ли body объект ошибок с ключом "pnr"
        if (error.body?.errors && typeof error.body.errors === "object") {
          // Создаем объект ошибок с ключами и значениями
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          // Если объект ошибок отсутствует или не соответствует ожидаемому формату,
          // возвращаем общее сообщение об ошибке
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        // Обработка случая, когда ошибка не является экземпляром ApiError
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const pauseSaleAction = createAsyncThunk(
  "sales/pauseSale",
  async (id: number, thunkAPI) => {
    try {
      const response = await SalesService.pauseSale(id);
      return response.data; // Adjust according to your actual response structure
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Проверяем, содержит ли body объект ошибок с ключом "pnr"
        if (error.body?.errors && typeof error.body.errors === "object") {
          // Создаем объект ошибок с ключами и значениями
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          // Если объект ошибок отсутствует или не соответствует ожидаемому формату,
          // возвращаем общее сообщение об ошибке
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        // Обработка случая, когда ошибка не является экземпляром ApiError
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const createTransactionAction = createAsyncThunk(
  "sales/createTransaction",
  async (transactionRequest: TransactionRequest, thunkAPI) => {
    try {
      const response = await SalesService.createTransaction(transactionRequest);
      // @ts-ignore
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Проверяем, содержит ли body объект ошибок с ключом "pnr"
        if (error.body?.errors && typeof error.body.errors === "object") {
          // Создаем объект ошибок с ключами и значениями
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          // Если объект ошибок отсутствует или не соответствует ожидаемому формату,
          // возвращаем общее сообщение об ошибке
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        // Обработка случая, когда ошибка не является экземпляром ApiError
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const confirmTicketNumberTableAction = createAsyncThunk(
  "sales/confirmTicketNumberTable",
  async (requestBody: ConfirmTicketNumberTableRequest, thunkAPI) => {
    try {
      const response = await SalesService.confirmTicketNumberTable(requestBody);
      return response.data; // Верните данные от API
    } catch (error: any) {
      if (error instanceof ApiError) {
        const errorObject =
          error.body?.errors || "An unexpected error occurred";
        return thunkAPI.rejectWithValue(errorObject);
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);
export const updateRevenueTicketAction = createAsyncThunk(
  "sales/updateRevenueTicket",
  async (args: { requestBody: UpdateRevenueTicketRequest }, thunkAPI) => {
    try {
      const response = await SalesService.updateRevenueTicket(args.requestBody);
      return response.data; // Adjust according to your actual response structure
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Обработка ошибок API
        if (error.body?.errors && typeof error.body.errors === "object") {
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);
export const confirmRevenueTicketAction = createAsyncThunk(
  "sales/confirmRevenueTicket",
  async (args: { requestBody: ConfirmRevenueTicketRequest }, thunkAPI) => {
    try {
      const response = await SalesService.confirmRevenueTicket(
        args.requestBody
      );
      return response.data; // Adjust according to your actual response structure
    } catch (error: any) {
      if (error instanceof ApiError) {
        // Обработка ошибок API
        if (error.body?.errors && typeof error.body.errors === "object") {
          const errorObject = error.body.errors;
          return thunkAPI.rejectWithValue(errorObject);
        } else {
          return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const confirmPassengerAction = createAsyncThunk(
  "sales/confirmPassenger",
  async (requestBody: ConfirmPassengerRequest, thunkAPI) => {
    try {
      const response = await SalesService.confirmPassenger(requestBody);
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        const errorObject =
          error.body?.message || "An unexpected error occurred";
        return thunkAPI.rejectWithValue(errorObject);
      } else {
        return thunkAPI.rejectWithValue("Please check passengers names!!!");
      }
    }
  }
);

export const confirmFinancialInfoAction = createAsyncThunk(
  "sales/confirmFinancialInfo",
  async (requestBody: ConfirmOAFRequest, thunkAPI) => {
    try {
      const response = await SalesService.confirmFinancialInfo(requestBody);
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        const errorObject =
          error.body?.errors ||
          error.body.message ||
          "An unexpected error occurred";
        return thunkAPI.rejectWithValue(errorObject);
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

// Добавление действия confirmTravelProtectionAction
export const confirmTravelProtectionAction = createAsyncThunk(
  "sales/confirmTravelProtection",
  async (requestBody: ConfirmTravelProtectionRequest, thunkAPI) => {
    try {
      const response = await SalesService.confirmTravelProtection(requestBody);
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        const errorObject =
          error.body?.errors || "An unexpected error occurred";
        return thunkAPI.rejectWithValue(errorObject);
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

// Добавление действия confirmTransactionAction
export const confirmTransactionAction = createAsyncThunk(
  "sales/confirmTransaction",
  async (requestBody: ConfirmTransactionRequest, thunkAPI) => {
    try {
      const response = await SalesService.confirmTransaction(requestBody);
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        const errorObject =
          error.body?.errors || "An unexpected error occurred";
        return thunkAPI.rejectWithValue(errorObject);
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

// Добавление действия confirmTipsAction
export const confirmTipsAction = createAsyncThunk(
  "sales/confirmTips",
  async (requestBody: ConfirmTipsRequest, thunkAPI) => {
    try {
      const response = await SalesService.confirmTips(requestBody);
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        const errorObject =
          error.body?.errors || "An unexpected error occurred";
        return thunkAPI.rejectWithValue(errorObject);
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const confirmTransactionsTableAction = createAsyncThunk(
  "sales/confirmTransactionsTable",
  async (requestBody: ConfirmTransactionsTableRequest, thunkAPI) => {
    try {
      const response = await SalesService.confirmTransactionsTable(requestBody);
      return response.data; // Верните данные от API
    } catch (error: any) {
      if (error instanceof ApiError) {
        console.log("error", error);
        const errorObject =
          error.body?.errors ||
          error.body.message ||
          "An unexpected error occurred";
        return thunkAPI.rejectWithValue(errorObject);
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const processSaleAction = createAsyncThunk(
  "sales/processSale",
  async (requestBody: ConfirmTransactionRequest, thunkAPI) => {
    try {
      const response = await SalesService.processSale(requestBody);
      return response.data; // Верните данные от API
    } catch (error: any) {
      if (error instanceof ApiError) {
        const errorObject =
          error.body?.errors ||
          error.body.message ||
          "An unexpected error occurred";
        return thunkAPI.rejectWithValue(errorObject);
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const approveTicketNumberTableAction = createAsyncThunk(
  "sales/approveTicketNumberTable",
  async (requestBody: ConfirmTicketNumberTableRequest, thunkAPI) => {
    try {
      const response = await ApprovesService.approveTicketNumberTable(
        requestBody
      );
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        return thunkAPI.rejectWithValue(
          error.body?.errors || error.body.message || "Validation error"
        );
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const approvePassengersTableAction = createAsyncThunk(
  "sales/approvePassengersTable",
  async (requestBody: ConfirmTicketNumberTableRequest, thunkAPI) => {
    try {
      const response = await ApprovesService.approvePassengersTable(
        requestBody
      );
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        return thunkAPI.rejectWithValue(
          error.body?.errors || error.body.message || "Validation error"
        );
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const approveFinancialInfoTableAction = createAsyncThunk(
  "sales/approveFinancialInfoTable",
  async (requestBody: ConfirmOAFRequest, thunkAPI) => {
    try {
      const response = await ApprovesService.approveFinancialInfoTable(
        requestBody
      );
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        return thunkAPI.rejectWithValue(
          error.body?.errors || error.body.message || "Validation error"
        );
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const approveTipsAction = createAsyncThunk(
  "sales/approveTips",
  async (requestBody: ConfirmTipsRequest, thunkAPI) => {
    try {
      const response = await ApprovesService.approveTips(requestBody);
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        return thunkAPI.rejectWithValue(
          error.body?.errors || "Validation error"
        );
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const approveTravelProtectionAction = createAsyncThunk(
  "sales/approveTravelProtection",
  async (requestBody: ConfirmTravelProtectionRequest, thunkAPI) => {
    try {
      const response = await ApprovesService.approveTravelProtection(
        requestBody
      );
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        return thunkAPI.rejectWithValue(
          error.body?.errors || "Validation error"
        );
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const approveTransactionsTableAction = createAsyncThunk(
  "sales/approveTransactionsTable",
  async (requestBody: ConfirmTransactionsTableRequest, thunkAPI) => {
    try {
      const response = await ApprovesService.approveTransactionsTable(
        requestBody
      );
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        return thunkAPI.rejectWithValue(
          error.body?.errors || "Validation error"
        );
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const closeSaleAction = createAsyncThunk(
  "sales/closeSale",
  async (requestBody: ConfirmTransactionRequest, thunkAPI) => {
    try {
      const response = await ApprovesService.closeSale(requestBody);
      return response.data;
    } catch (error: any) {
      if (error instanceof ApiError) {
        return thunkAPI.rejectWithValue(
          error.body?.errors || error.body.message || "Validation error"
        );
      } else {
        return thunkAPI.rejectWithValue("An unexpected error occurred");
      }
    }
  }
);

export const showCcAction = createAsyncThunk(
  "sales/showCc",
  // Обратите внимание на добавление `, { rejectWithValue }` в параметрах функции.
  // Это позволяет извлекать `rejectWithValue` из `thunkAPI`.
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await SalesService.showCc(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || "An unexpected error occurred"
      );
    }
  }
);

export const getVariables = createAsyncThunk(
  "sales/getVariables",
  // Обратите внимание на добавление `, { rejectWithValue }` в параметрах функции.
  // Это позволяет извлекать `rejectWithValue` из `thunkAPI`.
  // eslint-disable-next-line no-empty-pattern
  async (arg, { rejectWithValue }) => {
    try {
      const response = await VariablesService.getVariables();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || "An unexpected error occurred"
      );
    }
  }
);
