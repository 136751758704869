import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import TransactionTable from "../../tables/TransactionTable";
import { useState } from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import _without from "lodash/without";
import { Field, Form, Formik } from "formik";
import { TransactionRequest } from "../../../../../services/openapi";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  approveTransactionsTableAction,
  confirmTransactionsTableAction,
  createTransactionAction,
} from "../../../../../redux/slices/actions/salesActions";
import useAuth from "../../../../../hooks/useAuth";

export interface TransactionsTableData {
  date: string;
  id: number;
  type: string;
  oaf: number | string | null;
  method: string;
  amount: string;
  transactionId: number | string | null;
  approvalCode: string;
  status: string;
  products: Array<string | number> | null;
}

const TransactionSection = () => {
  function createData(
    date: string,
    id: number,
    type: string,
    oaf: number | string | null,
    method: string,
    amount: string,
    transactionId: number | string | null,
    approvalCode: string,
    status: string,
    products: Array<string | number> | null
  ): TransactionsTableData {
    return {
      date,
      id,
      type,
      oaf,
      method,
      amount,
      transactionId,
      approvalCode,
      status,
      products,
    };
  }
  const rows: Array<TransactionsTableData> = [
    createData(
      "25/03/23 15:46",
      12345,
      "Manual",
      1234567,
      "CC VI *1234",
      "1245",
      12341231,
      "DMA14349Q",
      "Charged",
      ["Air", "Tips", "TP"]
    ),
    createData(
      "25/03/23 15:46",
      12345,
      "Manual",
      1234567,
      "CC VI *1234",
      "1245",
      12341231,
      "DMA14349Q",
      "Charged",
      ["Air", "Tips", "TP"]
    ),
    createData(
      "25/03/23 15:46",
      12345,
      "Manual",
      1234567,
      "CC VI *1234",
      "1245",
      12341231,
      "DMA14349Q",
      "Charged",
      ["Air", "Tips", "TP"]
    ),
  ];

  const [isOpenDialog, setOpenDialog] = useState<boolean>(false);
  const [isOpenSnack, setOpenSnack] = useState<boolean>(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const [personName, setPersonName] = useState<string[]>([]);
  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const names = ["Air", "TIPS", "TP"];
  const [type, setType] = useState<string>("");
  const [oaf, setOaf] = useState<string | null>("");
  const [method, setMethod] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [transactionId, setTransactionId] = useState<string | null>("");
  const [approvalCode, setApprovalCode] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [data, setNewData] = useState<TransactionsTableData[]>(rows);

  const handleConfirmDialog = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Note: Months are zero-based, so we add 1
    const day = currentDate.getDate();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const currentDateString = `${day}/${month}/${year} ${hours}:${minutes}`;
    const id = Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
    const data1 = createData(
      currentDateString,
      id,
      type,
      oaf,
      method,
      amount,
      transactionId,
      approvalCode,
      status,
      personName
    );
    const newData = [...data, data1];
    setNewData(newData);
    setType("");
    setOaf("");
    setMethod("");
    setAmount("");
    setTransactionId("");
    setApprovalCode("");
    setStatus("");
    setPersonName([]);
    setOpenDialog(false);
    setOpenSnack(true);
  };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setPersonName((current) => _without(current, value));
  };

  const validationSchema = yup.object({
    type: yup.string().required("Type is required"),
    amount: yup
      .number()
      .required("Amount is required")
      .positive("Amount must be positive"),
    transactionId: yup.string().required("Transaction ID is required"),
    approvalCode: yup.string().required("Approval Code is required"),
    status: yup.string().required("Status is required"),
    products: yup
      .array()
      .of(yup.string())
      .required("At least one product is required"),
  });

  const dispatch = useDispatch<AppDispatch>();
  const { saleDetails } = useSelector((state: RootState) => state.sales);
  const { oafs, paymentCards, transaction_confirmed, transaction_approved } =
    saleDetails;
  const { id } = useParams();
  const { role } = useAuth();
  return (
    <Grid container spacing={1} padding={3}>
      <Grid item md={12}>
        {role !== "agent" && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Button
              sx={{ maxHeight: 60 }}
              onClick={handleClickOpenDialog}
              variant="contained"
              color="inherit"
            >
              + ADD TRANSACTIONS
            </Button>
          </Box>
        )}

        <Grid container>
          <Grid item>
            <TransactionTable />
          </Grid>
          <Grid item marginTop={3}>
            {saleDetails.status === "inProgress" &&
              (role === "manager" || role === "ticketing") && (
                <Button
                  sx={{ marginLeft: 1, alignSelf: "center" }}
                  variant={"contained"}
                  onClick={() =>
                    dispatch(confirmTransactionsTableAction({ id: Number(id) }))
                  }
                >
                  {transaction_confirmed ? "Unconfirm" : "Confirm"} Transactions
                </Button>
              )}
            {saleDetails.status === "inApproving" && (
              <Button
                sx={{ marginLeft: 12 }}
                variant={"contained"}
                onClick={() =>
                  dispatch(approveTransactionsTableAction({ id: Number(id) }))
                }
              >
                {transaction_approved ? "Reject " : "Approve "} Transactions
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={isOpenDialog} onClose={handleCloseDialog}>
        <DialogTitle>NEW Transaction</DialogTitle>
        <DialogContent>
          <DialogContentText>Set new Transaction!</DialogContentText>
          <Formik<TransactionRequest>
            initialValues={{
              type: "manual",
              amount: 0,
              transactionId: "",
              approvalCode: "",
              status: "created",
              method: "",
              saleId: Number(id),
              products: ["AIR"],
              oafId: oafs.length > 0 ? oafs[0].id : 0,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(createTransactionAction({ ...values })).then(() =>
                handleCloseDialog()
              );
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => (
              <Form>
                <Grid
                  container
                  display={"flex"}
                  flexDirection={"column"}
                  spacing={3}
                  mt={4}
                  sx={{ minWidth: 400 }}
                >
                  <Grid item>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Field
                        component={Select}
                        id={"type"}
                        name="type"
                        label="Type"
                        error={touched.type && Boolean(errors.type)}
                        value={values.type}
                        onChange={(event: any) =>
                          setFieldValue("type", event.target.value)
                        }
                      >
                        <MenuItem value={"manual"}>MANUAL</MenuItem>
                        <MenuItem value={"gds"}>GDS</MenuItem>
                        <MenuItem value={"automatic"}>AUTO</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        OAF ID
                      </InputLabel>
                      <Field
                        component={Select}
                        name="oafId"
                        label="OAF ID"
                        onChange={(event: any) =>
                          setFieldValue("oafId", event.target.value)
                        }
                        error={touched.oafId && Boolean(errors.oafId)}
                        value={values.oafId}
                      >
                        {oafs &&
                          oafs.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.id}
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Method
                      </InputLabel>
                      <Field
                        component={Select}
                        name="method"
                        label="Method"
                        onChange={(event: any) =>
                          setFieldValue("method", event.target.value)
                        }
                        error={touched.method && Boolean(errors.method)}
                        value={values.method}
                      >
                        {paymentCards &&
                          paymentCards.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.hash}
                            </MenuItem>
                          ))}
                        <MenuItem value={""}></MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Field
                      component={TextField}
                      name="amount"
                      label="Amount"
                      fullWidth
                      variant="outlined"
                      onChange={(event: any) =>
                        setFieldValue("amount", event.target.value)
                      }
                      error={touched.amount && Boolean(errors.amount)}
                      helperText={touched.amount && errors.amount}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      component={TextField}
                      name="transactionId"
                      label="Transaction ID"
                      fullWidth
                      variant="outlined"
                      onChange={(event: any) =>
                        setFieldValue("transactionId", event.target.value)
                      }
                      error={
                        touched.transactionId && Boolean(errors.transactionId)
                      }
                      helperText={touched.transactionId && errors.transactionId}
                      value={values.transactionId}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      component={TextField}
                      name="approvalCode"
                      label="Approval Code"
                      fullWidth
                      variant="outlined"
                      onChange={(event: any) =>
                        setFieldValue("approvalCode", event.target.value)
                      }
                      error={
                        touched.approvalCode && Boolean(errors.approvalCode)
                      }
                      helperText={touched.approvalCode && errors.approvalCode}
                      value={values.approvalCode}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Field
                        component={Select}
                        name="status"
                        label="status"
                        onChange={(event: any) =>
                          setFieldValue("status", event.target.value)
                        }
                        error={touched.status && Boolean(errors.status)}
                        value={values.status}
                      >
                        <MenuItem value={"created"}>Created</MenuItem>
                        <MenuItem value={"rejected"}>Rejected</MenuItem>
                        <MenuItem value={"charged"}>Charged</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="demo-multiple-chip-label">
                        Products
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={values.products}
                        onChange={(event) =>
                          setFieldValue("products", event.target.value)
                        }
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                onDelete={() =>
                                  setFieldValue(
                                    "products",
                                    values!.products!.filter((v) => v !== value)
                                  )
                                }
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <DialogActions>
                  <Button onClick={handleCloseDialog}>Cancel</Button>
                  <Button type={"submit"}>Confirm</Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default TransactionSection;
