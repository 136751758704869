import React, { FC, useEffect, useState } from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Check as AcceptIcon,
  Pause as PauseIcon,
  PlayArrow as PlayIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import moment from "moment/moment";
import useAuth from "../../../hooks/useAuth";
import {
  asignSaleAction,
  fetchSalesList,
  pauseSaleAction,
  rejectSaleAction,
} from "../../../redux/slices/actions/salesActions";
import { FetchSalesListArgs } from "../../../redux/slices/types/salesTypes";

interface SalesTableInterface {
  type:
    | "open"
    | "pending"
    | "ticketing"
    | "inProgress"
    | "processed"
    | "approved"
    | "rejected"
    | "inApproving";
}
const SalesTable: FC<SalesTableInterface> = ({ type }) => {
  const dispatch: AppDispatch = useDispatch();
  const { sales, pagination, loading } = useSelector(
    (state: RootState) => state.sales
  );
  const { role } = useAuth();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState(50);
  console.log("role", role);
  useEffect(() => {
    dispatch(
      fetchSalesList({
        perPage: pageSize,
        page: page + 1,
        status: type,
        role: role,
      } as FetchSalesListArgs)
    );
  }, [dispatch, page, pageSize]);

  const onPaginationModelChange = (model: {
    page: number;
    pageSize: number;
  }) => {
    setPage(model.page);
    setPageSize(model.pageSize);
    dispatch(
      fetchSalesList({
        perPage: model.pageSize,
        page: model.page + 1,
        status: type,
        role: role,
      } as FetchSalesListArgs)
    );
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      renderCell: (params: any) => {
        return moment(params?.formattedValue).format("YYYY-MM-DD HH:mm");
      },
    },
    { field: "pnr", headerName: "PNR", width: 150 },
    {
      field: "totalCharge",
      headerName: "Total Charge",
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `$ ${(params as GridValueGetterParams).value}`;
      },
    },
    {
      field: "tipCount",
      headerName: "Tips",
      width: 90,
      valueGetter: (params: GridValueGetterParams) => {
        return `$ ${(params as GridValueGetterParams).value}`;
      },
    },
    {
      field: "travelProtectionUserCost",
      headerName: "Travel Protection",
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return `$ ${(params as GridValueGetterParams).value}`;
      },
    },
    {
      field: "passengerName",
      headerName: "Passenger Name",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 300,
      renderCell: (params: { row: { id?: number; isPaused?: boolean } }) => {
        if (typeof params.row.id === "number") {
          return (
            <>
              <Tooltip title={"View Sale"}>
                <IconButton
                  aria-label="details"
                  component={NavLink}
                  to={`/sales/detail/${params.row.id}`}
                  size="large"
                >
                  <RemoveRedEyeIcon />
                </IconButton>
              </Tooltip>
              {(type === "open" ||
                type === "ticketing" ||
                type === "pending") &&
                (role === "agent" || role === "manager") && (
                  <Tooltip
                    title={params.row.isPaused ? "Restart Sale" : "Pause Sale"}
                  >
                    <IconButton
                      onClick={async () => {
                        await dispatch(
                          pauseSaleAction(params.row.id as number)
                        );
                        await dispatch(
                          fetchSalesList({
                            role: role,
                            perPage: pageSize,
                            page: page + 1,
                            status: type,
                          } as FetchSalesListArgs)
                        );
                      }}
                    >
                      {params.row.isPaused ? <PlayIcon /> : <PauseIcon />}
                    </IconButton>
                  </Tooltip>
                )}
              {(type === "open" ||
                type === "ticketing" ||
                type === "pending") &&
                (role === "agent" ||
                  role === "manager" ||
                  role === "ticketing") && (
                  <Tooltip title={"Reject Sale"}>
                    <IconButton
                      onClick={async () => {
                        await dispatch(
                          rejectSaleAction(params.row.id as number)
                        );
                        await dispatch(
                          fetchSalesList({
                            role: role,
                            perPage: pageSize,
                            page: page + 1,
                            status: type,
                          })
                        );
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {(type === "open" ||
                type === "ticketing" ||
                type === "pending" ||
                type === "processed") &&
                (role === "ticketing" ||
                  role === "bookkeeping" ||
                  role === "manager") && (
                  <Tooltip title={"Accept Sale"}>
                    <IconButton
                      onClick={async () => {
                        const args = {
                          id: params.row.id as number, // Replace with actual ID
                          asignSaleRequest: {
                            inWorkBy: 123,
                          }, // Replace with actual ticket number request
                        };
                        await dispatch(asignSaleAction(args));
                        await dispatch(
                          fetchSalesList({
                            perPage: pageSize,
                            page: page + 1,
                            status: type,
                            role: role,
                          })
                        );
                      }}
                    >
                      <AcceptIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </>
          );
        }
        return null; // Или верните другой JSX, если id не существует
      },
    },
  ];

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <DataGrid
          rows={sales}
          columns={columns}
          paginationModel={{ page, pageSize }}
          pagination
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[25, 50, 100, 200, 1000]}
          loading={loading}
          disableRowSelectionOnClick
          paginationMode="server"
          rowCount={pagination.meta.total || 0}
        />
      </Grid>
    </Grid>
  );
};

export default SalesTable;
