import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import salesReducer from "./slices/salesSlice";
import pnrReducer from "./slices/pnrSlice";
import agentReducer from "./slices/agentSlice";

export const store = configureStore({
  reducer: {
    pnr: pnrReducer,
    counter: counterReducer,
    sales: salesReducer,
    agent: agentReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
