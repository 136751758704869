import React, { useEffect, useState } from "react";
import { Formik, FieldArray, Form } from "formik";
import { jsx } from "@emotion/react";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Card,
  Switch,
  FormControlLabel,
  Divider,
  Backdrop,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import { PaymentCardRequest, SaleRequest } from "../../services/openapi";
import { DatePicker } from "@mui/x-date-pickers";
import { formatISO, parseISO } from "date-fns";
import TicketForm from "../../components/form/oaf/TicketForm";
import CreditCardForm from "../../components/form/oaf/CreditCardForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { clearError } from "../../redux/slices/salesSlice";
import { createSaleAction } from "../../redux/slices/actions/salesActions";

type ExtendedPassenger = SaleRequest["passengers"][number] & { id: number };
type ExtendedTicket = SaleRequest["tickets"][number] & { id: number };

interface ErrorObject {
  [key: string]: string[];
}

interface ErrorActionPayload {
  field?: string; // Поле для удаления из ошибки
}

// Расширенный тип SaleRequest с новыми полями
export type ExtendedSaleRequest = Omit<
  SaleRequest,
  "passengers" | "tickets"
> & {
  passengers: ExtendedPassenger[];
  tickets?: ExtendedTicket[];
};
const CreateOAForm = () => {
  const transformToSaleRequest = (
    extendedSaleRequest: ExtendedSaleRequest
  ): SaleRequest => {
    const { passengers, tickets, ...rest } = extendedSaleRequest;

    // Удаляем 'id' из каждого элемента в 'passengers' и 'tickets'
    const normalizedPassengers = passengers.map(
      ({ id, ...passenger }) => passenger
    );
    const normalizedTickets = tickets?.map(({ id, ...ticket }) => ticket) || [];

    return {
      ...rest,
      passengers: normalizedPassengers,
      tickets: normalizedTickets,
    };
  };
  const bookingClasses = [
    {
      code: "F",
      name: "First Class",
      description:
        "The most luxurious and premium class of service with spacious seats, gourmet meals, and exclusive services.",
    },
    {
      code: "J",
      name: "Business Class",
      description:
        "A premium class of service offering comfortable seating, enhanced meals, and priority services for business travelers.",
    },
    {
      code: "W",
      name: "Premium Economy Class",
      description:
        "An intermediate class with more legroom and enhanced amenities compared to economy class.",
    },
    {
      code: "C",
      name: "Business Class",
      description:
        "Business Class (sometimes also labeled as J) is a premium class of service offering comfortable seating, enhanced meals, and priority services for business travelers.",
    },
    {
      code: "M",
      name: "Economy Class",
      description:
        "Economy Class (sometimes also labeled as Y) is the standard class of service offering affordable fares with basic amenities and seating.",
    },
  ];
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const initAwardTicket: ExtendedTicket = {
    id: 0,
    ptc: "ADT",
    pax_name: "Doe/John A.",
    selling_price: 0,
    net_price: 0,
    miles: 0,
    taxes: 0,
    price_per_mile: 0.003,
    mileage_program_name: "",
  };
  const initRevenueTicket: ExtendedTicket = {
    id: 0,
    consolidator_name: "asdasda",
    ptc: "ADT",
    pax_name: "Doe/John Asdf",
    selling_price: 1,
    net_price: 1,
    taxes: 1,
  };
  const initialPaymentCard: PaymentCardRequest = {
    cardholdName: "JOHN DOE",
    cardNumber: 1234567890123456, // Пример номера карты
    month: 12, // Пример месяца
    year: 2025, // Пример года
    cvv: 123, // Пример CVV
    bankPhoneNumber: "+1234567890",
    amount: 100.0, // Пример суммы
    cardType: "Visa", // Пример типа карты
    country: "1",
    city: "1",
    state_province: "1",
    street_address: "123 Main St",
    zip: "10001",
    email: "johndoe@example.com",
    billingPhone: "+9876543210",
  };
  const initialValues: ExtendedSaleRequest = {
    hash: "",
    clientId: 123123,
    createdBy: 123123,
    ipAddress: "192.168.1.1", // Example IP address
    // itinerary: [
    //   {
    //     flightNumber: "1234",
    //     departureAirport: "ABC",
    //     arrivalAirport: "XYZ",
    //     airlineIataCode: "AA",
    //     departureDate: "2023-01-01",
    //     arrivalDate: "2023-01-02",
    //     layoverTime: "10:10",
    //     totalTravelTime: "72:00:00",
    //     flightType: "Direct",
    //     baggageNumber: -1,
    //     cabinType: "Economy",
    //     recordLocator: "ABCDEF",
    //     weekDayDeparture: "Monday",
    //     weekDayArrival: "Tuesday",
    //     bookingClass: "F",
    //   },
    // ],
    oafType: "default",
    passengers: [
      {
        id: 0,
        IssuingCountry: "123",
        dateOfBirth: "1998-03-15",
        email: "john.doe@example.com",
        expiringPassport: "2025-12-31",
        firstName: "John",
        food: "VGML",
        gender: "male",
        ktn: {
          code: "KTN123",
          securityProgramId: 1,
        },
        lastName: "Doe",
        middleName: "Aasdasd",
        nationality: "American",
        passportNumber: "X1234567",
        phoneNumber: "+1234567890",
        redressCode: "12345",
        wheelChairAssistance: true,
        subscribe: true,
      },
    ], // Example user agent
    paymentCard: [{ ...initialPaymentCard }], // Example PNR (Passenger Name Record)
    paymentType: "card", // Default total charge
    pnr: "", // Example PQ ID
    pqId: 1231, // Example request ID
    requestId: 123, // Example client ID
    tickets: [initAwardTicket], // Default OAF type
    ticket_type: "AwardTicket",
    tips: 0, // Default travel protection amount
    totalCharge: 0,
    travelProtection: 0,
    userAgent: "Mozilla/5.0",
  };
  const revenueTicketRequestSchema = Yup.object().shape({
    pax_name: Yup.string().required("Passenger name is required"),
    ptc: Yup.mixed()
      .oneOf(["ADT", "CHD", "INF"], "Invalid passenger type code")
      .required("Passenger type code is required"),
    selling_price: Yup.number()
      .positive("Selling price must be positive")
      .required("Selling price is required"),
    net_price: Yup.number()
      .positive("Net price must be positive")
      .required("Net price is required"),
    markup: Yup.number()
      .positive("Markup must be positive")
      .required("Markup is required"),
    fare: Yup.number().positive("Fare must be positive").nullable(),
    fuel: Yup.number().positive("Fuel must be positive").nullable(),
    taxes: Yup.number().positive("Taxes must be positive").nullable(),
    commission_type: Yup.mixed()
      .oneOf(["F", "FF"], "Invalid commission type")
      .required("Commission type is required"),
    comm_percent: Yup.number()
      .positive("Commission percent must be positive")
      .nullable(),
    comm_dollar: Yup.number()
      .positive("Commission dollar must be positive")
      .nullable(),
    processing_fee: Yup.number()
      .positive("Processing fee must be positive")
      .nullable(),
    cc_fee: Yup.number()
      .positive("Credit card fee must be positive")
      .nullable(),
    consolidator_if: Yup.number()
      .positive("Consolidator ID must be positive")
      .nullable(),
    estimated_profit: Yup.number()
      .positive("Estimated profit must be positive")
      .nullable(),
    total_estimated_profit: Yup.number()
      .positive("Total estimated profit must be positive")
      .nullable(),
    consolidator_name: Yup.string()
      .matches(/^[A-Za-z]*$/, "Only Latin alphabetic characters are allowed")
      .max(20, "Maximum length is 20 characters")
      .required("Consolidator name is required"),
  });

  const awardTicketRequestSchema = Yup.object().shape({
    ptc: Yup.mixed()
      .oneOf(["ADT", "CHD", "INF"], "Invalid passenger type code")
      .required("Passenger type code is required"),
    // pax_name: Yup.string().required("Passenger name is required"),
    // selling_price: Yup.number()
    //   .typeError("Value must be a number")
    //   .test(
    //     "is-decimal",
    //     "Invalid format, use a number up to 6 digits with optional 2 decimal places",
    //     (value: number | undefined) =>
    //       /^\d{1,6}(\.\d{1,2})?$/.test(value?.toString() ?? "")
    //   )
    //   .test(
    //     "range",
    //     "Value must be between 1 and 300000.99",
    //     (value: number | undefined) =>
    //       value != null && value >= 1 && value <= 300000.99
    //   )
    //   .required("Selling price is required"),
    // net_price: Yup.number()
    //   .typeError("Value must be a number")
    //   .test(
    //     "is-decimal",
    //     "Invalid format, use a number up to 6 digits with optional 2 decimal places",
    //     (value: number | undefined) =>
    //       /^\d{1,6}(\.\d{1,2})?$/.test(value?.toString() ?? "")
    //   )
    //   .test(
    //     "range",
    //     "Value must be between 1 and 300000.99",
    //     (value: number | undefined) =>
    //       value != null && value >= 1 && value <= 300000.99
    //   )
    //   .required("Net price is required"),
    // markup: Yup.number()
    //   .typeError("Value must be a number")
    //   .test(
    //     "is-decimal",
    //     "Invalid format, use a number up to 6 digits with optional 2 decimal places",
    //     (value: number | undefined) =>
    //       /^\d{1,6}(\.\d{1,2})?$/.test(value?.toString() ?? "")
    //   )
    //   .test(
    //     "range",
    //     "Value must be between 1 and 300000.99",
    //     (value: number | undefined) =>
    //       value != null && value >= 1 && value <= 300000.99
    //   )
    //   .required("Markup is required"),
    // miles: Yup.number()
    //   .positive("Miles must be positive")
    //   .required("Miles are required"),
    // taxes: Yup.number().positive("Taxes must be positive").nullable(),
    // price_per_mile: Yup.number()
    //   .typeError("Value must be a number")
    //   .test(
    //     "is-decimal",
    //     "Invalid format, use a number up to 6 digits with optional 2 decimal places",
    //     (value: number | undefined) =>
    //       /^\d{1,6}(\.\d{1,2})?$/.test(value?.toString() ?? "")
    //   )
    //   .test(
    //     "range",
    //     "Value must be between 1 and 300000.99",
    //     (value: number | undefined) =>
    //       value != null && value >= 1 && value <= 300000.99
    //   )
    //   .required("Price per mile is required"),
    // cc_fee: Yup.number().positive("CС fee must be positive").nullable(),
    // estimated_profit: Yup.number()
    //   .positive("Estimated profit must be positive")
    //   .nullable(),
    // total_estimated_profit: Yup.number()
    //   .positive("Total estimated profit must be positive")
    //   .nullable(),
    mileage_program_name: Yup.string()
      .max(20, "Maximum length is 20 characters")
      .required("Mileage program name is required"),
  });
  const ticketsSchema = Yup.array().of(
    Yup.lazy((ticket) =>
      ticket.ticket_type === "RevenueTicket"
        ? revenueTicketRequestSchema
        : awardTicketRequestSchema
    )
  );
  const itinerarySchema = Yup.object().shape({
    flightNumber: Yup.string()
      .required("Flight number is required")
      .matches(/^[0-9]*$/, "Only digits are allowed")
      .max(4, "max length 4 symbols"),
    departureAirport: Yup.string()
      .required("Departure airport is required")
      .matches(
        /^[A-Za-z]{3}$/,
        "Only 3 Latin letters are allowed for the airport code"
      ),
    arrivalAirport: Yup.string()
      .required("Arrival airport is required")
      .matches(
        /^[A-Za-z]{3}$/,
        "Only 3 Latin letters are allowed for the airport code"
      ),
    airlineIataCode: Yup.string()
      .required("Airline IATA code is required")
      .matches(
        /^[A-Za-z0-9]{2}$/,
        "Airline IATA code must be exactly 2 Latin letters or numbers"
      ),
    departureDate: Yup.date().required("Departure date is required"),
    arrivalDate: Yup.date().required("Arrival date is required"),
    layoverTime: Yup.string()
      .required("Layover time is required")
      .matches(
        /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
        "Invalid time format, use HH:MM"
      ),
    totalTravelTime: Yup.string()
      .required("Total travel time is required")
      .matches(
        /^[0-9]{2}:[0-5][0-9]:[0-5][0-9]$/,
        "Time must be in DD:HH:MM format"
      ),
    flightType: Yup.string().required("Flight type is required"),
    baggageNumber: Yup.number().required("Baggage number is required"),
    cabinType: Yup.string().required("Cabin type is required"),
    recordLocator: Yup.string()
      .min(6, "Length must be exactly 6 characters")
      .max(6, "Length must be exactly 6 characters"),
    weekDayDeparture: Yup.string().required("Weekday departure is required"),
    weekDayArrival: Yup.string().required("Weekday arrival is required"),
    bookingClass: Yup.string().required("Booking class is required"),
  });
  const passengersSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        /^[A-Za-z\s\-]*$/,
        "Only Latin alphabetic characters, spaces, and hyphens are allowed"
      )
      .max(17, "Maximum length is 17 characters")
      .required("First name is required"),

    lastName: Yup.string()
      .required("Last name is required")
      .matches(
        /^[A-Za-z\s\-]*$/,
        "Only Latin alphabetic characters, spaces, and hyphens are allowed"
      )
      .max(17, "Maximum length is 17 characters"),
    middleName: Yup.string()
      .matches(
        /^[A-Za-z\s\-]*$/,
        "Only Latin alphabetic characters, spaces, and hyphens are allowed"
      )
      .max(17, "Maximum length is 17 characters")
      .nullable(),
    dateOfBirth: Yup.string().required("dateOfBirth is required"),
    email: Yup.string().email("Email is not valid").notRequired().nullable(),
    phoneNumber: Yup.string()
      .matches(
        /^\+?[0-9]+$/,
        "The string must consist of digits and can start with a +"
      )
      .nullable()
      .notRequired(),
    IssuingCountry: Yup.string()
      .matches(/^[0-9]*$/, "Only digits are allowed")
      .required("Issuing Country is required"),
    gender: Yup.string()
      .required("Gender is required")
      .oneOf(["male", "female"], 'Gender must be either "male" or "female"'),
    passportNumber: Yup.string()
      .matches(
        /^[A-Za-z0-9\s]*$/,
        "Only Latin letters, numbers, and spaces are allowed"
      )
      .max(17, "Maximum length is 17 characters")
      .required("Passport number is required"),
    expiringPassport: Yup.string().required("expiringPassport is required"),
    nationality: Yup.string().required("nationality is required"),

    food: Yup.string().required("food is required"),

    redressCode: Yup.string().max(15, "Maximum length is 15 characters"),
  });
  const ipV4Regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const ipV6Regex = /^([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4})$/;

  const combinedIpRegex = new RegExp(`${ipV4Regex.source}|${ipV6Regex.source}`);

  const paymentCardRequestSchema = Yup.object().shape({
    cardholdName: Yup.string()
      .required("Cardholder name is required")
      .matches(
        /^[A-Za-z]+(\s[A-Za-z]+)+$/,
        "Cardholder name must contain only Latin letters and include both first and last name"
      )
      .max(30, "Cardholder name must be at most 30 characters long"),
    cardNumber: Yup.string()
      .matches(/^[0-9]{16,18}$/, "Card number must be 16 to 18 digits")
      .required("Card number is required"),
    month: Yup.number()
      .min(1, "Month must be between 1 and 12")
      .max(12, "Month must be between 1 and 12")
      .required("Expiration month is required"),
    year: Yup.number()
      .required("Expiration year is required")
      .min(1000, "Year must be a 4-digit number")
      .max(9999, "Year must be a 4-digit number")
      .test(
        "is-current-or-future-year",
        "Year must be the current or a future year",
        (value) => {
          const currentYear = new Date().getFullYear();
          return value ? value >= currentYear : false;
        }
      ),
    cvv: Yup.string()
      .required("CVV is required")
      .matches(/^[0-9]{3,4}$/, "CVV must be 3 or 4 digits long"),
    bankPhoneNumber: Yup.string()
      .notRequired()
      .matches(
        /^\+\d*$/,
        "Phone number must start with '+' and contain only digits"
      )
      .max(17, "Phone number must be at most 17 characters long"),
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be positive"),
    cardType: Yup.string().required("Card type is required"),
    country: Yup.string()
      .required("Country is required")
      .matches(/^[0-9]+$/, "This field must contain only numbers")
      .max(20, "Country must be at most 20 characters long"),

    city: Yup.string()
      .required("City is required")
      .matches(/^[0-9]+$/, "This field must contain only numbers")
      .max(20, "City must be at most 20 characters long"),
    state_province: Yup.string()
      .matches(/^[0-9]+$/, "This field must contain only numbers")
      .notRequired()
      .matches(
        /^[A-Za-z0-9]*$/,
        "State must contain only Latin letters and numbers"
      )
      .max(20, "State must be at most 20 characters long"),
    street_address: Yup.string()
      .required("Street address is required")
      .matches(
        /^[A-Za-z0-9\s\/-]+$/,
        "Street address must contain only letters, numbers, spaces, hyphens, and slashes"
      ),
    zip: Yup.string()
      .max(12, "City must be at most 12 characters long")
      .matches(/^[A-Z0-9 -]+$/i, "ZIP code must be in a valid format")
      .required("ZIP code is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    billingPhone: Yup.string()
      .required("Billing phone is required")
      .matches(
        /^\+?[0-9]{1,17}$/,
        "Billing phone must start with '+' and contain only digits"
      )
      .max(17, "Billing phone must be at most 17 characters long"),
  });

  const validationSchema = Yup.object().shape({
    pnr: Yup.string()
      .matches(
        /^[A-Za-z0-9]{6}$/,
        "PNR must be exactly 6 characters long and contain only Latin letters and numbers"
      )
      .min(6, "Length must be exactly 6 characters")
      .max(6, "Length must be exactly 6 characters")
      .notRequired(),
    totalCharge: Yup.number().required("Total charge is required"),
    travelProtection: Yup.number().required("Travel protection is required"),
    pqId: Yup.string()
      .matches(/^\d+$/, "pqId must contain only digits")
      .required("pqId is required"),
    requestId: Yup.string()
      .required("requestId is required")
      .matches(/^\d+$/, "requestId must contain only digits"),
    clientId: Yup.string()
      .required("clientId is required")
      .matches(/^\d+$/, "clientId must contain only digits"),
    createdBy: Yup.string()
      .required("createdBy is required")
      .matches(/^[0-9]*$/, "Only digits are allowed"),
    oafType: Yup.string().required("oafType is required"),
    tips: Yup.number()
      .min(0, "Value cannot be negative")
      .required("tips is required"),
    ipAddress: Yup.string()
      .matches(combinedIpRegex, "Please enter a valid IPv4 or IPv6 address")
      .required("ipAddress is required"),
    userAgent: Yup.string().required("userAgent is required"),
    passengers: Yup.array().of(passengersSchema),
    itinerary: Yup.array().of(itinerarySchema),
    ticket_type: Yup.string()
      .oneOf(["RevenueTicket", "AwardTicket"], "Invalid ticket type")
      .required("Ticket type is required"),
    // tickets: Yup.array().when("ticket_type", {
    //   is: (val: any) => {
    //     console.log("val");
    //     return val === "AwardTicket";
    //   },
    //   then: (
    //     schema // <---- notice here return function
    //   ) => {
    //     const res = schema.of(awardTicketRequestSchema);
    //     console.log("res", res);
    //     return schema.of(res);
    //   },
    // }),
    feedback: Yup.object().shape({
      description: Yup.string(),
      overallExperience: Yup.number().max(10).min(0).required("required"),
      agentRate: Yup.number().max(10).min(0).required("required"),
    }),
    paymentType: Yup.string()
      .required("Payment type is required")
      .oneOf(["card", "wire"], "Payment type must be either 'card' or 'wire'"),
    paymentCard: Yup.array().when("paymentType", {
      is: (val: any) => {
        return val === "card";
      },
      then: (
        schema // <---- notice here return function
      ) => {
        const res = schema.of(paymentCardRequestSchema).required();
        return res;
      },
    }),
  });

  const { loading, error } = useSelector((state: RootState) => state.sales);

  // Предполагаем, что у вас есть логика для определения успешного завершения запроса
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const handleSubmit = async (values: ExtendedSaleRequest) => {
    const saleRequest = transformToSaleRequest(values);
    const actionResult = await dispatch(createSaleAction(saleRequest));
    if (createSaleAction.fulfilled.match(actionResult)) {
      // Действие успешно выполнено
      setSuccess(true);
    } else {
      console.log("e", error);
      setSuccess(false);
    }
  };

  const getErrorMessage = (error: any, path: string): string | undefined => {
    return typeof error === "object" && error && path in error
      ? error[path]
      : undefined;
  };
  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false);
    // Вызываем clearError без параметров, чтобы очистить всю ошибку
    dispatch(clearError({}));
  };

  const handleAlertClose = (field: string) => () => {
    setIsSnackbarOpen(false);
    // Вызываем clearError с именем поля для удаления
    dispatch(clearError({ field }));
  };

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  useEffect(() => {
    // При обновлении ошибки открываем Snackbar
    if (error) {
      setIsSnackbarOpen(true);
    }
  }, [error]);
  console.log("error", error);
  function shouldRender(dateOfBirth: string | Date) {
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age >= 12;
  }
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Sale Created Successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        {typeof error === "string" ? ( // Проверяем, является ли error строкой
          <Alert
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        ) : (
          error && ( // Проверяем, является ли error объектом
            <div>
              {Object.keys(error).map((key) =>
                error[key].map((message: string, index: number) => (
                  <Alert
                    key={`${key}_${index}`} // Уникальный ключ для каждого Alert
                    onClose={handleAlertClose(key)} // Передаем имя поля в обработчик
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {message}
                  </Alert>
                ))
              )}
            </div>
          )
        )}
      </Snackbar>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <Grid
              container
              spacing={1}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography variant={"h1"}>Default Info</Typography>
              <Card sx={{ padding: 2, marginBottom: 10 }} variant={"outlined"}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="pnr"
                      label="PNR"
                      value={values.pnr}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.pnr && Boolean(errors.pnr)}
                      helperText={touched.pnr && errors.pnr}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      disabled
                      name="totalCharge"
                      label="Total Charge"
                      value={values.totalCharge}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.totalCharge && Boolean(errors.totalCharge)}
                      helperText={touched.totalCharge && errors.totalCharge}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <InputLabel id={`travelProtection`}>
                        travelProtection
                      </InputLabel>
                      <Select
                        labelId={`travelProtection`}
                        name={`travelProtection`}
                        value={values.travelProtection}
                        label="travelProtection"
                        onChange={handleChange}
                      >
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={0.09}>9%</MenuItem>
                        <MenuItem value={0.12}>12%</MenuItem>
                        <MenuItem value={0.15}>15%</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <InputLabel id={`paymentType`}>Payment Type</InputLabel>
                      <Select
                        labelId={`paymentType`}
                        name={`paymentType`}
                        value={values.paymentType}
                        label="paymentType"
                        onChange={(e) => {
                          e.target.value === "wire"
                            ? setFieldValue("paymentCard", [])
                            : setFieldValue("paymentCard", [
                                initialPaymentCard,
                              ]);
                          handleChange(e);
                        }}
                      >
                        <MenuItem value={"card"}>Credit Card</MenuItem>
                        <MenuItem value={"wire"}>Wire Transfer</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="pqId"
                      label="PQ ID"
                      value={values.pqId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.pqId && Boolean(errors.pqId)}
                      helperText={touched.pqId && errors.pqId}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="requestId"
                      label="Request ID"
                      value={values.requestId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.requestId && Boolean(errors.requestId)}
                      helperText={touched.requestId && errors.requestId}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="clientId"
                      label="Client ID"
                      value={values.clientId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.clientId && Boolean(errors.clientId)}
                      helperText={touched.clientId && errors.clientId}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="createdBy"
                      label="Created by Agent"
                      value={values.createdBy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.createdBy && Boolean(errors.createdBy)}
                      helperText={touched.createdBy && errors.createdBy}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="oafType"
                      label="OAF Type"
                      disabled
                      value={values.oafType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.oafType && Boolean(errors.oafType)}
                      helperText={touched.oafType && errors.oafType}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="tips"
                      label="Tips"
                      value={values.tips}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.tips && Boolean(errors.tips)}
                      helperText={touched.tips && errors.tips}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="ipAddress"
                      label="IP Address"
                      value={values.ipAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ipAddress && Boolean(errors.ipAddress)}
                      helperText={touched.ipAddress && errors.ipAddress}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      name="userAgent"
                      label="User Agent"
                      value={values.userAgent}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.userAgent && Boolean(errors.userAgent)}
                      helperText={touched.userAgent && errors.userAgent}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Card>
              <Typography variant={"h1"}>Passengers</Typography>
              <FieldArray
                name="passengers"
                render={(arrayHelpers) => (
                  <>
                    {values.passengers.map(
                      (
                        {
                          firstName,
                          middleName,
                          lastName,
                          dateOfBirth,
                          gender,
                          passportNumber,
                          IssuingCountry,
                          expiringPassport,
                          nationality,
                          phoneNumber,
                          email,
                          redressCode,
                          food,
                          wheelChairAssistance,
                          subscribe,
                        },
                        index
                      ) => (
                        <Card
                          key={index}
                          sx={{ padding: 2, marginBottom: 2 }}
                          variant={"outlined"}
                        >
                          <Grid container spacing={2} key={index}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography variant={"h3"}>
                                Passenger #{index + 1}
                              </Typography>
                              {values.passengers.length > 1 && (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    const ticketIndex =
                                      values.tickets?.findIndex(
                                        (ticket) =>
                                          ticket.id ===
                                          values.passengers[index].id
                                      );
                                    if (
                                      ticketIndex !== -1 &&
                                      ticketIndex !== undefined
                                    ) {
                                      // Клонируем массив для безопасной модификации
                                      const updatedTickets = [
                                        ...(values.tickets || []),
                                      ];

                                      // Удаляем элемент из склонированного массива
                                      updatedTickets.splice(ticketIndex, 1);

                                      // Обновляем значения с новым массивом
                                      setFieldValue(`tickets`, updatedTickets);
                                    }
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  Remove Passenger
                                </Button>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                name={`passengers.${index}.firstName`}
                                label="First Name"
                                value={firstName}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  setFieldValue(
                                    `tickets[${index}].pax_name`,
                                    `${values.passengers[index].lastName}/${e.target.value} ${values.passengers[index].middleName}`
                                  );
                                  handleBlur(e);
                                }}
                                error={
                                  touched.passengers?.[index]?.firstName &&
                                  Boolean(
                                    getErrorMessage(
                                      errors.passengers?.[index],
                                      "firstName"
                                    )
                                  )
                                }
                                helperText={
                                  touched.passengers?.[index]?.firstName &&
                                  getErrorMessage(
                                    errors.passengers?.[index],
                                    "firstName"
                                  )
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                name={`passengers.${index}.lastName`}
                                label="Last Name"
                                value={lastName}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={(e) => {
                                  setFieldValue(
                                    `tickets[${index}].pax_name`,
                                    `${e.target.value}/${values.passengers[index].firstName} ${values.passengers[index].middleName}`
                                  );
                                  handleBlur(e);
                                }}
                                error={
                                  touched.passengers?.[index]?.lastName &&
                                  Boolean(
                                    getErrorMessage(
                                      errors.passengers?.[index],
                                      "lastName"
                                    )
                                  )
                                }
                                helperText={
                                  touched.passengers?.[index]?.lastName &&
                                  getErrorMessage(
                                    errors.passengers?.[index],
                                    "lastName"
                                  )
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                name={`passengers.${index}.middleName`}
                                id={`passengers.${index}.middleName`}
                                label="Middle Name"
                                value={middleName}
                                onBlur={(e) => {
                                  setFieldValue(
                                    `tickets[${index}].pax_name`,
                                    ` ${values.passengers[index].lastName}/${values.passengers[index].firstName} ${e.target.value}`
                                  );
                                  handleBlur(e);
                                }}
                                onChange={handleChange}
                                error={
                                  touched.passengers?.[index]?.middleName &&
                                  Boolean(
                                    getErrorMessage(
                                      errors.passengers?.[index],
                                      "middleName"
                                    )
                                  )
                                }
                                helperText={
                                  touched.passengers?.[index]?.middleName &&
                                  getErrorMessage(
                                    errors.passengers?.[index],
                                    "middleName"
                                  )
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <DatePicker
                                sx={{ width: "100%" }}
                                format="dd-MM-yyyy"
                                label="Date of Birth"
                                defaultValue={parseISO("10-10-2000")}
                                value={parseISO(
                                  values.passengers[index].dateOfBirth
                                )}
                                minDate={new Date(1910, 0, 1)}
                                maxDate={new Date()}
                                onChange={(date) => {
                                  let ptc = "";

                                  if (date) {
                                    const today = new Date();
                                    const birthDate = new Date(date);
                                    let age =
                                      today.getFullYear() -
                                      birthDate.getFullYear();
                                    const m =
                                      today.getMonth() - birthDate.getMonth();

                                    if (
                                      m < 0 ||
                                      (m === 0 &&
                                        today.getDate() < birthDate.getDate())
                                    ) {
                                      age--;
                                    }
                                    if (age >= 12) ptc = "ADT";
                                    else if (age < 1) ptc = "INF";
                                    else ptc = "CHD";
                                  }
                                  console.log("ptc", ptc);
                                  setFieldValue(`tickets[${index}].ptc`, ptc);
                                  setFieldValue(
                                    `passengers[${index}].dateOfBirth`,
                                    date
                                      ? formatISO(date, {
                                          representation: "date",
                                        })
                                      : ""
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <FormControl fullWidth>
                                <InputLabel id={`passengers.${index}.gender`}>
                                  Gender
                                </InputLabel>
                                <Select
                                  labelId={`passengers.${index}.gender`}
                                  name={`passengers.${index}.gender`}
                                  value={gender}
                                  label="Gender"
                                  onChange={handleChange}
                                >
                                  <MenuItem value={"male"}>Male</MenuItem>
                                  <MenuItem value={"female"}>Female</MenuItem>
                                </Select>
                              </FormControl>
                              {/*<TextField*/}
                              {/*  name={`passengers.${index}.gender`}*/}
                              {/*  label="gender"*/}
                              {/*  value={gender}*/}
                              {/*  onChange={handleChange}*/}
                              {/*  onBlur={handleBlur}*/}
                              {/*  error={*/}
                              {/*    touched.passengers?.[index]?.gender &&*/}
                              {/*    Boolean(*/}
                              {/*      getErrorMessage(*/}
                              {/*        errors.passengers?.[index],*/}
                              {/*        "gender"*/}
                              {/*      )*/}
                              {/*    )*/}
                              {/*  }*/}
                              {/*  helperText={*/}
                              {/*    touched.passengers?.[index]?.gender &&*/}
                              {/*    getErrorMessage(*/}
                              {/*      errors.passengers?.[index],*/}
                              {/*      "gender"*/}
                              {/*    )*/}
                              {/*  }*/}
                              {/*  fullWidth*/}
                              {/*/>*/}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                name={`passengers.${index}.passportNumber`}
                                label="Passport Number"
                                value={passportNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.passengers?.[index]?.passportNumber &&
                                  Boolean(
                                    getErrorMessage(
                                      errors.passengers?.[index],
                                      "passportNumber"
                                    )
                                  )
                                }
                                helperText={
                                  touched.passengers?.[index]?.passportNumber &&
                                  getErrorMessage(
                                    errors.passengers?.[index],
                                    "passportNumber"
                                  )
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                name={`passengers.${index}.IssuingCountry`}
                                label="Issuing Country"
                                value={IssuingCountry}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.passengers?.[index]?.IssuingCountry &&
                                  Boolean(
                                    getErrorMessage(
                                      errors.passengers?.[index],
                                      "IssuingCountry"
                                    )
                                  )
                                }
                                helperText={
                                  touched.passengers?.[index]?.IssuingCountry &&
                                  getErrorMessage(
                                    errors.passengers?.[index],
                                    "IssuingCountry"
                                  )
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <DatePicker
                                sx={{ width: "100%" }}
                                format="dd-MM-yyyy"
                                label="Expiring Passport"
                                value={parseISO(
                                  values.passengers[index].expiringPassport
                                )}
                                minDate={new Date()}
                                onChange={(date) => {
                                  setFieldValue(
                                    `passengers[${index}].expiringPassport`,
                                    date
                                      ? formatISO(date, {
                                          representation: "date",
                                        })
                                      : ""
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                name={`passengers.${index}.nationality`}
                                label="Nationality"
                                value={nationality}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.passengers?.[index]?.nationality &&
                                  Boolean(
                                    getErrorMessage(
                                      errors.passengers?.[index],
                                      "nationality"
                                    )
                                  )
                                }
                                helperText={
                                  touched.passengers?.[index]?.nationality &&
                                  getErrorMessage(
                                    errors.passengers?.[index],
                                    "nationality"
                                  )
                                }
                                fullWidth
                              />
                            </Grid>
                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*  <TextField*/}
                            {/*    name={`passengers.${index}.phoneNumber`}*/}
                            {/*    label="Phone Number"*/}
                            {/*    value={phoneNumber}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    onBlur={handleBlur}*/}
                            {/*    error={*/}
                            {/*      touched.passengers?.[index]?.phoneNumber &&*/}
                            {/*      Boolean(*/}
                            {/*        getErrorMessage(*/}
                            {/*          errors.passengers?.[index],*/}
                            {/*          "phoneNumber"*/}
                            {/*        )*/}
                            {/*      )*/}
                            {/*    }*/}
                            {/*    helperText={*/}
                            {/*      touched.passengers?.[index]?.phoneNumber &&*/}
                            {/*      getErrorMessage(*/}
                            {/*        errors.passengers?.[index],*/}
                            {/*        "phoneNumber"*/}
                            {/*      )*/}
                            {/*    }*/}
                            {/*    fullWidth*/}
                            {/*  />*/}
                            {/*</Grid>*/}
                            {shouldRender(
                              values.passengers[index].dateOfBirth
                            ) && (
                              <>
                                <Grid item xs={12} sm={6} md={3}>
                                  <TextField
                                    name={`passengers.${index}.phoneNumber`}
                                    label="Phone Number"
                                    value={phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      touched.passengers?.[index]
                                        ?.phoneNumber &&
                                      Boolean(
                                        getErrorMessage(
                                          errors.passengers?.[index],
                                          "phoneNumber"
                                        )
                                      )
                                    }
                                    helperText={
                                      touched.passengers?.[index]
                                        ?.phoneNumber &&
                                      getErrorMessage(
                                        errors.passengers?.[index],
                                        "phoneNumber"
                                      )
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <TextField
                                    name={`passengers.${index}.email`}
                                    label="Email"
                                    value={email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      touched.passengers?.[index]?.email &&
                                      Boolean(
                                        getErrorMessage(
                                          errors.passengers?.[index],
                                          "email"
                                        )
                                      )
                                    }
                                    helperText={
                                      touched.passengers?.[index]?.email &&
                                      getErrorMessage(
                                        errors.passengers?.[index],
                                        "email"
                                      )
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </>
                            )}

                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                name={`passengers.${index}.redressCode`}
                                label="Redress Code"
                                value={redressCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.passengers?.[index]?.redressCode &&
                                  Boolean(
                                    getErrorMessage(
                                      errors.passengers?.[index],
                                      "redressCode"
                                    )
                                  )
                                }
                                helperText={
                                  touched.passengers?.[index]?.redressCode &&
                                  getErrorMessage(
                                    errors.passengers?.[index],
                                    "redressCode"
                                  )
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <FormControl fullWidth>
                                <InputLabel id={`passengers.${index}.food`}>
                                  Food Type
                                </InputLabel>
                                <Select
                                  labelId={`passengers.${index}.food`}
                                  name={`passengers.${index}.food`}
                                  value={food}
                                  label="Food Type"
                                  onChange={handleChange}
                                >
                                  <MenuItem value={"NONE"}>Declined</MenuItem>
                                  <MenuItem value={"VGML"}>
                                    Vegetarian Meal
                                  </MenuItem>
                                  <MenuItem value={"VLML"}>
                                    Vegetarian Lacto-Ovo Meal
                                  </MenuItem>
                                  <MenuItem value={"VGNM"}>Vegan Meal</MenuItem>
                                  <MenuItem value={"CHML"}>Child Meal</MenuItem>
                                  <MenuItem value={"DBML"}>
                                    Diabetic Meal
                                  </MenuItem>
                                  <MenuItem value={"GFML"}>
                                    Gluten-Free Meal
                                  </MenuItem>
                                  <MenuItem value={"KSML"}>
                                    Kosher Meal
                                  </MenuItem>
                                  <MenuItem value={"HNML"}>Hindu Meal</MenuItem>
                                  <MenuItem value={"MOML"}>
                                    Muslim Meal
                                  </MenuItem>
                                  <MenuItem value={"LCML"}>
                                    Low Calorie Meal
                                  </MenuItem>
                                  <MenuItem value={"LFML"}>
                                    Low Fat Meal
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              {/*<TextField*/}
                              {/*  name={`passengers.${index}.food`}*/}
                              {/*  label="food"*/}
                              {/*  value={food}*/}
                              {/*  onChange={handleChange}*/}
                              {/*  onBlur={handleBlur}*/}
                              {/*  error={*/}
                              {/*    touched.passengers?.[index]?.food &&*/}
                              {/*    Boolean(*/}
                              {/*      getErrorMessage(*/}
                              {/*        errors.passengers?.[index],*/}
                              {/*        "food"*/}
                              {/*      )*/}
                              {/*    )*/}
                              {/*  }*/}
                              {/*  helperText={*/}
                              {/*    touched.passengers?.[index]?.food &&*/}
                              {/*    getErrorMessage(*/}
                              {/*      errors.passengers?.[index],*/}
                              {/*      "food"*/}
                              {/*    )*/}
                              {/*  }*/}
                              {/*  fullWidth*/}
                              {/*/>*/}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id={`passengers.${index}.wheelChairAssistance`}
                                >
                                  Wheel Chair Assistance
                                </InputLabel>
                                <Select
                                  labelId={`passengers.${index}.wheelChairAssistance`}
                                  name={`passengers.${index}.wheelChairAssistance`}
                                  value={Number(wheelChairAssistance)}
                                  onChange={handleChange}
                                  label="Wheel Chair Assistance"
                                >
                                  <MenuItem value={1}>Need</MenuItem>
                                  <MenuItem value={0}>No Need</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id={`passengers.${index}.subscribe`}
                                >
                                  Subscription
                                </InputLabel>
                                <Select
                                  labelId={`passengers.${index}.subscribe`}
                                  name={`passengers.${index}.subscribe`}
                                  label="subscription"
                                  value={Number(subscribe)}
                                  onChange={handleChange}
                                >
                                  <MenuItem value={1}>Activate</MenuItem>
                                  <MenuItem value={0}>Reject</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Card>
                      )
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (values.ticket_type === "AwardTicket") {
                          values.tickets?.push({
                            id: values.passengers.length,
                            ptc: "ADT",
                            pax_name: "",
                            selling_price: 0,
                            net_price: 0,
                            miles: 0,
                            taxes: 0,
                            price_per_mile: 1,
                            mileage_program_name: "",
                          });
                        } else {
                          values.tickets!.push({
                            id: values.passengers.length,
                            consolidator_name: "",
                            ptc: "ADT",
                            pax_name: "",
                            selling_price: 0,
                            net_price: 0,
                            taxes: 0,
                          });
                        }

                        arrayHelpers.push({
                          id: values.passengers.length,
                          firstName: "",
                          lastName: "",
                          middleName: "",
                          dateOfBirth: "2000-10-10",
                          gender: "male",
                          passportNumber: "",
                          IssuingCountry: "",
                          expiringPassport: "2090-10-10",
                          nationality: "",
                          phoneNumber: "",
                          email: "",
                          redressCode: "",
                          food: "NONE",
                          wheelChairAssistance: false,
                          subscribe: false,
                        });
                      }}
                    >
                      Add Passenger
                    </Button>
                  </>
                )}
              />
              <Typography variant={"h1"}>Itinerary</Typography>
              {/*<FieldArray*/}
              {/*  name="itinerary"*/}
              {/*  render={(arrayHelpers) => (*/}
              {/*    <>*/}
              {/*      {values.itinerary.map(*/}
              {/*        (*/}
              {/*          {*/}
              {/*            flightNumber,*/}
              {/*            arrivalAirport,*/}
              {/*            departureAirport,*/}
              {/*            departureDate,*/}
              {/*            arrivalDate,*/}
              {/*            layoverTime,*/}
              {/*            totalTravelTime,*/}
              {/*            flightType,*/}
              {/*            baggageNumber,*/}
              {/*            cabinType,*/}
              {/*            recordLocator,*/}
              {/*            airlineIataCode,*/}
              {/*            weekDayDeparture,*/}
              {/*            weekDayArrival,*/}
              {/*            bookingClass,*/}
              {/*          },*/}
              {/*          index*/}
              {/*        ) => (*/}
              {/*          <Card*/}
              {/*            key={index}*/}
              {/*            sx={{ padding: 2, marginBottom: 2 }}*/}
              {/*            variant={"outlined"}*/}
              {/*          >*/}
              {/*            <Grid*/}
              {/*              item*/}
              {/*              xs={12}*/}
              {/*              sm={12}*/}
              {/*              md={12}*/}
              {/*              mb={2}*/}
              {/*              display={"flex"}*/}
              {/*              justifyContent={"space-between"}*/}
              {/*            >*/}
              {/*              <Typography variant={"h3"}>*/}
              {/*                Segment #{index + 1}*/}
              {/*              </Typography>*/}
              {/*              {values.itinerary.length > 1 && (*/}
              {/*                <Button*/}
              {/*                  variant="contained"*/}
              {/*                  color="secondary"*/}
              {/*                  onClick={() => arrayHelpers.remove(index)}*/}
              {/*                >*/}
              {/*                  Remove segment*/}
              {/*                </Button>*/}
              {/*              )}*/}
              {/*            </Grid>*/}
              {/*            <Grid container spacing={2} key={index}>*/}
              {/*              <Grid item xs={12} sm={6} md={2}>*/}
              {/*                <TextField*/}
              {/*                  name={`itinerary.${index}.flightNumber`}*/}
              {/*                  label="Flight Number"*/}
              {/*                  value={flightNumber}*/}
              {/*                  onChange={handleChange}*/}
              {/*                  onBlur={handleBlur}*/}
              {/*                  error={*/}
              {/*                    touched.itinerary?.[index]?.flightNumber &&*/}
              {/*                    Boolean(*/}
              {/*                      getErrorMessage(*/}
              {/*                        errors.itinerary?.[index],*/}
              {/*                        "flightNumber"*/}
              {/*                      )*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  helperText={*/}
              {/*                    touched.itinerary?.[index]?.flightNumber &&*/}
              {/*                    getErrorMessage(*/}
              {/*                      errors.itinerary?.[index],*/}
              {/*                      "flightNumber"*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  fullWidth*/}
              {/*                />*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={2}>*/}
              {/*                <TextField*/}
              {/*                  name={`itinerary.${index}.departureAirport`}*/}
              {/*                  label="Departure Airport"*/}
              {/*                  value={departureAirport}*/}
              {/*                  onChange={handleChange}*/}
              {/*                  onBlur={handleBlur}*/}
              {/*                  error={*/}
              {/*                    touched.itinerary?.[index]*/}
              {/*                      ?.departureAirport &&*/}
              {/*                    Boolean(*/}
              {/*                      getErrorMessage(*/}
              {/*                        errors.itinerary?.[index],*/}
              {/*                        "departureAirport"*/}
              {/*                      )*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  helperText={*/}
              {/*                    touched.itinerary?.[index]*/}
              {/*                      ?.departureAirport &&*/}
              {/*                    getErrorMessage(*/}
              {/*                      errors.itinerary?.[index],*/}
              {/*                      "departureAirport"*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  fullWidth*/}
              {/*                />*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={2}>*/}
              {/*                <TextField*/}
              {/*                  name={`itinerary.${index}.arrivalAirport`}*/}
              {/*                  label="Arrival Airport"*/}
              {/*                  value={arrivalAirport}*/}
              {/*                  onChange={handleChange}*/}
              {/*                  onBlur={handleBlur}*/}
              {/*                  error={*/}
              {/*                    touched.itinerary?.[index]?.arrivalAirport &&*/}
              {/*                    Boolean(*/}
              {/*                      getErrorMessage(*/}
              {/*                        errors.itinerary?.[index],*/}
              {/*                        "arrivalAirport"*/}
              {/*                      )*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  helperText={*/}
              {/*                    touched.itinerary?.[index]?.arrivalAirport &&*/}
              {/*                    getErrorMessage(*/}
              {/*                      errors.itinerary?.[index],*/}
              {/*                      "arrivalAirport"*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  fullWidth*/}
              {/*                />*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <TextField*/}
              {/*                  name={`itinerary.${index}.airlineIataCode`}*/}
              {/*                  label="Airline Iata Code"*/}
              {/*                  value={airlineIataCode}*/}
              {/*                  onChange={handleChange}*/}
              {/*                  onBlur={handleBlur}*/}
              {/*                  error={*/}
              {/*                    touched.itinerary?.[index]?.airlineIataCode &&*/}
              {/*                    Boolean(*/}
              {/*                      getErrorMessage(*/}
              {/*                        errors.itinerary?.[index],*/}
              {/*                        "airlineIataCode"*/}
              {/*                      )*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  helperText={*/}
              {/*                    touched.itinerary?.[index]?.airlineIataCode &&*/}
              {/*                    getErrorMessage(*/}
              {/*                      errors.itinerary?.[index],*/}
              {/*                      "airlineIataCode"*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  fullWidth*/}
              {/*                />*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <DatePicker*/}
              {/*                  sx={{ width: "100%" }}*/}
              {/*                  format="dd-MM-yyyy"*/}
              {/*                  label="Departure Date"*/}
              {/*                  value={parseISO(*/}
              {/*                    values.itinerary[index].departureDate*/}
              {/*                  )}*/}
              {/*                  onChange={(date) => {*/}
              {/*                    setFieldValue(*/}
              {/*                      `itinerary[${index}].departureDate`,*/}
              {/*                      date*/}
              {/*                        ? formatISO(date, {*/}
              {/*                            representation: "date",*/}
              {/*                          })*/}
              {/*                        : ""*/}
              {/*                    );*/}
              {/*                  }}*/}
              {/*                />*/}
              {/*                /!*<TextField*!/*/}
              {/*                /!*  name={`itinerary.${index}.departureDate`}*!/*/}
              {/*                /!*  label="Departure Date"*!/*/}
              {/*                /!*  value={departureDate}*!/*/}
              {/*                /!*  onChange={handleChange}*!/*/}
              {/*                /!*  onBlur={handleBlur}*!/*/}
              {/*                /!*  error={*!/*/}
              {/*                /!*    touched.itinerary?.[index]?.departureDate &&*!/*/}
              {/*                /!*    Boolean(*!/*/}
              {/*                /!*      getErrorMessage(*!/*/}
              {/*                /!*        errors.itinerary?.[index],*!/*/}
              {/*                /!*        "departureDate"*!/*/}
              {/*                /!*      )*!/*/}
              {/*                /!*    )*!/*/}
              {/*                /!*  }*!/*/}
              {/*                /!*  helperText={*!/*/}
              {/*                /!*    touched.itinerary?.[index]?.departureDate &&*!/*/}
              {/*                /!*    getErrorMessage(*!/*/}
              {/*                /!*      errors.itinerary?.[index],*!/*/}
              {/*                /!*      "departureDate"*!/*/}
              {/*                /!*    )*!/*/}
              {/*                /!*  }*!/*/}
              {/*                /!*  fullWidth*!/*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <DatePicker*/}
              {/*                  sx={{ width: "100%" }}*/}
              {/*                  format="dd-MM-yyyy"*/}
              {/*                  label="Arrival Date"*/}
              {/*                  value={parseISO(*/}
              {/*                    values.itinerary[index].arrivalDate*/}
              {/*                  )}*/}
              {/*                  onChange={(date) => {*/}
              {/*                    setFieldValue(*/}
              {/*                      `itinerary[${index}].arrivalDate`,*/}
              {/*                      date*/}
              {/*                        ? formatISO(date, {*/}
              {/*                            representation: "date",*/}
              {/*                          })*/}
              {/*                        : ""*/}
              {/*                    );*/}
              {/*                  }}*/}
              {/*                />*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <TextField*/}
              {/*                  name={`itinerary.${index}.layoverTime`}*/}
              {/*                  label="Layover Time"*/}
              {/*                  value={layoverTime}*/}
              {/*                  onChange={handleChange}*/}
              {/*                  onBlur={handleBlur}*/}
              {/*                  error={*/}
              {/*                    touched.itinerary?.[index]?.layoverTime &&*/}
              {/*                    Boolean(*/}
              {/*                      getErrorMessage(*/}
              {/*                        errors.itinerary?.[index],*/}
              {/*                        "layoverTime"*/}
              {/*                      )*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  helperText={*/}
              {/*                    touched.itinerary?.[index]?.layoverTime &&*/}
              {/*                    getErrorMessage(*/}
              {/*                      errors.itinerary?.[index],*/}
              {/*                      "layoverTime"*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  fullWidth*/}
              {/*                />*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <TextField*/}
              {/*                  name={`itinerary.${index}.totalTravelTime`}*/}
              {/*                  label="Total Travel Time"*/}
              {/*                  value={totalTravelTime}*/}
              {/*                  onChange={handleChange}*/}
              {/*                  onBlur={handleBlur}*/}
              {/*                  error={*/}
              {/*                    touched.itinerary?.[index]?.totalTravelTime &&*/}
              {/*                    Boolean(*/}
              {/*                      getErrorMessage(*/}
              {/*                        errors.itinerary?.[index],*/}
              {/*                        "totalTravelTime"*/}
              {/*                      )*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  helperText={*/}
              {/*                    touched.itinerary?.[index]?.totalTravelTime &&*/}
              {/*                    getErrorMessage(*/}
              {/*                      errors.itinerary?.[index],*/}
              {/*                      "totalTravelTime"*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  fullWidth*/}
              {/*                />*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <TextField*/}
              {/*                  name={`itinerary.${index}.flightType`}*/}
              {/*                  label="Flight Type"*/}
              {/*                  value={flightType}*/}
              {/*                  onChange={handleChange}*/}
              {/*                  onBlur={handleBlur}*/}
              {/*                  error={*/}
              {/*                    touched.itinerary?.[index]?.flightType &&*/}
              {/*                    Boolean(*/}
              {/*                      getErrorMessage(*/}
              {/*                        errors.itinerary?.[index],*/}
              {/*                        "flightType"*/}
              {/*                      )*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  helperText={*/}
              {/*                    touched.itinerary?.[index]?.flightType &&*/}
              {/*                    getErrorMessage(*/}
              {/*                      errors.itinerary?.[index],*/}
              {/*                      "flightType"*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  fullWidth*/}
              {/*                />*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <FormControl fullWidth>*/}
              {/*                  <InputLabel id={`baggageNumber`}>*/}
              {/*                    baggageNumber*/}
              {/*                  </InputLabel>*/}
              {/*                  <Select*/}
              {/*                    labelId={`baggageNumber`}*/}
              {/*                    name={`itinerary.${index}.baggageNumber`}*/}
              {/*                    value={values.itinerary[index].baggageNumber}*/}
              {/*                    label="Baggage Number"*/}
              {/*                    onChange={handleChange}*/}
              {/*                  >*/}
              {/*                    <MenuItem value={-1}>-</MenuItem>*/}
              {/*                    <MenuItem value={0}>0</MenuItem>*/}
              {/*                    <MenuItem value={1}>1</MenuItem>*/}
              {/*                    <MenuItem value={2}>2</MenuItem>*/}
              {/*                    <MenuItem value={3}>3</MenuItem>*/}
              {/*                  </Select>*/}
              {/*                </FormControl>*/}
              {/*                /!*<TextField*!/*/}
              {/*                /!*  name={`itinerary.${index}.baggageNumber`}*!/*/}
              {/*                /!*  label="Baggage Number"*!/*/}
              {/*                /!*  value={baggageNumber}*!/*/}
              {/*                /!*  onChange={handleChange}*!/*/}
              {/*                /!*  onBlur={handleBlur}*!/*/}
              {/*                /!*  error={*!/*/}
              {/*                /!*    touched.itinerary?.[index]?.baggageNumber &&*!/*/}
              {/*                /!*    Boolean(*!/*/}
              {/*                /!*      getErrorMessage(*!/*/}
              {/*                /!*        errors.itinerary?.[index],*!/*/}
              {/*                /!*        "baggageNumber"*!/*/}
              {/*                /!*      )*!/*/}
              {/*                /!*    )*!/*/}
              {/*                /!*  }*!/*/}
              {/*                /!*  helperText={*!/*/}
              {/*                /!*    touched.itinerary?.[index]?.baggageNumber &&*!/*/}
              {/*                /!*    getErrorMessage(*!/*/}
              {/*                /!*      errors.itinerary?.[index],*!/*/}
              {/*                /!*      "baggageNumber"*!/*/}
              {/*                /!*    )*!/*/}
              {/*                /!*  }*!/*/}
              {/*                /!*  fullWidth*!/*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <TextField*/}
              {/*                  name={`itinerary.${index}.cabinType`}*/}
              {/*                  label="Cabin Type"*/}
              {/*                  value={cabinType}*/}
              {/*                  onChange={handleChange}*/}
              {/*                  onBlur={handleBlur}*/}
              {/*                  error={*/}
              {/*                    touched.itinerary?.[index]?.cabinType &&*/}
              {/*                    Boolean(*/}
              {/*                      getErrorMessage(*/}
              {/*                        errors.itinerary?.[index],*/}
              {/*                        "cabinType"*/}
              {/*                      )*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  helperText={*/}
              {/*                    touched.itinerary?.[index]?.cabinType &&*/}
              {/*                    getErrorMessage(*/}
              {/*                      errors.itinerary?.[index],*/}
              {/*                      "cabinType"*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  fullWidth*/}
              {/*                />*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <TextField*/}
              {/*                  name={`itinerary.${index}.recordLocator`}*/}
              {/*                  label="Record Locator"*/}
              {/*                  value={recordLocator}*/}
              {/*                  onChange={handleChange}*/}
              {/*                  onBlur={handleBlur}*/}
              {/*                  error={*/}
              {/*                    touched.itinerary?.[index]?.recordLocator &&*/}
              {/*                    Boolean(*/}
              {/*                      getErrorMessage(*/}
              {/*                        errors.itinerary?.[index],*/}
              {/*                        "recordLocator"*/}
              {/*                      )*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  helperText={*/}
              {/*                    touched.itinerary?.[index]?.recordLocator &&*/}
              {/*                    getErrorMessage(*/}
              {/*                      errors.itinerary?.[index],*/}
              {/*                      "recordLocator"*/}
              {/*                    )*/}
              {/*                  }*/}
              {/*                  fullWidth*/}
              {/*                />*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <FormControl fullWidth>*/}
              {/*                  <InputLabel*/}
              {/*                    id={`itinerary.${index}.weekDayDeparture`}*/}
              {/*                  >*/}
              {/*                    Week Day Departure*/}
              {/*                  </InputLabel>*/}
              {/*                  <Select*/}
              {/*                    labelId={`itinerary.${index}.weekDayDeparture`}*/}
              {/*                    name={`itinerary.${index}.weekDayDeparture`}*/}
              {/*                    value={weekDayDeparture}*/}
              {/*                    label="weekDayDeparture"*/}
              {/*                    onChange={handleChange}*/}
              {/*                  >*/}
              {/*                    {daysOfWeek.map((day) => (*/}
              {/*                      <MenuItem key={day} value={day}>*/}
              {/*                        {day}*/}
              {/*                      </MenuItem>*/}
              {/*                    ))}*/}
              {/*                  </Select>*/}
              {/*                </FormControl>*/}
              {/*                /!*<TextField*!/*/}
              {/*                /!*  name={`itinerary.${index}.weekDayDeparture`}*!/*/}
              {/*                /!*  label="Week Day Departure"*!/*/}
              {/*                /!*  value={weekDayDeparture}*!/*/}
              {/*                /!*  onChange={handleChange}*!/*/}
              {/*                /!*  onBlur={handleBlur}*!/*/}
              {/*                /!*  error={*!/*/}
              {/*                /!*    touched.itinerary?.[index]?.weekDayDeparture &&*!/*/}
              {/*                /!*    Boolean(*!/*/}
              {/*                /!*      getErrorMessage(*!/*/}
              {/*                /!*        errors.itinerary?.[index],*!/*/}
              {/*                /!*        "weekDayDeparture"*!/*/}
              {/*                /!*      )*!/*/}
              {/*                /!*    )*!/*/}
              {/*                /!*  }*!/*/}
              {/*                /!*  helperText={*!/*/}
              {/*                /!*    touched.itinerary?.[index]?.weekDayDeparture &&*!/*/}
              {/*                /!*    getErrorMessage(*!/*/}
              {/*                /!*      errors.itinerary?.[index],*!/*/}
              {/*                /!*      "weekDayDeparture"*!/*/}
              {/*                /!*    )*!/*/}
              {/*                /!*  }*!/*/}
              {/*                /!*  fullWidth*!/*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <FormControl fullWidth>*/}
              {/*                  <InputLabel*/}
              {/*                    id={`itinerary.${index}.weekDayArrival`}*/}
              {/*                  >*/}
              {/*                    week Day Arrival*/}
              {/*                  </InputLabel>*/}
              {/*                  <Select*/}
              {/*                    labelId={`itinerary.${index}.weekDayArrival`}*/}
              {/*                    name={`itinerary.${index}.weekDayArrival`}*/}
              {/*                    value={weekDayArrival}*/}
              {/*                    label="weekDayArrival"*/}
              {/*                    onChange={handleChange}*/}
              {/*                  >*/}
              {/*                    {daysOfWeek.map((day) => (*/}
              {/*                      <MenuItem key={day} value={day}>*/}
              {/*                        {day}*/}
              {/*                      </MenuItem>*/}
              {/*                    ))}*/}
              {/*                  </Select>*/}
              {/*                </FormControl>*/}
              {/*                /!*<TextField*!/*/}
              {/*                /!*  name={`itinerary.${index}.weekDayArrival`}*!/*/}
              {/*                /!*  label="Week Day Arrival"*!/*/}
              {/*                /!*  value={weekDayArrival}*!/*/}
              {/*                /!*  onChange={handleChange}*!/*/}
              {/*                /!*  onBlur={handleBlur}*!/*/}
              {/*                /!*  error={*!/*/}
              {/*                /!*    touched.itinerary?.[index]?.weekDayArrival &&*!/*/}
              {/*                /!*    Boolean(*!/*/}
              {/*                /!*      getErrorMessage(*!/*/}
              {/*                /!*        errors.itinerary?.[index],*!/*/}
              {/*                /!*        "weekDayArrival"*!/*/}
              {/*                /!*      )*!/*/}
              {/*                /!*    )*!/*/}
              {/*                /!*  }*!/*/}
              {/*                /!*  helperText={*!/*/}
              {/*                /!*    touched.itinerary?.[index]?.weekDayArrival &&*!/*/}
              {/*                /!*    getErrorMessage(*!/*/}
              {/*                /!*      errors.itinerary?.[index],*!/*/}
              {/*                /!*      "weekDayArrival"*!/*/}
              {/*                /!*    )*!/*/}
              {/*                /!*  }*!/*/}
              {/*                /!*  fullWidth*!/*/}
              {/*              </Grid>*/}
              {/*              <Grid item xs={12} sm={6} md={3}>*/}
              {/*                <FormControl fullWidth>*/}
              {/*                  <InputLabel*/}
              {/*                    id={`itinerary.${index}.bookingClass`}*/}
              {/*                  >*/}
              {/*                    Booking Class*/}
              {/*                  </InputLabel>*/}
              {/*                  <Select*/}
              {/*                    labelId={`itinerary.${index}.bookingClass`}*/}
              {/*                    name={`itinerary.${index}.bookingClass`}*/}
              {/*                    value={bookingClass}*/}
              {/*                    label="Booking Class"*/}
              {/*                    onChange={handleChange}*/}
              {/*                  >*/}
              {/*                    {bookingClasses.map((book, index) => (*/}
              {/*                      <MenuItem key={index} value={book.code}>*/}
              {/*                        {book.name}*/}
              {/*                      </MenuItem>*/}
              {/*                    ))}*/}
              {/*                  </Select>*/}
              {/*                </FormControl>*/}
              {/*                /!*<TextField*!/*/}
              {/*                /!*  name={`itinerary.${index}.bookingClass`}*!/*/}
              {/*                /!*  label="Booking Class"*!/*/}
              {/*                /!*  value={bookingClass}*!/*/}
              {/*                /!*  onChange={handleChange}*!/*/}
              {/*                /!*  onBlur={handleBlur}*!/*/}
              {/*                /!*  error={*!/*/}
              {/*                /!*    touched.itinerary?.[index]?.bookingClass &&*!/*/}
              {/*                /!*    Boolean(*!/*/}
              {/*                /!*      getErrorMessage(*!/*/}
              {/*                /!*        errors.itinerary?.[index],*!/*/}
              {/*                /!*        "bookingClass"*!/*/}
              {/*                /!*      )*!/*/}
              {/*                /!*    )*!/*/}
              {/*                /!*  }*!/*/}
              {/*                /!*  helperText={*!/*/}
              {/*                /!*    touched.itinerary?.[index]?.bookingClass &&*!/*/}
              {/*                /!*    getErrorMessage(*!/*/}
              {/*                /!*      errors.itinerary?.[index],*!/*/}
              {/*                /!*      "bookingClass"*!/*/}
              {/*                /!*    )*!/*/}
              {/*                /!*  }*!/*/}
              {/*                /!*  fullWidth*!/*/}
              {/*              </Grid>*/}
              {/*            </Grid>*/}
              {/*          </Card>*/}
              {/*        )*/}
              {/*      )}*/}
              {/*      <Button*/}
              {/*        variant="contained"*/}
              {/*        color="primary"*/}
              {/*        onClick={() =>*/}
              {/*          arrayHelpers.push({*/}
              {/*            // начальные значения для одного итинерария*/}
              {/*            flightNumber: "1234",*/}
              {/*            departureAirport: "ABC",*/}
              {/*            arrivalAirport: "XYZ",*/}
              {/*            airlineIataCode: "AA",*/}
              {/*            departureDate: "2023-01-01",*/}
              {/*            arrivalDate: "2023-01-02",*/}
              {/*            layoverTime: "2h",*/}
              {/*            totalTravelTime: "5h",*/}
              {/*            flightType: "Direct",*/}
              {/*            baggageNumber: -1,*/}
              {/*            cabinType: "Economy",*/}
              {/*            recordLocator: "ABCDE",*/}
              {/*            iataCodeArrival: "XYZ",*/}
              {/*            iataCodeDeparture: "ABC",*/}
              {/*            weekDayDeparture: "Monday",*/}
              {/*            weekDayArrival: "Tuesday",*/}
              {/*            bookingClass: "F",*/}
              {/*          })*/}
              {/*        }*/}
              {/*      >*/}
              {/*        Add Segment*/}
              {/*      </Button>*/}
              {/*    </>*/}
              {/*  )}*/}
              {/*/>*/}
              <FormControlLabel
                control={
                  <Switch
                    id={"ticket_type"}
                    checked={values!.ticket_type === "AwardTicket"}
                    onChange={(e) => {
                      setFieldValue(
                        "tickets",
                        e.target.checked
                          ? Array(values.passengers.length).fill(
                              initAwardTicket
                            )
                          : Array(values.passengers.length).fill(
                              initRevenueTicket
                            )
                      );
                      setFieldValue(
                        "ticket_type",
                        e.target.checked ? "AwardTicket" : "RevenueTicket"
                      );
                    }}
                    color="primary"
                  />
                }
                label={
                  values!.ticket_type === "AwardTicket"
                    ? "Award Ticket"
                    : "Revenue Ticket"
                }
              />

              <TicketForm
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
              />
              {values.paymentType === "card" && (
                <CreditCardForm
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
              )}
              <Typography variant={"h1"} mt={3} mb={1}>
                Feedback
              </Typography>
              <Divider />
              <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateOAForm;
