import { OAF } from "../../services/openapi";
import { FC, useState } from "react";
import {
  Card as MuiCard,
  styled,
  CardContent as MuiCardContent,
  CardHeader,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import * as React from "react";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { showCcAction } from "../../redux/slices/actions/salesActions";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const OAFCard: FC = () => {
  const Card = styled(MuiCard)`
    background-color: ${(props: any) => {
      return props.theme.card.headerBackground;
    }};
    min-width: 304px;
    border: 1px solid ${(props) => props.theme.palette.background.default};
    margin: 1px;
    pl: 1;
  `;
  const { id } = useParams();
  const CardContent = styled(MuiCardContent)(({ theme }) => ({
    backgroundColor: theme.palette.background.default, // This uses the primary color from the theme
    // Add other styles here
  }));
  const dispatch: AppDispatch = useDispatch();
  const { paymentCards } = useSelector(
    (state: RootState) => state.sales.saleDetails
  );
  const { saleDetails } = useSelector((state: RootState) => state.sales);
  const { role } = useAuth();
  const [show, setShow] = useState<boolean>(false);
  return (
    <Card
      sx={{
        minWidth: 304,
        margin: 2,
      }}
    >
      <CardContent>
        <Typography>Credit Cards</Typography>
        {show &&
          paymentCards &&
          paymentCards.map((card, index) => (
            <div key={index}>
              {Object.entries(card).map(([key, value]) => (
                <Typography key={key} variant="body2">
                  {`${key}: ${value}`}
                </Typography>
              ))}
            </div>
          ))}
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        {saleDetails.status === "inProgress" ||
        saleDetails.status === "inApproving" ||
        (saleDetails.status === "approved" &&
          (role === "manager" ||
            role === "ticketing" ||
            role === "bookkeeping")) ? (
          <Button
            onClick={() =>
              dispatch(showCcAction(Number(id))).then(() => setShow(true))
            }
          >
            OPEN CC
          </Button>
        ) : (
          <>You have no rights to see this info</>
        )}
        {show && <Button onClick={() => setShow(false)}>CLOSE CC</Button>}
      </CardActions>
    </Card>
  );
};

export default OAFCard;
