/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmOAFRequest } from '../models/ConfirmOAFRequest';
import type { ConfirmTicketNumberTableRequest } from '../models/ConfirmTicketNumberTableRequest';
import type { ConfirmTipsRequest } from '../models/ConfirmTipsRequest';
import type { ConfirmTransactionRequest } from '../models/ConfirmTransactionRequest';
import type { ConfirmTransactionsTableRequest } from '../models/ConfirmTransactionsTableRequest';
import type { ConfirmTravelProtectionRequest } from '../models/ConfirmTravelProtectionRequest';
import type { SuccessResponse } from '../models/SuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApprovesService {

    /**
     * approveTicketNumberTable
     * Approve ticket number table with the provided data
     * @param requestBody
     * @returns any Ticket number succesfully in approve updated
     * @throws ApiError
     */
    public static approveTicketNumberTable(
        requestBody: ConfirmTicketNumberTableRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/approveTicketNumberTable',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * approvePassengersTable
     * Approve ticket number table with the provided data
     * @param requestBody
     * @returns any Passengers  succesfully in approve updated
     * @throws ApiError
     */
    public static approvePassengersTable(
        requestBody: ConfirmTicketNumberTableRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/approvePassengersTable',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * approveFinancialInfoTable
     * Approve ticket number table with the provided data
     * @param requestBody
     * @returns any Passengers  succesfully in approve updated
     * @throws ApiError
     */
    public static approveFinancialInfoTable(
        requestBody: ConfirmOAFRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/approveFinancialInfoTable',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * confirmTips
     * confirmTips with the provided data
     * @param requestBody
     * @returns any confirmPassengersTable for sale created successfully
     * @throws ApiError
     */
    public static approveTips(
        requestBody: ConfirmTipsRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/approveTips',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * approveTravelProtection
     * approveTravelProtection with the provided data
     * @param requestBody
     * @returns any confirmTravelProtection for sale created successfully
     * @throws ApiError
     */
    public static approveTravelProtection(
        requestBody: ConfirmTravelProtectionRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/approveTravelProtection',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * approveTransactionsTable
     * approveTransactionsTable with the provided data
     * @param requestBody
     * @returns any approveTransactionsTable for sale created successfully
     * @throws ApiError
     */
    public static approveTransactionsTable(
        requestBody: ConfirmTransactionsTableRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/approveTransactionsTable',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

    /**
     * processSale
     * processSale with the provided data
     * @param requestBody
     * @returns any closeSale for sale created successfully
     * @throws ApiError
     */
    public static closeSale(
        requestBody: ConfirmTransactionRequest,
    ): CancelablePromise<{
        data?: SuccessResponse;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/closeSale',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation error`,
            },
        });
    }

}
