import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Pnr,
  PnrPassenger,
  PnrService,
  UpdatePassengerRequest,
} from "../../services/openapi";

// Async thunk for storing PNR data
export const storePnr = createAsyncThunk<any>(
  "pnr/storePnr",
  async (pnrData: any) => {
    const response = await PnrService.storePnr(pnrData);
    console.log("re123s", response);
    if (!response.message) throw new Error("Failed to save PNR");
    return response.message;
  }
);

// Async thunk for updating passenger data
export const updatePassengerData = createAsyncThunk<
  any,
  UpdatePassengerRequest
>("pnr/updatePassengerData", async (passengerData: UpdatePassengerRequest) => {
  const response = await PnrService.updatePassengerData(passengerData);
  if (!response.message) throw new Error("Failed to update passenger data");
  return response.message;
});

// Async thunk for getting PNR passengers by sale
export const getPnrPassengersBySale = createAsyncThunk<PnrPassenger[], number>(
  "pnr/getPnrPassengersBySale",
  async (saleId: number) => {
    return PnrService.getPnrPassengersBySale(saleId);
  }
);

interface PnrSliceInterface {
  pnrs: Pnr[];
  pnrPassengers: PnrPassenger[];
  status: string;
  error: any;
}

const initialState: PnrSliceInterface = {
  pnrs: [],
  pnrPassengers: [],
  status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
  error: null,
};

const pnrSlice = createSlice({
  name: "pnr",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle storePnr async thunk
      .addCase(storePnr.pending, (state) => {
        state.status = "loading";
      })
      .addCase(storePnr.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (state.pnrs) {
          state.pnrs.push(action.payload as Pnr);
        }
      })
      .addCase(storePnr.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle updatePassengerData async thunk
      .addCase(updatePassengerData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePassengerData.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(updatePassengerData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Handle getPnrPassengersBySale async thunk
      .addCase(getPnrPassengersBySale.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPnrPassengersBySale.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pnrPassengers = action.payload;
      })
      .addCase(getPnrPassengersBySale.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the custom selectors and actions here
export default pnrSlice.reducer;
